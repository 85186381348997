import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Camera = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 30" {...props}>
    <Path
      d="M11.126 1.456A3 3 0 0 1 13.698 0h8.603a3 3 0 0 1 2.573 1.456l.252.42a3 3 0 0 0 2.572 1.457H33a3 3 0 0 1 3 3V27a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V6.333a3 3 0 0 1 3-3H8.3a3 3 0 0 0 2.573-1.456l.252-.42z"
      clipRule="evenodd"
      fill="#50CEA2"
      fillRule="evenodd"
    />
    <Path
      d="M11.25 13.8V9.75h4.05M20.7 9.75h4.05v4.05M24.75 19.2v4.05H20.7M15.3 23.25h-4.05V19.2M18 18.525a2.025 2.025 0 1 0 0-4.05 2.025 2.025 0 0 0 0 4.05z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default Camera
