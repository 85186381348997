import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Sections5: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content, pictureUrl, icon1Url } = section
  if (content) {
    const { overline, heading, text } = content
    return (
      <Container>
        <Picture>
          {pictureUrl ? (
            <img src={pictureUrl} alt="" style={{ maxWidth: '942px' }} />
          ) : null}
        </Picture>
        <OverlineHeading>
          <Overline>{overline}</Overline>
          <Heading>{heading}</Heading>
        </OverlineHeading>
        <TextIcon>
          <Icon>
            {icon1Url ? (
              <img src={icon1Url} alt="" style={{ maxWidth: '36px' }} />
            ) : null}
          </Icon>
          <Text>{text}</Text>
        </TextIcon>
      </Container>
    )
  }
  return <Box>Sections5</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
`

const Picture = styled.div`
  width: 100%;
`

const OverlineHeading = styled.div`
  width: 50%;
  padding: 36px;
  gap: 36px;
  box-sizing: border-box;
`
const Overline = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  word-wrap: break-word;
`
const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;

  color: #000000;
  word-wrap: break-word;
`

const TextIcon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 50%;
  box-sizing: border-box;
`
const Text = styled.div`
  font-style: normal;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
  width: 85%;
  word-wrap: break-word;
`
const Icon = styled.div`
  width: 15%;
`
