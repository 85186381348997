import { useApolloOfflineClient } from 'react-offix-hooks'
import { useAppContext } from 'utils/app-context'
import { createHandleCreateEvent } from '../helpers/task-complete-helpers'

interface CreateEventHookProps {
  locationHierarchyId: string
  taskId: string
  sessionUuid: string
}

function useCreateEvent(props: CreateEventHookProps) {
  const { locationHierarchyId, taskId, sessionUuid } = props
  const client = useApolloOfflineClient()
  const appContextValue = useAppContext()
  const selectedDay = appContextValue.selectedDay

  return createHandleCreateEvent({
    client,
    selectedDay,
    taskId,
    sessionUuid,
    locationHierarchyId,
  })
}

export default useCreateEvent
