import { Platform } from 'react-native'
export const ENVIRONMENT = process.env.ENVIRONMENT
export const CROSS_DOMAIN_STORAGE_URL = process.env.CROSS_DOMAIN_STORAGE_URL
export const TEAM_METRICS_URL = process.env.TEAM_METRICS_URL

export const FULLSTORY_ORG_ID = process.env.FULLSTORY_ORG_ID;

export const ROOT_FOLDER_NAME = '__root__'

const PLATFORM_ANDROID = 'android'

export const IS_ANDROID = Platform.OS === PLATFORM_ANDROID
export const IS_WEB = Platform.OS === 'web'

export const SUPPORTED_VIDEO_TYPES = {
  MP4: 'mp4',
  MOV: 'mov',
}

export const SUPPORTED_IMAGE_TYPES = {
  JPG: 'jpg',
  PNG: 'png',
}
