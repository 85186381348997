import React, { FC } from 'react'

import { Box } from './Box'

import LockIcon from '../LockIcon'
import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

interface SubsectionValues {
  heading: string
  text: string
  more: string
  protectedLink?: boolean
}

export const Columns3: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading: mainHeading, subsections } = content
    return (
      <Container>
        <MainHeading>{mainHeading}</MainHeading>
        <Sections>
          {subsections?.map(
            ({ heading, text, more, protectedLink }: SubsectionValues) =>
              heading || text || more ? (
                <Section key={`${heading}_${text}_${more}`}>
                  <Heading>{heading}</Heading>
                  <Text>{text}</Text>
                  {more && (
                    <More>
                      <div>
                        <LockIcon locked={protectedLink} />
                        <a href={more} target="_blank">
                          Learn More
                        </a>
                      </div>
                      <a href={more} target="_blank">
                        <img
                          src={require('assets/images/png/learnMore24.png')}
                          width="24"
                          alt=""
                        />
                      </a>
                    </More>
                  )}
                </Section>
              ) : null,
          )}
        </Sections>
      </Container>
    )
  }
  return <Box>Columns3</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`

const MainHeading = styled.div`
  padding: 10px;
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.005em;
  color: #000000;
  word-wrap: break-word;
`

const Sections = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Section = styled.div`
  width: calc(33% - 20px);
  margin: 10px;
  border-bottom: 1px solid #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'SourceSansPro_300Light';
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'SourceSansPro_400Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.015em;
  color: #000000;
  flex-grow: 1;
  word-wrap: break-word;
`

const More = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;

  div {
    font-style: normal;
    font-family: 'Poppins_600SemiBold';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    word-wrap: break-word;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  img {
    margin-left: 12px;
  }
`
