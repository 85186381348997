import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

interface SubsectionValues {
  heading: string
  text: string
}

export const Sections8: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading: mainHeading, subsections, text: mainText } = content
    return (
      <Container>
        <Heading>{mainHeading}</Heading>
        <Text>{mainText}</Text>
        <Subsections>
          {subsections?.map(
            ({ heading, text }: SubsectionValues, index: number) =>
              heading || text ? (
                <Subsection key={`${heading}_${text}`}>
                  <SubsectionIcon>
                    {section[`icon${index + 1}Url`] ? (
                      <img
                        src={section[`icon${index + 1}Url`]}
                        alt=""
                        style={{ maxWidth: '36px' }}
                      />
                    ) : null}
                  </SubsectionIcon>
                  <SubsectionText>
                    <SubHeading>{heading}</SubHeading>
                    <SubText>{text}</SubText>
                  </SubsectionText>
                </Subsection>
              ) : null,
          )}
        </Subsections>
      </Container>
    )
  }
  return <Box>Sections8</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  background: #ebecec;
  padding: 20px 20px 0px 20px;
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_300Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  background: #ebecec;
  padding: 0px 20px 20px 20px;
  border-bottom: 2px solid #ffffff;
  overflow-wrap: break-word;
  word-wrap: break-word;
`

const Subsections = styled.div`
  background: #ebecec;
  padding: 36px 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`

const Subsection = styled.div`
  width: calc(50% - 12px);
  display: flex;
  flex-direction: row;
`
const SubsectionIcon = styled.div`
  width: 20%;
  text-align: center;
`
const SubsectionText = styled.div`
  width: 80%;
`

const SubHeading = styled.div`
  font-style: normal;
  font-family: 'SourceSansPro_600SemiBold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.005em;
  color: #000000;
  word-wrap: break-word;
`

const SubText = styled.div`
  font-style: normal;
  font-family: 'SourceSansPro_400Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  word-wrap: break-word;
`
