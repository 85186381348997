import React, { useMemo, useState } from 'react'
import {
  ImageURISource,
  Linking,
  useWindowDimensions,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router-native'

import draftToHtml from 'draftjs-to-html'
import HTML from 'react-native-render-html'
import { AVPlaybackSource } from 'expo-av/build/AV'
import { Button, Text } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler'
import Modal from 'react-native-modal'
import { captureException } from 'utils/sentry'
import Icon from 'components/common/Icon'
import { TextModal } from 'components/task-complete/TextModal'
import {
  TaskListModalMedia,
  TaskNotes,
} from 'components/task-list/TaskListModalComponents'
import COLORS from 'constants/colors'
import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import { IS_WEB } from 'constants/static'
import MESSAGES from 'constants/messages'
import { EVENT_TYPES, TASK_TYPES } from 'constants/types'
import {
  EMPTY_REACT_DRAFT_WYSIWYG_STRING,
  ROUTE_NAMES,
} from 'navigation/constants'
import { percentScreenHeight } from 'utils/animations'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import TaskCompletionElement from './TaskCompletionElement'
import WebView from 'react-native-webview'
import {
  customHTMLElementModels,
  HTMLRenderers,
} from 'utils/helpers/html-render-helper'

import { replaceSnippetsInDescription } from 'utils/helpers/snippets-helper'

import {
  isCantCompleteEvent,
  isNotApplicable,
} from 'components/task-complete/helpers/task-complete-helpers'
import CantCompleteButton from './buttons/CantCompleteButton'
import NotApplicableButton from './buttons/NotApplicableButton'
import TaskModalHeader from './TaskListModalComponents/TaskModalHeader'
import { useTranslation } from 'react-i18next'

export type TaskListModalProps = {
  isOpen: boolean
  hasTextOrPhotos: boolean
  closeModal: (removeModalFromCache?: boolean) => void
  task: TaskInterface
  fileSource: ImageURISource
  isVideo: boolean
  sessionUuid?: string
  locationHierarchyId?: string
  completedEvent?: any
  cantCompleteText?: string
  snippetsMapping?: StringMap
  showSnippets: Boolean
}

const ModalComponent = IS_WEB ? View : Modal

export const TaskListModal = ({
  isOpen,
  hasTextOrPhotos,
  closeModal,
  task,
  fileSource,
  isVideo,
  sessionUuid,
  locationHierarchyId,
  completedEvent,
  snippetsMapping,
  showSnippets,
}: TaskListModalProps) => {
  const [cantCompleteModalOpen, setCantCompleteModalOpen] =
    useState<boolean>(false)
  const [notApplicableModalOpen, setNotApplicableModalOpen] = useState(false)
  const history = useHistory()
  const appContextValue = useAppContext()
  const dayISO = ISODayString(appContextValue.selectedDay)
  const notApplicableText = completedEvent?.notApplicableText
  const cantCompleteText = completedEvent?.cantCompleteText
  const { width } = useWindowDimensions()
  const [contentWidth, setContentWidth] = useState(width)
  const { t } = useTranslation()

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      captureException(`Missing snippet: ${key}, taskId: ${task.id}`)
    },
    [task],
  )

  const replaceDocusignSnippets = (html: string) => {
    return html.replace(/{{docusign_signer_signature}}/g, '')
  }

  const descriptionHtml = useMemo(() => {
    if (!task) {
      return
    }
    const parsedTaskData = parseJson(task)

    return replaceDocusignSnippets(
      draftToHtml(
        replaceSnippetsInDescription(
          parsedTaskData,
          snippetsMapping,
          handleMissingSnippet,
        ),
      ),
    )
  }, [task, showSnippets])

  const onAddNotePhotos = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
      return
    }
    history.push({
      pathname: ROUTE_NAMES.LOCATION_TEXT_AND_PHOTOS,
      state: {
        sessionUuid,
        uniqueId: `${task.id}:${dayISO}`,
        id: task.id,
        locationHierarchyId,
        isTaskLevel: true,
      },
    })
    closeModal()
  }

  const onCantComplete = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
      return
    }

    setCantCompleteModalOpen(true)
  }

  const onNotApplicable = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
      return
    }

    setNotApplicableModalOpen(true)
  }

  const cantComplete = () => {
    setCantCompleteModalOpen(false)
    closeModal(true)
  }

  const handleNotApplicableSubmitCallback = () => {
    setNotApplicableModalOpen(false)
    closeModal(true)
  }

  const renderMediaUrls = (mediaUrls: [string]) =>
    mediaUrls.map((url: string) => {
      const split = url.split('/')
      const fileName = split[split.length - 1]
      if (split.length === 1) {
        return (
          <Text
            style={{
              color: COLORS.TURQUOISE,
            }}
          >
            Uploading...
          </Text>
        )
      }

      return (
        <Text
          style={{
            color: COLORS.TURQUOISE,
            textDecorationLine: 'underline',
          }}
          onPress={() => Linking.openURL(url)}
        >
          {fileName}
        </Text>
      )
    })

  const isCommentTask = task.taskType == TASK_TYPES.TEXT
  const iconColor = hasTextOrPhotos ? COLORS.BLUEDARK : COLORS.GRAYMEDIUM
  const addNotesFlex = IS_WEB ? 'none' : 1

  return (
    <ModalComponent
      style={[
        stylesGlobal.modal,
        IS_WEB
          ? { maxWidth: '100%', paddingTop: 24, alignSelf: 'flex-start' }
          : {},
      ]}
      isVisible={isOpen}
    >
      <View style={[stylesGlobal.modalContent, styles.container]}>
        <TaskModalHeader
          completedEvent={completedEvent}
          task={task}
          onClose={() => closeModal(true)}
        />
        <ScrollView
          contentContainerStyle={styles.scrollContainer}
          onLayout={(event) => {
            setContentWidth(event.nativeEvent.layout.width)
          }}
        >
          {!IS_WEB && (
            <TaskListModalMedia
              isVideo={isVideo}
              fileSource={fileSource as AVPlaybackSource}
            />
          )}
          <HTML
            defaultTextProps={{
              selectable: true,
            }}
            baseStyle={{
              marginBottom: 30,
              fontSize: 18,
              fontWeight: 'normal',
              letterSpacing: -0.17,
              lineHeight: 32,
            }}
            source={{ html: descriptionHtml }}
            renderers={HTMLRenderers}
            WebView={WebView}
            contentWidth={contentWidth}
            customHTMLElementModels={customHTMLElementModels}
            renderersProps={{
              iframe: {
                scalesPageToFit: true,
              },
              img: {
                enableExperimentalPercentWidth: true,
              },
            }}
          />
        </ScrollView>
        {IS_WEB && !isCommentTask && (
          <TaskNotes
            uniqueId={`${task.id}:${dayISO}`}
            taskId={task.id}
            sessionUuid={sessionUuid}
            locationHierarchyId={locationHierarchyId}
          />
        )}
        <View
          style={[
            styles.bottomContainerWrap,
            !task.showNotApplicableOption ? { width: 'auto' } : {},
          ]}
        >
          <View style={styles.bottomContainer}>
            <View
              style={{
                marginRight: 24,
              }}
            >
              <CompleteButton
                task={task}
                {...{
                  locationHierarchyId,
                  sessionUuid,
                  completedEvent,
                  styles,
                  closeModal,
                }}
              />
            </View>
            <View
              style={{
                marginRight: IS_WEB ? 24 : 0,
              }}
            >
              <CantCompleteButton
                selected={isCantCompleteEvent(completedEvent)}
                onPress={onCantComplete}
              />
            </View>
            {IS_WEB && task.showNotApplicableOption && (
              <NotApplicableButton
                selected={isNotApplicable(completedEvent)}
                onPress={onNotApplicable}
              />
            )}
          </View>
          <View
            style={[styles.bottomContainerSecond, { justifyContent: 'center' }]}
          >
            {!IS_WEB && (
              <View
                style={{
                  flex: addNotesFlex,
                  marginRight: 20,
                  maxWidth: IS_WEB ? 200 : 'auto',
                }}
              >
                <Button
                  containerStyle={styles.buttonContainerStyle}
                  buttonStyle={[styles.buttonStyle, styles.addNotePhotosButton]}
                  titleStyle={[
                    styles.buttonTextStyle,
                    styles.addNotePhotosButtonTitle,
                  ]}
                  icon={
                    <Icon
                      name={ICON_NAMES.STICKY_NOTE}
                      size={25}
                      color={iconColor}
                      style={styles.buttonIcon}
                    />
                  }
                  title="Add Notes"
                  type="outline"
                  onPress={onAddNotePhotos}
                  titleProps={{
                    allowFontScaling: false,
                  }}
                />
              </View>
            )}
            {!IS_WEB && task.showNotApplicableOption && (
              <View
                style={{
                  flex: 1,
                  maxWidth: IS_WEB ? 200 : 'auto',
                }}
              >
                <NotApplicableButton
                  selected={isNotApplicable(completedEvent)}
                  onPress={onNotApplicable}
                />
              </View>
            )}
          </View>
        </View>
        {IS_WEB &&
          (!!completedEvent?.cantCompleteText ||
            !!completedEvent?.text ||
            !!completedEvent?.notApplicableText ||
            !!completedEvent?.mediaUrls) && (
            <View
              style={{ paddingLeft: 45, paddingRight: 30, marginBottom: 40 }}
            >
              {!!completedEvent?.cantCompleteText && (
                <Text style={{ color: COLORS.SECONDARY }}>
                  {completedEvent?.cantCompleteText}
                </Text>
              )}
              {!!completedEvent?.notApplicableText && (
                <Text style={{ color: COLORS.ORANGE }}>
                  {completedEvent?.notApplicableText}
                </Text>
              )}
              {!!completedEvent?.text && (
                <Text style={{ color: COLORS.TURQUOISE }}>
                  {completedEvent?.text}
                </Text>
              )}
              {!!completedEvent?.mediaUrls &&
                renderMediaUrls(completedEvent.mediaUrls)}
            </View>
          )}
      </View>
      {cantCompleteModalOpen && (
        <TextModal
          isOpen={true}
          setModalOpen={setCantCompleteModalOpen}
          onSubmit={cantComplete}
          completeText={cantCompleteText}
          eventType={EVENT_TYPES.ADD_CANT_TEXT}
          taskId={task?.id}
          title={t('cantCompleteTask')}
          {...{ sessionUuid, locationHierarchyId }}
        />
      )}

      {notApplicableModalOpen && (
        <TextModal
          isOpen={true}
          setModalOpen={setNotApplicableModalOpen}
          onSubmit={handleNotApplicableSubmitCallback}
          completeText={notApplicableText}
          eventType={EVENT_TYPES.NOT_APPLICABLE}
          taskId={task?.id}
          title={t('notApplicable')}
          {...{ sessionUuid, locationHierarchyId }}
        />
      )}
    </ModalComponent>
  )
}

const styles = EStyleSheet.create({
  container: {
    paddingHorizontal: IS_WEB ? 0 : 16,
    marginTop: IS_WEB ? 0 : 32,
    paddingTop: IS_WEB ? 0 : 8,
    paddingBottom: 16,
    backgroundColor: IS_WEB ? 'transparent' : '#f4f4f4',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  closeButton: {
    width: 50,
    height: 50,
    position: 'absolute',
    left: 10,
  },
  imageStyle: {
    width: '80%',
  },
  fileContainer: {
    width: '95%',
    height: IS_WEB ? 'auto' : percentScreenHeight(60),
  },
  descriptionContainer: {
    marginTop: -25,
    marginBottom: -10,
  },
  descriptionStyle: {
    marginTop: 15,
  },
  scrollContainer: {
    marginTop: 32,
    flexGrow: 1,
    paddingLeft: IS_WEB ? 45 : 0,
    paddingRight: IS_WEB ? 30 : 0,
    paddingHorizontal: IS_WEB ? 0 : 16,
  },
  bottomContainerWrap: {
    flexDirection: 'column',
    paddingTop: 36,
    width: '100%',
    paddingLeft: IS_WEB ? 45 : 0,
  },
  bottomContainer: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingBottom: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bottomContainerSecond: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonStyle: {
    borderWidth: 1,
    height: 48,
    backgroundColor: COLORS.TRANSPARENT,
    borderRadius: 6,
  },
  buttonTextStyle: {
    fontSize: IS_WEB ? 16 : 14,
  },
  addNotePhotosButtonTitle: {
    color: COLORS.BLUEDARK,
  },
  addNotePhotosButton: {
    borderColor: COLORS.BLUEDARK,
  },
  buttonIcon: {
    marginRight: 10,
  },
  modalCloseIcon: {
    position: 'absolute',
    top: -4,
    right: 8,
  },
  buttonContainerStyle: {
    width: IS_WEB ? 170 : '100%',
  },
})

const parseJson = (task: TaskInterface) => {
  try {
    return JSON.parse(task.description)
  } catch (err) {
    captureException(err)
    console.log(
      `An error occurred while parsing json task.description ${task.description} for task.id ${task.id}`,
    )
    return EMPTY_REACT_DRAFT_WYSIWYG_STRING
  }
}

const CompleteButton = ({
  task,
  locationHierarchyId,
  sessionUuid,
  completedEvent,
  styles,
  closeModal,
}: any) => (
  <TaskCompletionElement
    {...task}
    {...{
      locationHierarchyId,
      sessionUuid,
      completedEvent,
      showAsButton: true,
      closeModal,
    }}
  />
)
