// all route and tab constants
export const ROUTE_NAMES = {
  LOGIN: '/LOGIN',
  RESET_PASSWORD: '/RESET_PASSWORD',
  TASKS_ALL: '/TASKS_ALL',
  TASK_LIST: '/TASK_LIST',
  LOCATIONS: '/LOCATIONS',
  TASK_FORM_NEW: '/TASK_FORM_NEW',
  TASK_FORM_UPDATE: '/TASK_FORM_UPDATE',
  TASKS_TODAY: '/TASKS_TODAY',
  CAMERA_COMPLETE_TASK: '/CAMERA',
  SIGN_COMPLETE_TASK: '/SIGN',
  TEXT_COMPLETE_TASK: '/TEXT',
  LAST_IMAGE_TAKEN: '/LAST_IMAGE_TAKEN',
  LOCATION_TEXT_AND_PHOTOS: '/LOCATION_TEXT_AND_PHOTOS',
  CLIENT_PAGES: '/CLIENT_PAGES',
  CLIENT_PAGE: '/CLIENT_PAGE/:pageId',
  RESOURCES: '/RESOURCES',
  REPORTS: '/REPORTS',
}

// config for all the screens and tabs present in the app
export const ROUTES_CONFIG = {
  [ROUTE_NAMES.TASK_LIST]: {
    headerTitle: 'Tasks',
  },
  [ROUTE_NAMES.LOCATIONS]: {
    tabTitle: 'By folder',
    headerTitle: 'Locations',
  },
  [ROUTE_NAMES.TASKS_ALL]: {
    tabTitle: 'All tasks',
  },
  [ROUTE_NAMES.TASKS_TODAY]: {
    tabTitle: "Today's tasks",
    headerTitle: "Today's tasks",
  },
  [ROUTE_NAMES.TASK_FORM_NEW]: {
    headerTitle: 'New Task',
  },
  [ROUTE_NAMES.TASK_FORM_UPDATE]: {
    headerTitle: 'Edit Task',
  },
  [ROUTE_NAMES.RESOURCES]: {
    headerTitle: 'Resources',
  },
}

// initial screen
export const INITIAL_ROUTE_NAME = ROUTE_NAMES.TASKS_ALL

export const EMPTY_REACT_DRAFT_WYSIWYG_STRING =
  '{"blocks":[{"key":"b881g","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
