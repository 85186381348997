import React from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import COLORS from 'constants/colors'
import TaskSubtitle from './TaskSubtitle'

type TaskTitleProps = {
  title: string
  taskType: string
  frequencyType: string
  completedEvent: TaskCompleteType
  additionalNotesText: string
  onOptionTaskClick?: (value: string) => void
  important: boolean
  estimateInMins?: number
}

const TaskTitle = ({
  title,
  additionalNotesText,
  important,
  estimateInMins,
}: TaskTitleProps) => {
  return (
    <View style={styles.task}>
      <View style={styles.taskTextsContainer}>
        <Text style={styles.taskText} numberOfLines={2}>
          {title}
        </Text>
      </View>
      {!!additionalNotesText && (
        <View style={styles.taskIcons}>
          <Text
            style={{ color: COLORS.BLUEDARK, fontSize: 11 }}
            numberOfLines={1}
          >
            {additionalNotesText.replace(/[\n\r]/g, ' ')}
          </Text>
        </View>
      )}
      {!additionalNotesText && (
        <View
          style={{
            marginLeft: 10,
          }}
        >
          <TaskSubtitle estimateInMins={estimateInMins} important={important} />
        </View>
      )}
    </View>
  )
}

const styles = EStyleSheet.create({
  task: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 320,
  },
  taskTextsContainer: {
    marginLeft: 10,
  },
  taskText: {
    fontSize: 14,
    lineHeight: 18,
    color: COLORS.NAVY_BLUE,
  },
  taskIcons: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    left: 10,
    bottom: 0,
    right: 0,
    maxHeight: 17,
    overflow: 'hidden',
  },
  taskIcon: {
    marginRight: 5,
    color: COLORS.BLUEDARK,
    fontSize: 15,
  },
})

export default TaskTitle
