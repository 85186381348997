import * as React from 'react'
import Svg, { SvgProps, Path, G, Defs, ClipPath } from 'react-native-svg'

const CommentOutlined = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 37" {...props}>
    <Path
      d="M8.875 27.875v-1H4.5a3.5 3.5 0 0 1-3.5-3.5V4.5A3.5 3.5 0 0 1 4.5 1h27A3.5 3.5 0 0 1 35 4.5v18.875a3.5 3.5 0 0 1-3.5 3.5H19.168l-.27.21L8.874 34.88v-7.005z"
      fill="#fff"
      stroke="#50CEA2"
      strokeWidth={2}
    />
    <G
      clipPath="url(#a)"
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path d="M9.75 10.25h16.5M9.75 14h16.5M9.75 17.75h9" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path transform="translate(9 5)" fill="#fff" d="M0 0h18v18H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default CommentOutlined
