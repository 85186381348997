import { IS_WEB } from 'constants/static'

const completeButtonStyles = {
  buttonContainer: {
    alignSelf: 'center',
    width: IS_WEB ? 192 : '100%',
  },
  iconStyle: {
    marginRight: 6,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 60,
  },
}

export default completeButtonStyles
