import { SnippetTypeEnum } from 'constants/types'
import { cloneDeep } from 'lodash'
import {
  RawDraftContentState,
  RawDraftContentBlock,
  RawDraftInlineStyleRange,
  RawDraftEntityRange,
} from 'draft-js'

import {
  forOwn,
  identity,
  isNil,
  isObject,
  keyBy,
  mapValues,
  pickBy,
} from 'lodash'

// Using a custom implementation for matchAll because JS engine on android uses an older JS version
const matchAll = (re: RegExp, str: string) => {
  let match
  const matches = []

  while ((match = re.exec(str))) {
    matches.push(match)
  }

  return matches
}

type placeholderCallback = (
  offset: number,
  length: number,
  snippetName: string,
) => void

const findPlaceholders = (text: string, callback: placeholderCallback) => {
  let offset, length
  const matches = matchAll(/{{[a-zA-Z._]+}}/g, text)

  for (const match of matches) {
    offset = match.index
    length = match[0].length
    callback(offset, length, match[0].slice(2, -2))
  }
}

const setNewLengthForRange = (
  start: number,
  end: number,
  snippetValue: string,
  range: RawDraftInlineStyleRange | RawDraftEntityRange,
) => {
  const { offset, length, ...rest } = range
  let newLength = length
  let newOffset = offset

  if (offset === start) {
    newLength = snippetValue.length
  } else if (offset > start) {
    const diffBetweenPlaceholderAndValue = end - snippetValue.length
    newOffset -= diffBetweenPlaceholderAndValue
  }
  return { offset: newOffset, length: newLength, ...rest }
}

/*
  Takes in draft-js raw content and updates each block
  with the correct value for the snippet placeholder.
  Also updates the styles and entities offset after the text length change.
*/
const replaceSnippetsInDescription = (
  rawContent: RawDraftContentState,
  snippetsMapping: StringMap,
  onError?: (key: string) => void,
): RawDraftContentState => {
  let rawContentCopy = cloneDeep(rawContent) as RawDraftContentState

  const blocks = rawContentCopy.blocks.map(
    ({ text, inlineStyleRanges, entityRanges, ...rest }) => {
      let newText = text
      let newInlineStyleRanges = inlineStyleRanges
      let newEntityRanges = entityRanges

      findPlaceholders(text, (start, end, value) => {
        const snippetValue = snippetsMapping[value]
        if (!snippetValue && onError) {
          onError(value)
          return
        }

        newText = newText.replace(`{{${value}}}`, snippetValue)

        newInlineStyleRanges = newInlineStyleRanges.map((range) =>
          setNewLengthForRange(start, end, snippetValue, range),
        ) as RawDraftInlineStyleRange[]

        newEntityRanges = newEntityRanges.map((range) =>
          setNewLengthForRange(start, end, snippetValue, range),
        ) as RawDraftEntityRange[]
      })

      return {
        ...rest,
        text: newText,
        inlineStyleRanges: newInlineStyleRanges,
        entityRanges: newEntityRanges,
      } as RawDraftContentBlock
    },
  )

  return { ...rawContentCopy, blocks }
}

const replaceSnippetsInText = (
  text: string,
  snippetsMapping: StringMap = {},
  onError?: (key: string) => void,
) => {
  let newText = text
  findPlaceholders(text, (start: number, end: number, key: string) => {
    if (!snippetsMapping[key]) {
      if (onError) {
        onError(key)
      }
      return
    }

    newText = newText.replaceAll(`{{${key}}}`, snippetsMapping[key])
  })

  return newText
}

const mapUserTaxonomyTerms = (user: User) => {
  const termsByTaxonomy: { [key: string]: string[] } = {}
  const { taxonomyTerms } = user

  if (!taxonomyTerms) {
    return termsByTaxonomy
  }

  taxonomyTerms.forEach((taxonomyTerm: TaxonomyTerm) => {
    const { title, taxonomy } = taxonomyTerm

    if (!taxonomy) {
      return
    }

    const { slug } = taxonomy

    if (!termsByTaxonomy[slug]) {
      termsByTaxonomy[slug] = []
    }
    termsByTaxonomy[slug].push(title)
  })

  return mapValues(termsByTaxonomy, (val: string[]) => val.join())
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const buildDynamicSnippetsMap = (user: User): StringMap => {
  const employee = user?.employee || {}
  const manager = employee?.manager || {}
  const pickUserData = (targetUser: User | {}) =>
    pickBy(targetUser, (val: any) => !isObject(val) && !isNil(val))

  const employeeData = {
    ...pickUserData(user),
    ...pickUserData(employee),
    ...mapUserTaxonomyTerms(user),
  }
  const managerData = {
    ...pickUserData(manager),
  }

  const userData: StringMap = {}

  forOwn(employeeData, (value: any, key: string) => {
    userData[`employee.${camelToSnakeCase(key)}`] = value
  })

  forOwn(managerData, (value: any, key: string) => {
    userData[`manager.${camelToSnakeCase(key)}`] = value
  })

  return userData
}

const mapSnippetsForLocation = (
  snippets: Snippet[],
  owner?: User,
): StringMap => {
  const snippetMap = keyBy(snippets, 'name')
  const employeeSnippets: StringMap = {}
  if (owner) {
    const dynamicSnippets = buildDynamicSnippetsMap(owner)

    forOwn(snippetMap, (snippet: Snippet, key: string) => {
      if (snippet.snippetType === SnippetTypeEnum.DYNAMIC) {
        employeeSnippets[key] =
          dynamicSnippets[key] || snippet.fallbackValue || key
      }
    })
  }

  return {
    ...(pickBy(mapValues(snippetMap, 'value'), identity) as StringMap),
    ...employeeSnippets,
  }
}

export {
  mapSnippetsForLocation,
  replaceSnippetsInDescription,
  replaceSnippetsInText,
}
