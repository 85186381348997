import { useQuery } from '@apollo/react-hooks'
import Big from 'big.js'
import { Loader } from 'components/common/Loader'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { MODEL_TYPES } from 'constants/types'
import { callMutation } from 'graphql1/graphql-helpers'
import { CREATE_ORDER } from 'graphql1/mutations'
import { GET_CLIENT_PRODUCTS } from 'graphql1/queries'
import { useAppContext } from 'utils/app-context'
import React, { useEffect, useState } from 'react'

import {
  Alert,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { Button } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useApolloOfflineClient } from 'react-offix-hooks'
import OrderItem from './OrderItem'
import { getDefaultQueryVariables } from 'utils/apollo'

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  listContainer: {
    flexGrow: 1,
  },
  listWrap: {
    flex: 1,
  },
  totalContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'row',
  },
  totalText: {
    width: '50%',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sumText: {
    width: '50%',
    fontSize: 18,
    textAlign: 'right',
    fontWeight: 'bold',
    color: COLORS.TURQUOISE,
  },
  submitButton: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: COLORS.TURQUOISE,
  },
  dummyText: {
    height: 100,
  },
  TextInputWrapper: {
    flex: 1,
    height: 40,
    margin: 20,
  },
  FlexGrowOne: {
    flexGrow: 1,
  },
})
const getInitialInputValues = (data: ClientCustomerProductCatalogue[]) => {
  const values: any = {}

  data.forEach(({ id, price }) => {
    values[id] = {
      quant: 0,
      price,
    }
  })

  return values
}
export default function OrdersList({
  locationHierarchyId,
}: {
  locationHierarchyId: string
}) {
  const [focusIndex, setFocusIndex] = useState(-1)
  const [refreshing, setRefreshing] = useState(false)
  const [inputValues, setInputValues] = useState<any>({})
  const [sum, setSum] = useState('0.00')
  const appContextValue = useAppContext()
  const { data, refetch, loading, error } = useQuery(GET_CLIENT_PRODUCTS, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { locationHierarchyId },
  })
  const doneEdit = (itemId: string, value: string) => {}
  const itemFocused = (itemId: string) => {}
  const updateInputValue = (id: string, value: string) => {
    const currentInputValues = { ...inputValues }

    if (!currentInputValues[id]) {
      return
    }

    currentInputValues[id].quant = +value

    setInputValues(currentInputValues)
  }

  useEffect(() => {
    if (data?.clientProducts) {
      setInputValues(getInitialInputValues(data.clientProducts))
    }
  }, [data])

  useEffect(() => {
    if (!Object.values(inputValues).length) {
      return
    }
    const orderSum = Object.values(inputValues)
      .map((order: any) => new Big(order.quant || 0).times(+order.price))
      .reduce((a: Big, b: Big) => a.plus(b))
      .toFixed(2)

    setSum(orderSum)
  }, [inputValues])

  const renderItem = ({
    item,
    index,
  }: {
    item: ClientCustomerProductCatalogue
    index: number
  }) => (
    <OrderItem
      value={`${inputValues[item.id]?.quant || ''}`}
      product={item}
      key={item.id}
      doneEdit={doneEdit}
      itemFocused={itemFocused}
      valueChanged={(value: string) => updateInputValue(item.id, value)}
      focus={focusIndex === index}
    />
  )
  const client = useApolloOfflineClient()

  const onSubmit = () => {
    Alert.alert(
      'Submit Order',
      'Are you sure you want to submit the order?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'Yes', onPress: () => createOrder() },
      ],
      { cancelable: false },
    )
  }

  const createOrder = async () => {
    const orderProducts = Object.keys(inputValues)
      .filter((id) => {
        const { quant } = inputValues[id]

        return +quant > 0
      })
      .map((id) => {
        const { quant } = inputValues[id]

        return { id, quantity: quant }
      })
    const variables: any = {
      locationHierarchyId,
    }

    if (orderProducts.length) {
      variables.orderProducts = orderProducts
    }

    const result = await callMutation({
      client,
      mutation: CREATE_ORDER,
      returnType: MODEL_TYPES.ORDER,
      variables,
    })

    const message = result
      ? 'Order Created'
      : `Error occurred while creating the order`

    Alert.alert('Create Order', message, [{ text: 'OK' }], { cancelable: true })

    if (result) {
      setInputValues(getInitialInputValues(data.clientProducts))
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      style={styles.container}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <ScrollView style={styles.container}>
          {data?.clientProducts.map((item: any, index: number) =>
            renderItem({ item, index }),
          )}
          <View>
            <View style={styles.totalContainer}>
              <Text style={styles.totalText}>Total:</Text>
              <Text style={styles.sumText}>${sum}</Text>
            </View>
            <View>
              <Button
                title="Submit"
                disabled={!(+sum > 0)}
                buttonStyle={styles.submitButton}
                onPress={onSubmit}
              />
            </View>
          </View>
          <View>
            <Text style={styles.dummyText}></Text>
          </View>
        </ScrollView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}
