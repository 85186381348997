import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Medal = (props: SvgProps) => (
  <Svg viewBox="0 0 123 180" {...props}>
    <Path
      d="M92.192 110.85v69.146L61.46 156.947l-30.732 23.049V110.85h61.464z"
      fill="#2E4785"
    />
    <Path
      d="M92.192 180v-69.146H61.46v46.097L92.192 180z"
      clipRule="evenodd"
      fill="#243162"
      fillRule="evenodd"
    />
    <Path
      d="M61.464 122.93c33.945 0 61.463-27.518 61.463-61.463S95.409.003 61.464.003 0 27.521 0 61.467s27.518 61.463 61.464 61.463z"
      fill="#50CEA2"
    />
    <Path
      d="M61.464 122.93c33.945 0 61.463-27.518 61.463-61.463S95.409.003 61.464.003v122.93z"
      clipRule="evenodd"
      fill="#35928C"
      fillRule="evenodd"
    />
    <Path
      d="M61.461 85.52 37.719 98.004l4.534-26.441-19.207-18.726 26.544-3.858 11.871-24.057L73.332 48.98l26.544 3.858L80.67 71.563l4.535 26.441L61.462 85.52z"
      fill="#FFBD1B"
    />
    <Path
      d="M37.719 98.004 61.461 85.52V24.922L49.59 48.979l-26.544 3.858 19.207 18.726-4.534 26.44z"
      clipRule="evenodd"
      fill="#FFCE55"
      fillRule="evenodd"
    />
  </Svg>
)

export default Medal
