import { WatchQueryFetchPolicy, ErrorPolicy } from 'apollo-client'

export const END_POINT = process.env.END_POINT
export const JLL_CLIENT_ID = process.env.JLL_CLIENT_ID
export const JLL_OKTA_DOMAIN = process.env.JLL_OKTA_DOMAIN
export const JLL_DOMAINS_ENV = process.env.JLL_DOMAINS
export const JLL_DOMAINS = JLL_DOMAINS_ENV?.split(' ') || []

export const SONOMA_CLIENT_ID = process.env.SONOMA_CLIENT_ID
export const SONOMA_OKTA_DOMAIN = process.env.SONOMA_OKTA_DOMAIN
export const SONOMA_DOMAINS = process.env.SONOMA_DOMAINS?.split(' ') || []

export const GRAPHQL_END_POINT = `${END_POINT}/graphql`

export const UPLOAD_END_POINT = `${END_POINT}/upload`

export const ALIVE_ENDPOINT = `${END_POINT}/b7bf357a-a965-425a-833e-71215c13cbf4`

export const ALIVE_RESPONSE_EXPECTED = '71e75eed-1cc7-44e8-889e-f6d8155b43b6'

export const FETCH_POLICIES: { [key: string]: WatchQueryFetchPolicy } = {
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
  NETWORK_ONLY: 'network-only',
  CACHE_AND_NETWORK: 'cache-and-network',
  CACHE_FIRST: 'cache-first',
}

export const ERROR_POLICIES: { [key: string]: ErrorPolicy } = {
  NONE: 'none',
  IGNORE: 'ignore',
  ALL: 'all',
}

export const ALIVE_CHECK_TIMEOUT = 10000

export const NETWORK_TIMEOUT = 4000

export const NOTIFY_SERVER_DOWN_EVERY_HOUR = 0.5

export const STATUS_CODES = {
  UNAUTHORIZED: '401',
}
