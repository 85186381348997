import { QueueEntryOperation } from 'offix-client'
import MESSAGES from 'constants/messages'
import { writeErrorData } from 'utils/error-handling'

export const addApolloClientListeners = (cl: ClientType) => {
  // TODO: remove later
  cl.registerOfflineEventListener({
    onOperationEnqueued() {
      // called when operation was placed on the queue
      // tslint:disable-next-line no-console
      console.log('Offix Operation Enqueued')
    },
    onOperationFailure: (operation: QueueEntryOperation<any>) => {
      // TODO: upload image with own mutation when online
      // KEEP FOR NOW
      // const failedImageUploadUrl =
      // operation?.op?.variables?.attributes?.imageFile?.uri
      // quid = operation.quid
      // client.queue.entries.forEach((e: any) => {
      //   quid
      // })
      // called when the operation failed
      writeErrorData(cl, { message: MESSAGES.OPERATION_FAILURE })
      // tslint:disable-next-line no-console
      console.log('Offix Failure: ', operation)
    },
    onOperationSuccess: () => {
      // called when the operation was fulfilled
      // tslint:disable-next-line no-console
      console.log('Success')
    },
    onOperationRequeued: () => {
      // called when an operation was loaded in from storage and placed back on the queue
      // This would happen across app restarts
      // tslint:disable-next-line no-console
      console.log('Requeued')
    },
    queueCleared() {
      // KEEP FOR NOW
      // tslint:disable-next-line no-console
      console.log('queueCleared')
      // called when all operations are fulfilled and the queue is cleared
      cl.resetStore()
      cl.persistor.purge()
      cl.cache.reset()
    },
  })
}
