import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { View } from 'react-native'

import { GET_PAGE } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import { Loader } from 'components/common/Loader'

import PageSection from './PageSection'
import styled from 'styled-components'

const ContentPage = ({
  pageId,
  close,
}: {
  pageId: string
  close: () => void
}) => {
  const { data, loading } = useQuery(GET_PAGE, {
    variables: {
      pageId,
    },
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
  })

  if (loading || !data) {
    return <Loader />
  }

  const {
    contentPage: { sections },
  } = data

  return (
    <View>
      <Container>
        <Wrapper>
          <Back>
            <img
              src={require('assets/images/png/arrow_left.png')}
              width={13}
              onClick={() => close()}
            />
            <div onClick={() => close()}>Back</div>
          </Back>
          {sections.map((section: any) =>
            section.hidden ? null : (
              <PageSection key={section.id} section={section} />
            ),
          )}
        </Wrapper>
      </Container>
    </View>
  )
}

export default ContentPage

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #eeeeee;
`

const Wrapper = styled.div`
  width: 942px;
  background-color: #fff;
`

const Back = styled.div`
  padding: 20px 20px 0px;
  display: flex;
  align-items: center;

  font-family: 'Poppins_400Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #333333;

  * {
    cursor: pointer;
  }
  img {
    margin-right: 5px;
  }
`
