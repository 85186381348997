import { useQuery } from '@apollo/react-hooks'
import Feedback from 'components/common/Feedback'
import RowView from 'components/common/fragments/RowView'
import { default as React, useEffect, useMemo, useState } from 'react'
import { Image as NativeImage, View } from 'react-native'
import { Card } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useApolloOfflineClient } from 'react-offix-hooks'

import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import { Icon } from 'components/Index'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { ICON_NAMES } from 'constants/icons'
import { IS_WEB } from 'constants/static'
import { APP_DATA, TASK_PHOTOS_QUERY, TASK_TEXT_QUERY } from 'graphql1/queries'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import TaskListModalWeb from './TaskListModalWeb'
import { isSupportedVideo } from 'utils/phone'
import TaskCompletionElement from './TaskCompletionElement'
import { TaskListModal } from './TaskListModal'
import TaskTitle from './TaskTitle'
import Image from 'components/common/Image'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { captureException } from 'utils/sentry'

const defaultTaskImage = require('assets/images/png/default_task_image.png')
const defaultVideoImage = require('assets/images/jpg/video_preview_default.jpg')
const IMAGE_SIZE = IS_WEB ? 32 : 60

// row in tasklist
const TaskListElement = ({
  sessionUuid,
  locationHierarchyId,
  completedEvent,
  snippetsMapping,
  showSnippets,
  // @ts-ignore
  ...task
}: TaskEntryPropsType) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const client = useApolloOfflineClient()
  const appContextValue = useAppContext()
  const {
    id: taskId,
    title,
    taskType,
    mediaUrl,
    frequencyType,
    important,
    estimateInMins,
  } = task
  const fileUrlRaw = mediaUrl || ''
  const isVideo = isSupportedVideo(fileUrlRaw)
  const dayISO = ISODayString(appContextValue.selectedDay)
  let previewSource = mediaUrl
    ? { uri: mediaUrl }
    : !IS_WEB
    ? NativeImage.resolveAssetSource(defaultTaskImage)
    : { uri: defaultTaskImage }
  const fileSource = previewSource
  const { data } =
    useQuery<{
      appData: AppDataType
    }>(APP_DATA)
  const createEvent = useCreateEvent({
    sessionUuid,
    taskId,
    locationHierarchyId,
  })
  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      captureException(`Missing snippet: ${key}, taskId: ${taskId}`)
    },
    [taskId],
  )
  const titleWithSnippets = useMemo(
    () => replaceSnippetsInText(title, snippetsMapping, handleMissingSnippet),
    [title, snippetsMapping],
  )

  const { data: taskTextData } = useQuery<{ taskText: Array<LocationText> }>(
    TASK_TEXT_QUERY,
    {
      fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
      variables: { taskId: task.id, date: dayISO },
    },
  )

  const { data: taskPhotosData } = useQuery(TASK_PHOTOS_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { taskId: task.id, date: dayISO },
  })

  if (isVideo) {
    previewSource = defaultVideoImage
  }

  const openModal = () => {
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: task.id,
          __typename: 'AppData',
        },
      },
    })
    setModalOpen(true)
  }

  const closeModal = (removeModalFromCache = false) => {
    if (IS_WEB) {
      return
    }

    if (removeModalFromCache) {
      client.writeData({
        data: {
          appData: {
            taskDescriptionModalId: '',
            __typename: 'AppData',
          },
        },
      })
    }
    setModalOpen(false)
  }

  useEffect(() => {
    if (data?.appData?.taskDescriptionModalId === String(task.id)) {
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [data])

  const isNotUploaded = completedEvent && !Boolean(completedEvent?.createdAt)

  return (
    <View key={taskId} style={{ height: IS_WEB ? 72 : 90 }}>
      <Card
        containerStyle={[
          styles.cardContainer,
          IS_WEB
            ? {
                backgroundColor: COLORS.WHITE,
                borderRadius: 8,
                borderWidth: modalOpen ? 2 : 0,
                borderColor: COLORS.TURQUOISE,
              }
            : {},
        ]}
        wrapperStyle={styles.cardWrapper}
      >
        <RowView style={styles.content}>
          <Feedback onPress={openModal} style={styles.feedback}>
            <View style={styles.imageAndTitleWrapper}>
              <Image
                containerStyle={styles.filePreviewContainer}
                style={styles.imageStyle}
                source={previewSource}
                PlaceholderContent={<GeneralActivityIndicator />}
              />
              <View style={styles.taskTitleWrapper}>
                <TaskTitle
                  {...{
                    title: titleWithSnippets,
                    taskType,
                    frequencyType,
                    additionalNotesText: taskTextData?.taskText[0]?.text,
                    important,
                    estimateInMins,
                  }}
                />
              </View>
            </View>
          </Feedback>
          <View>
            {/*TaskCompletionElement can be of one of the types - check, photo, sign etc.
              Defined in this file above: TaskCompletionElement*/}
            <TaskCompletionElement
              {...task}
              {...{
                locationHierarchyId,
                sessionUuid,
                completedEvent,
              }}
            />
          </View>
          {isNotUploaded && (
            <View style={styles.taskNotUploadedWrapper}>
              <Icon name={ICON_NAMES.CLOUD_OFF} style={styles.taskIcon} />
            </View>
          )}
        </RowView>

        {modalOpen && (
          <>
            {IS_WEB ? (
              <TaskListModalWeb>
                <TaskListModal
                  isOpen={modalOpen}
                  hasTextOrPhotos={
                    taskPhotosData?.taskPhotos?.length ||
                    taskTextData?.taskText?.length
                  }
                  {...{
                    closeModal,
                    task: { ...task, title: titleWithSnippets },
                    fileSource,
                    isVideo,
                    sessionUuid,
                    completedEvent,
                    locationHierarchyId,
                    snippetsMapping,
                    showSnippets,
                  }}
                />
              </TaskListModalWeb>
            ) : (
              <TaskListModal
                isOpen={modalOpen}
                hasTextOrPhotos={
                  taskPhotosData?.taskPhotos?.length ||
                  taskTextData?.taskText?.length
                }
                {...{
                  closeModal,
                  task: { ...task, title: titleWithSnippets },
                  fileSource,
                  isVideo,
                  sessionUuid,
                  completedEvent,
                  locationHierarchyId,
                  snippetsMapping,
                  showSnippets,
                }}
              />
            )}
          </>
        )}
      </Card>
    </View>
  )
}

const styles = EStyleSheet.create({
  cardContainer: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 0,
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 4,
    marginBottom: 4,
    height: 90,
    borderRadius: 8,
    borderWidth: 0,
  },
  content: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  feedback: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  imageAndTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100%',
    alignItems: 'center',
  },
  cardWrapper: {
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
  },
  filePreviewContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: 6,
    overflow: 'hidden',
    marginRight: IS_WEB ? 16 : 5,
    marginLeft: IS_WEB ? 16 : 7,
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  taskTitleWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 10,
    height: '100%',
  },
  taskNotUploadedWrapper: {
    position: 'absolute',
    right: 21,
    bottom: 0,
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    flexGrow: 1,
  },
  taskTexts: {
    marginLeft: 10,
  },
  taskIcons: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    left: 10,
    bottom: 0,
  },
  taskIcon: {
    marginRight: 5,
    color: COLORS.YELLOW,
  },
  taskDate: {
    fontSize: '$fontSmall',
    flexDirection: 'row',
    marginLeft: 2,
  },
  taskTypeIconView: {
    marginLeft: 5,
  },
  taskTypeNameLabel: {
    fontSize: '$fontSmall',
    marginRight: 1,
  },
  Filler: {
    flex: 1,
  },
  taskRemoveElement: {
    marginRight: 10,
  },
})

export default TaskListElement
