import React, { useCallback, useEffect, useState } from 'react'
import COLORS from 'constants/colors'
import { ArrowRightIcon, CheckmarkIcon } from 'components/common/custom-icons'
import { startASession } from './locations-helper'
import { ROUTE_NAMES } from 'navigation/constants'
import { useHistory } from 'react-router'
import { useApolloOfflineClient } from 'react-offix-hooks'
import { useAppContext } from 'utils/app-context'
import MESSAGES from 'constants/messages'
import { ImageSourcePropType, View, Text } from 'react-native'
import Feedback from 'components/common/Feedback'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useTranslation } from 'react-i18next'

const defaultLocationImage = require('assets/images/jpg/location_default.jpg')

const ManagerLocation = ({
  location,
  onFolderChange,
  parentImageSource,
}: {
  location: LocationInterface
  onFolderChange: (
    newParentId: string,
    newParentImageSource: ImageSourcePropType,
    owner?: User,
  ) => void
  parentImageSource: ImageSourcePropType
}) => {
  const history = useHistory()
  const [hasOpenSession, setHasOpenSession] = useState<boolean | null>(null)
  const hasChildLocations = location.children?.length > 0
  const locationHierarchyId = location.id
  const interactable = hasOpenSession !== null
  const client = useApolloOfflineClient()
  const appContextValue = useAppContext()
  const { t } = useTranslation()
  const { selectedDay } = appContextValue
  const owner = location?.owner
  const navigationData = {
    locationHierarchyId,
    locationTitle: location.title,
    sessionUuid: location.sessionUuid,
    completed: location.completed,
    notes: location.notes,
    loadData: true,
    owner,
  }
  const imageSource = location.logoUrl
    ? { uri: location.logoUrl }
    : parentImageSource || defaultLocationImage

  useEffect(() => {
    setHasOpenSession(Boolean(location.sessionUuid))
  }, [Boolean(location.sessionUuid)])

  const changeToFolder = useCallback(() => {
    if (!interactable) {
      return
    }

    if (hasChildLocations) {
      onFolderChange(locationHierarchyId, imageSource, owner)
    } else if (!Boolean(location.numberTasks)) {
      alert(MESSAGES.NO_TASKS_OR_SUBLOCATIONS)
    } else {
      goToTasksToday()
    }
  }, [navigationData, imageSource, hasOpenSession])

  const goToTasksToday = () => {
    if (hasOpenSession) {
      history.push({
        pathname: ROUTE_NAMES.TASKS_TODAY,
        search: `?locationHierarchyId=${location.id}`,
        state: navigationData,
      })
    } else {
      startASession(
        client,
        location.id,
        selectedDay,
        location.title,
        appContextValue,
        location.notes,
        history,
      )
    }
  }

  const completedTasksCount =
    location.completed + location.cantComplete + location.notApplicable
  const percent =
    location.tasks > 0
      ? Math.round((completedTasksCount / location.tasks) * 100)
      : 0

  const renderTaskNumber = () => {
    if (percent == 0) {
      const taskString = location.tasks == 1 ? t('task') : t('tasks')

      return `${location.tasks} ${taskString}`
    }

    return `${completedTasksCount} / ${location.tasks}`
  }

  return (
    <Feedback style={styles.container} onPress={changeToFolder}>
      <Text style={styles.locationLeft}>{location.title}</Text>
      <View style={styles.locationRight}>
        <Text style={styles.locationTasksNumber}>{renderTaskNumber()}</Text>
        <Text style={styles.locationTasksPercentage}>{percent}%</Text>
        <View>
          {percent == 100 ? (
            <CheckmarkIcon height={24} width={24} />
          ) : (
            <ArrowRightIcon height={24} width={24} />
          )}
        </View>
      </View>
    </Feedback>
  )
}

const styles = EStyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
    padding: 12,
    backgroundColor: '#f5f7fb',
    borderRadius: 8,
  },
  locationLeft: {
    flex: 1,
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 24,
  },
  locationRight: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 24,
  },
  locationTasksNumber: {
    minWidth: 50,
    fontWeight: '400',
    color: COLORS.SIXES_GREY,
  },
  locationTasksPercentage: {
    fontWeight: '600',
    fontSize: 14,
    color: COLORS.DARK_TURQUOISE,
  },
})

export default ManagerLocation
