import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { Text, View } from 'react-native'
import { Icon } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useHistory, useLocation } from 'react-router'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'

import { Loader } from 'components/common/Loader'
import { LocationNotesModal } from 'components/locations/LocationNotesModal'
import OrdersList from 'components/orders/OrdersList'
import { TextModal } from 'components/task-complete/TextModal'

import { FETCH_POLICIES } from 'constants/api'
import { IS_WEB } from 'constants/static'
import COLORS from 'constants/colors'
import { EVENT_TYPES, CACHE_KEYS } from 'constants/types'

import {
  LOCATION_PHOTOS_QUERY,
  TASKS_SCREEN_QUERY,
  GET_CLIENT_CONFIG,
  LOCATION_SCREEN_QUERY,
} from 'graphql/queries'
import groupBy from 'lodash/groupBy'
import { ROUTE_NAMES } from 'navigation/constants'
import {
  getDefaultQueryVariables,
  getTaskCompleteVariables,
} from 'utils/apollo'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import { useHeaderContext } from 'utils/header-context'
import TaskListMapping from './TaskListMapping'
import { mapSnippetsForLocation } from 'utils/helpers/snippets-helper'
import { getLevelTree } from 'components/locations/locations-helper'
import { useTranslation } from 'react-i18next'
const Tab = createMaterialBottomTabNavigator()

const getRightHeader = ({
  history,
  setModalOpen,
  showInfoIcon,
  sessionUuid,
  locationText,
  locationHasPhotosOrText,
  locationHierarchyId,
  day,
  setLocationNotesModalOpen,
  showOnboarding,
}: {
  history: any
  setModalOpen: Dispatch<SetStateAction<boolean>>
  showInfoIcon: boolean
  sessionUuid: string
  locationText: string
  locationHasPhotosOrText: boolean
  locationHierarchyId: string
  day: string
  setLocationNotesModalOpen: Dispatch<SetStateAction<boolean>>
  showOnboarding: boolean
}) => ({
  headerRight: () => (
    <View style={styles.headerRightContainer}>
      {!showOnboarding && (
        <TouchableOpacity
          style={{ marginRight: 10 }}
          onPress={() => {
            if (IS_WEB) {
              setLocationNotesModalOpen(true)
            } else {
              history.push({
                pathname: ROUTE_NAMES.LOCATION_TEXT_AND_PHOTOS,
                state: {
                  sessionUuid,
                  loadData: true,
                  completed: { text: locationText },
                  locationHierarchyId,
                  uniqueId: `${locationHierarchyId}:${day}`,
                },
              })
            }
          }}
        >
          <Icon
            name="message"
            type="material"
            size={30}
            color={
              locationHasPhotosOrText ? COLORS.TURQUOISE : COLORS.GRAYMEDIUM
            }
          />
        </TouchableOpacity>
      )}

      {showInfoIcon && (
        <TouchableOpacity
          style={{ marginRight: 15 }}
          onPress={() => {
            setModalOpen(true)
          }}
        >
          <Icon
            name="info"
            type="material"
            size={30}
            color={COLORS.TURQUOISE}
          />
        </TouchableOpacity>
      )}
    </View>
  ),
})

// component to render the task list
export default function TaskTodayScreen() {
  const location = useLocation<any>()
  const appContextValue = useAppContext()
  const variables = getDefaultQueryVariables(appContextValue)
  const dayISO = ISODayString(appContextValue.selectedDay)
  const history = useHistory<TaskTodayScreenNavigationState>()
  const { setOptions } = useHeaderContext()
  const [locationNotesModalOpen, setLocationNotesModalOpen] = useState(false)
  const locationState = location?.state || {}
  const { t } = useTranslation()

  const locationTitle = locationState?.locationTitle
  const sessionUuid = locationState?.sessionUuid
  const locationNotes = locationState?.notes
  let loadData = locationState?.loadData
  const locationHierarchyId = locationState.locationHierarchyId || null
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const taskCompleteVariables = getTaskCompleteVariables(
    locationHierarchyId,
    appContextValue,
  )

  const [executeScreenQuery, { data, loading, error, called }] = useLazyQuery(
    TASKS_SCREEN_QUERY,
    {
      fetchPolicy: loadData
        ? FETCH_POLICIES.CACHE_AND_NETWORK
        : FETCH_POLICIES.CACHE_ONLY,
      variables: { ...variables, ...taskCompleteVariables },
    },
  )

  const { data: clientConfigData } = useQuery(GET_CLIENT_CONFIG, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  const showOnboarding = clientConfigData?.clientConfig?.showOnboarding
  const showOrdersTab = clientConfigData?.clientConfig?.showOrdersTab

  const [executePhotosQuery, { data: locationPhotosData }] = useLazyQuery(
    LOCATION_PHOTOS_QUERY,
    {
      fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
      variables: taskCompleteVariables,
    },
  )

  const showSnippets =
    !!clientConfigData?.[CACHE_KEYS.CLIENT_CONFIG]?.showSnippets

  const tasksGrouped = groupBy(
    data?.[CACHE_KEYS.TASK_VIRTUAL] || [],
    'locationHierarchyId',
  )

  const locationText = data?.[CACHE_KEYS.LOCATION_TEXT]?.[0]?.text
  const locationHasPhotosOrText = Boolean(
    locationText?.trim().length || locationPhotosData?.locationPhotos?.length,
  )

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(LOCATION_SCREEN_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
    variables,
  })
  const locations = locationsData?.[CACHE_KEYS.LOCATION_HIERARCHIES] || []

  const refetch = () => {
    history.location.state = { ...history.location.state, loadData: true }

    Promise.all([executeScreenQuery(), executePhotosQuery()]).then(() => {
      history.location.state = { ...history.location.state, loadData: false }
    })
  }

  const snippetsMapping: StringMap = useMemo(() => {
    if (!data) {
      return {}
    }

    const locationHierarchy = data[CACHE_KEYS.LOCATION_HIERARCHY]

    return mapSnippetsForLocation(
      data[CACHE_KEYS.SNIPPETS],
      locationHierarchy?.rootNode?.owner,
    )
  }, [data, showSnippets])

  {
    /* Get the tasks for the given locationHierarchyId */
  }
  const tasks = tasksGrouped?.[locationHierarchyId]

  const onSessionTextSubmit = () => {
    setLocationNotesModalOpen(false)
  }

  useEffect(() => {
    if (!locationHierarchyId) {
      history.push(ROUTE_NAMES.LOCATIONS)
    }
    // This will change the way data is loaded, only the first time user navigates to the page
    // it will load data from the server, otherwise loads from cache
    Promise.all([executeScreenQuery(), executePhotosQuery()]).then(() => {
      history.location.state = { ...history.location.state, loadData: false }
    })
  }, [])

  const completedTaskEvents = data?.[CACHE_KEYS.TASKS_COMPLETE] || []
  // set header title
  useEffect(() => {
    setOptions({
      title: getLevelTree(locations, locationState?.locationHierarchyId),
      showBack: true,
      ...getRightHeader({
        history,
        setModalOpen,
        showInfoIcon: !!locationNotes,
        sessionUuid,
        locationText,
        locationHasPhotosOrText,
        locationHierarchyId,
        day: dayISO,
        setLocationNotesModalOpen,
        showOnboarding,
      }),
    })
  }, [locationTitle, locationHasPhotosOrText, locationText])

  const taskListProperties = {
    tasks,
    locationHierarchyId,
    sessionUuid,
    completedTaskEvents,
    loading,
    refetch,
  }

  if (!called || loading || locationsLoading) {
    return <Loader />
  }

  if (error || locationsError) {
    return <Text>An error occurred while fetching tasks ...</Text>
  }

  if (showOrdersTab) {
    return (
      <Tab.Navigator
        activeColor={COLORS.TURQUOISE}
        inactiveColor={COLORS.GRAYDARK1}
        barStyle={{ backgroundColor: COLORS.WHITE }}
      >
        <Tab.Screen name="Tasks">
          {() => (
            <View style={{ flex: 1 }}>
              <TaskListMapping
                {...taskListProperties}
                additionalPadding={true}
                snippetsMapping={snippetsMapping}
                showSnippets={showSnippets}
              />
              {locationNotes && (
                <LocationNotesModal
                  isOpen={modalOpen}
                  notes={locationNotes}
                  setModalOpen={setModalOpen}
                />
              )}
            </View>
          )}
        </Tab.Screen>
        <Tab.Screen name="Orders">
          {() => <OrdersList locationHierarchyId={locationHierarchyId} />}
        </Tab.Screen>
      </Tab.Navigator>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <TaskListMapping
        {...taskListProperties}
        snippetsMapping={snippetsMapping}
        showSnippets={showSnippets}
      />
      {locationNotes && (
        <LocationNotesModal
          isOpen={modalOpen}
          notes={locationNotes}
          setModalOpen={setModalOpen}
        />
      )}
      {IS_WEB && (
        <TextModal
          isOpen={locationNotesModalOpen}
          setModalOpen={setLocationNotesModalOpen}
          onSubmit={onSessionTextSubmit}
          completeText={locationText}
          eventType={EVENT_TYPES.LOCATION_TEXT}
          submitButtonText={t('sessionNotes')}
          title={t('sessionNotes')}
          {...{ sessionUuid, locationHierarchyId, taskId: undefined }}
        />
      )}
    </View>
  )
}

// styles
const styles = EStyleSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
})
