import React, { useState } from 'react'
import {
  View,
  TouchableOpacity,
  Platform,
  Dimensions,
  useWindowDimensions,
} from 'react-native'
import Modal from 'react-native-modal'
import draftToHtml from 'draftjs-to-html'
import HTML from 'react-native-render-html'
import { ScrollView } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import { stylesGlobal } from 'constants/es-style'
import { percentScreenHeight } from 'utils/animations'
import { EMPTY_REACT_DRAFT_WYSIWYG_STRING } from 'navigation/constants'
import { captureException } from 'utils/sentry'
import EStyleSheet from 'react-native-extended-stylesheet'
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin'
import WebView from 'react-native-webview'
import {
  customHTMLElementModels,
  HTMLRenderers,
} from 'utils/helpers/html-render-helper'

type LocationNotesModalProps = {
  isOpen: boolean
  setModalOpen: DispatchType<boolean>
  notes: string
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: 32,
    backgroundColor: '#f4f4f4',
    maxHeight: '100%',
    width: '100%',
  },
  fileContainer: {
    width: '100%',
    height: percentScreenHeight(60),
  },
  descriptionContainer: {
    marginTop: -25,
    marginBottom: -10,
    ...(Platform.OS === 'web' ? { overflow: 'auto', maxHeight: '100%' } : {}),
  },
  descriptionStyle: {
    marginTop: 15,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 50,
    height: 50,
    zIndex: 1000,
  },
})

const parseJson = (notes: string) => {
  try {
    return JSON.parse(notes)
  } catch (err) {
    captureException(err)
    console.log(`An error occurred while parsing json notes ${notes}`)
    return EMPTY_REACT_DRAFT_WYSIWYG_STRING
  }
}

export const LocationNotesModal = ({
  isOpen,
  setModalOpen,
  notes,
}: LocationNotesModalProps) => {
  const closeModal = () => setModalOpen(false)
  const { width } = useWindowDimensions()
  const [contentWidth, setContentWidth] = useState(width)

  return (
    <Modal isVisible={isOpen} style={stylesGlobal.modal}>
      <View style={[stylesGlobal.modalContent, styles.container]}>
        <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
          <Icon
            name={ICON_NAMES.CLOSE}
            size={30}
            style={stylesGlobal.modalCloseIcon}
            onPress={closeModal}
          />
        </TouchableOpacity>
        <SafeAreaView style={styles.descriptionContainer}>
          <ScrollView
            onLayout={(event) => {
              setContentWidth(event.nativeEvent.layout.width)
            }}
          >
            <HTML
              defaultTextProps={{
                selectable: true,
              }}
              baseStyle={{
                marginBottom: 30,
                fontSize: 18,
                fontWeight: 'normal',
                letterSpacing: -0.17,
                lineHeight: 32,
              }}
              source={{ html: draftToHtml(parseJson(notes)) }}
              renderers={HTMLRenderers}
              WebView={WebView}
              customHTMLElementModels={customHTMLElementModels}
              contentWidth={contentWidth}
              renderersProps={{
                iframe: {
                  scalesPageToFit: true,
                },
                img: { enableExperimentalPercentWidth: true },
              }}
            />
          </ScrollView>
        </SafeAreaView>
      </View>
    </Modal>
  )
}
