import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'
import { MODEL_TYPES } from 'constants/types'

const commonTaskFields = `
  id
  title
  description
  taskType
  mediaUrl
  frequencyType
  dueDate
  showNotApplicableOption
  estimateInMins
  important
  docusignEnvelopeId
  options {
    id
    option
  }
`

export const TASK_COMPLETE_FIELDS = `
  id
  sessionUuid
  taskId
  imageUrl
  mediaUrls
  text
  cantCompleteText
  notApplicableText
  eventType
  createdAt
  completedBy {
    fullName
  }
`

export const FRAGMENT_LOCATION_HIERARCHY_FIELDS = gql`
  fragment LocationHierarchyFields on LocationHierarchyWithAnalytics {
    id
    title
    numberTasks
    clientCustomerId
    clientAddress
    invoiceFileUrls
    logoUrl
    children {
      id
    }
    notes
    owner {
      id
      fullName
      employee {
        startDate
      }
    }
    tasks
    completed
    notApplicable
    cantComplete
  }
`

export const FRAGMENT_TASK_FIELDS = gql`
  fragment TaskFields on Task {
    ${commonTaskFields}
  }
`

export const FRAGMENT_TASK_VIRTUAL_FIELDS = gql`
  fragment TaskVirtualFields on TaskVirtual {
    ${commonTaskFields}
    locationHierarchyId
  }
`

export const TASK_VIRTUAL_COMPLETE_FIELD_FRAGMENT = gql`
  fragment CompleteField${MODEL_TYPES.TASK_VIRTUAL} on ${MODEL_TYPES.TASK_VIRTUAL} {
    complete
  }
`
export const TASK_VIRTUAL_LAST_IMAGE_URL_FIELD_FRAGMENT = gql`
  fragment LastImageUrlField${MODEL_TYPES.TASK_VIRTUAL} on ${MODEL_TYPES.TASK_VIRTUAL} {
    lastImageUrl
  }
`

export const FRAGMENT_EVENT_FIELDS = gql`
  fragment EventFields on Event {
    id
    eventType
    imageUrl
    createdAt
    mediaUrls
    user {
      fullName
    }
  }
`

export const FRAGMENT_SESSION_FIELDS = gql`
  fragment SessionFields on Session {
    uuid
    day
    locationHierarchyId
  }
`

export const FRAGMENT_LOCATION_TEXT_FIELDS = gql`
  fragment LocationTextFields on LocationText {
    id
    text
  }
`

export const FRAGMENT_LOCATION_PHOTO_FIELDS = gql`
  fragment LocationPhotoFields on LocationPhoto {
    imageUrl
    id
  }
`
export const FRAGMENT_TASK_TEXT_FIELDS = gql`
  fragment TaskTextFields on TaskText {
    id
    text
  }
`

export const FRAGMENT_TASK_PHOTO_FIELDS = gql`
  fragment TaskPhotoFields on TaskPhoto {
    imageUrl
    id
  }
`

export const FRAGMENT_USER_OWNER_FIELDS = gql`
  fragment UserOwnerFields on User {
    firstName
    lastName
    fullName
    email
    workEmail
    personalEmail
    taxonomyTerms {
      title
      id
      taxonomy {
        name
        slug
      }
    }
    employee {
      startDate
      manager {
        firstName
        lastName
        email
        fullName
      }
    }
  }
`
