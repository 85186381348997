import React, { useState, useCallback } from 'react'
import { View, Alert, Platform } from 'react-native'
import Modal from 'react-native-modal'
import { Button } from 'react-native-elements'

import { format, toDate } from 'date-fns-tz'
import CalendarPicker from 'react-native-calendar-picker'
import EStyleSheet from 'react-native-extended-stylesheet'

import COLORS from 'constants/colors'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import BorderedButton from 'components/common/buttons/BorderedButton'
import { DEVICE_WIDTH } from 'utils/phone'
import { stylesGlobal } from 'constants/es-style'
import { useAppContext } from 'utils/app-context'
import { isToday } from 'utils/date'
import MESSAGES from 'constants/messages'
import { useNetworkStatus } from 'react-offix-hooks'
import { CalendarIcon } from 'components/common/custom-icons'

type PickerProps = {
  selectedDay: string
  setSelectedDay: (date: string) => void
}

const Picker = ({ selectedDay, setSelectedDay }: PickerProps) => {
  const [selectedDayPicker, setDatePicker] = useState<Date>(toDate(selectedDay))
  const online = useNetworkStatus()
  const setDate = useCallback(
    (newDate: Date) => {
      if (newDate) {
        const isoDay = newDate.toISOString().substring(0, 10)
        // jump back to today if offline
        if (!online && !isToday(toDate(isoDay))) {
          Alert.alert('Warning', MESSAGES.DATE_TODAY_OFFLINE)
          const today = new Date()
          const isoToday = format(today, 'yyyy-MM-dd')
          setDatePicker(today)
          setSelectedDay(isoToday)
        } else {
          setDatePicker(newDate)
          setSelectedDay(isoDay)
        }
      }
    },
    [online],
  )

  return (
    <CalendarPicker
      startFromMonday
      showDayStragglers
      width={Platform.OS === 'web' ? 330 : DEVICE_WIDTH - 60}
      selectedStartDate={selectedDayPicker}
      selectedEndDate={selectedDayPicker}
      todayBackgroundColor={COLORS.GRAYDEFAULT}
      selectedDayColor={COLORS.TURQUOISE}
      selectedDayTextColor={COLORS.BLACK}
      onDateChange={setDate}
    />
  )
}

const LocationDatePicker = () => {
  const [showPicker, setShowPicker] = useState<boolean>(false)

  const { selectedDay, setAppContextValue } = useAppContext()

  const setSelectedDay = (newSelectedDay: string) => {
    setAppContextValue((oldAppContextValue: AppContextValue) => ({
      ...oldAppContextValue,
      selectedDay: newSelectedDay,
    }))
  }

  const toggleShowPicker = () => {
    setShowPicker((showPickerOld: boolean) => !showPickerOld)
  }

  const dateString = format(toDate(selectedDay), 'MM/dd')

  return (
    <View>
      <Button
        onPress={toggleShowPicker}
        buttonStyle={styles.calendarButtonStyle}
        titleStyle={styles.calendarButtonTitleStyle}
        icon={
          <CalendarIcon style={{ marginRight: 4 }} width={24} height={24} />
        }
        title={dateString}
      />
      <Modal isVisible={showPicker} style={stylesGlobal.modal}>
        <View style={stylesGlobal.modalContent}>
          <Icon
            name={ICON_NAMES.CLOSE}
            size={20}
            style={stylesGlobal.modalCloseIcon}
            onPress={toggleShowPicker}
          />
          <Picker {...{ selectedDay, setSelectedDay }} />
          <View style={styles.togglePickerWrapper}>
            <BorderedButton title="apply" onPress={toggleShowPicker} />
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  togglePickerWrapper: {
    marginTop: 15,
  },
  calendarButtonStyle: { backgroundColor: COLORS.TRANSPARENT },
  calendarButtonTitleStyle: {
    color: COLORS.BLACK,
    fontWeight: '600',
    letterSpacing: 1,
  },
})

export default LocationDatePicker
