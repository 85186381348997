import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const AccessTime = (props: SvgProps) => (
  <Svg viewBox="0 0 18 18" {...props}>
    <Path
      d="M8.992 1.5C4.853 1.5 1.5 4.86 1.5 9s3.353 7.5 7.492 7.5c4.148 0 7.508-3.36 7.508-7.5s-3.36-7.5-7.508-7.5zM9 15c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"
      fill="#7C8DA3"
    />
    <Path
      d="M9.375 5.25H8.25v4.5l3.938 2.363.562-.923-3.375-2.002V5.25Z"
      fill="#7C8DA3"
    />
  </Svg>
)

export default AccessTime
