import React, { ReactElement } from 'react'
import ICONS, { ICON_NAMES } from 'constants/icons'

// Props:
const Icon = (props: IconPropsType): ReactElement => {
  const {
    name: nameInConfiguration = ICON_NAMES.DEFAULT,
    mirror,
    style,
    ...rest
  } = props
  const { lib, name: nameInLibrary } =
    ICONS[nameInConfiguration] || ICONS[ICON_NAMES.DEFAULT]
  return React.createElement(lib, {
    name: nameInLibrary,
    style: [
      {
        ...(mirror ? { transform: [{ rotateY: '180deg' }] } : {}),
      },
      style,
    ],
    ...rest,
  })
}

export default Icon
