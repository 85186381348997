import React, { Attributes } from 'react'
import {
  View,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableNativeFeedbackProps,
  TouchableHighlightProps,
} from 'react-native'

import { IS_ANDROID } from 'constants/static'

type FeedbackPropsType = TouchableNativeFeedbackProps &
  TouchableHighlightProps & { children: ChildrenType }

const Feedback = (props: FeedbackPropsType) => {
  const component = IS_ANDROID ? TouchableNativeFeedback : TouchableOpacity

  const children = props?.children || []

  return React.createElement(component, props, children)
}

export default Feedback
