import React, {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { Camera as ExpoCamera } from 'expo-camera'
import EStyleSheet from 'react-native-extended-stylesheet'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { STYLE_CONSTANTS } from 'constants/es-style'
import COLORS from 'constants/colors'
import { ICON_NAMES } from 'constants/icons'
import Icon from './Icon'
import SpinningView from './SpinningView'
import { captureException } from 'utils/sentry'

// handler to take a picture
const takePicture = async (
  setLoading: DispatchType<boolean>,
  doWithImage: CapturedPictureHandlerType,
  cameraRef: MutableRefObject<ExpoCamera>,
) => {
  setLoading(true)
  try {
    const img = await cameraRef.current.takePictureAsync()
    if (doWithImage) {
      setLoading(false)
      doWithImage(img)
    } else {
      setLoading(false)
    }
  } catch (error) {
    captureException(error)
    setLoading(false)
    alert(error)
  }
}

const getIconStyle = (loading: boolean) => [
  { color: loading ? '#aaa' : '#fff' },
  styles.largeIcon,
]

// camera component
export default function Camera({ doWithImage, onCancel }: CameraPropsType) {
  const [hasPermission, setHasPermission] = useState<boolean>(false)
  const [type, setType] = useState<string>(ExpoCamera.Constants.Type.back)
  const [cameraFlash, setCameraFlash] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const cameraRef = useRef<ExpoCamera>(null)

  // take picture
  const onPressTakePicture = useCallback(
    () => takePicture(setLoading, doWithImage, cameraRef),
    [cameraRef],
  )

  const flipCamera = () => {
    setType(
      type === ExpoCamera.Constants.Type.back
        ? ExpoCamera.Constants.Type.front
        : ExpoCamera.Constants.Type.back,
    )
  }

  const toggleFlash = () => {
    setCameraFlash((currentValue) => !currentValue)
  }

  useEffect(() => {
    ;(async () => {
      const { status } = await ExpoCamera.requestPermissionsAsync()
      setHasPermission(status === 'granted')
    })()
  }, [])

  if (hasPermission === null) {
    return <Text>No access to camera</Text>
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  return (
    <TouchableOpacity
      style={{ ...STYLE_CONSTANTS.FILL }}
      onPress={onPressTakePicture}
    >
      <ExpoCamera
        style={{ ...STYLE_CONSTANTS.FILL }}
        type={type}
        flashMode={
          cameraFlash
            ? ExpoCamera.Constants.FlashMode.on
            : ExpoCamera.Constants.FlashMode.off
        }
        ref={cameraRef}
      >
        <View style={styles.cameraContainer}>
          {!loading && (
            <>
              <TouchableOpacity
                style={styles.camera}
                onPress={onPressTakePicture}
              >
                <Icon name={ICON_NAMES.CAMERA} style={getIconStyle(loading)} />
              </TouchableOpacity>
              {Boolean(onCancel) && (
                <TouchableOpacity
                  style={styles.cancelIconWrapper}
                  onPress={onCancel}
                >
                  <Icon name={ICON_NAMES.CLOSE} style={getIconStyle(loading)} />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={styles.flashIconWrapper}
                onPress={toggleFlash}
              >
                <Icon
                  name={cameraFlash ? ICON_NAMES.FLASH : ICON_NAMES.FLASH_OFF}
                  style={getIconStyle(loading)}
                />
              </TouchableOpacity>
              <TouchableOpacity style={styles.flipButton} onPress={flipCamera}>
                <MaterialCommunityIcons
                  name="rotate-3d-variant"
                  size={40}
                  color="white"
                />
              </TouchableOpacity>
            </>
          )}

          {loading && (
            <SpinningView style={styles.spinnerWrapper} reverse>
              <Icon name={ICON_NAMES.SPINNER} style={styles.spinner} />
            </SpinningView>
          )}
        </View>
      </ExpoCamera>
    </TouchableOpacity>
  )
}

// styles
const styles = EStyleSheet.create({
  cameraContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  spinnerWrapper: {
    alignSelf: 'center',
  },
  spinner: {
    color: COLORS.WHITE,
    fontSize: 80,
  },
  camera: {
    alignSelf: 'flex-end',
    marginBottom: 20,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  largeIcon: {
    fontSize: 40,
  },
  cancelIconWrapper: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  flashIconWrapper: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  flipButton: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: 80,
    bottom: 0,
    right: 0,
  },
})
