import React, { useMemo } from 'react'
import EStyleSheet from 'react-native-extended-stylesheet'
import COLORS from 'constants/colors'
import Icon from 'components/common/Icon'
import { Button } from 'react-native-elements'
import { GestureResponderEvent } from 'react-native'

const ButtonWithIcon = ({
  icon,
  title,
  color,
  size = 30,
  padding,
  ...rest
}: {
  icon: string
  color?: string
  title?: string
  size?: number
  padding?: boolean
  onPress: (k: GestureResponderEvent) => void
}) => {
  const stylesDynamic = useMemo(
    () =>
      EStyleSheet.create({
        button: {
          ...(color ? { backgroundColor: color } : {}),
        },
      }),
    [color],
  )
  return (
    <Button
      buttonStyle={[
        styles.button,
        stylesDynamic.button,
        ...(padding ? [styles.withPadding] : []),
      ]}
      titleStyle={styles.buttonTitle}
      icon={<Icon name={icon} {...{ size }} color={COLORS.WHITE} />}
      {...{ title }}
      {...rest}
      iconRight
    />
  )
}

const styles = EStyleSheet.create({
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: COLORS.GREEN,
  },
  buttonTitle: {
    marginRight: 10,
    fontSize: '$fontMediumSmall',
    alignItems: 'center',
    justifyContent: 'center',
  },
  withPadding: {
    paddingTop: 5,
    paddingBottom: 5,
  },
})

export default ButtonWithIcon
