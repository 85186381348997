import Icon from 'components/common/Icon'
import ImageWithZoom from 'components/common/ImageWithZoom'
import COLORS from 'constants/colors'
import { stylesGlobal, STYLE_CONSTANTS } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import { IS_WEB } from 'constants/static'
import { CACHE_KEYS, EVENT_TYPES } from 'constants/types'
import { deleteEventBackend } from 'graphql1/graphql-helpers'
import { LOCATION_PHOTOS_QUERY } from 'graphql1/queries'
import React from 'react'
import {
  Alert,
  Dimensions,
  ImageURISource,
  Platform,
  Image,
  TouchableOpacity,
  View,
} from 'react-native'
import { Button } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { ScrollView } from 'react-native-gesture-handler'
import Modal from 'react-native-modal'
import { useApolloOfflineClient } from 'react-offix-hooks'
import { percentScreenHeight } from 'utils/animations'
import { getDeleteFromCacheFunction } from 'utils/apollo-cache'

const IMAGE_WIDTH = Dimensions.get('window').width - 120

type TaskListModalProps = {
  isOpen: boolean
  setModalOpen: DispatchType<boolean>
  image: ImageURISource
  eventId?: string | number
  locationHierarchyId: string
  date: string
}

const styles = EStyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginTop: 32,
    backgroundColor: '#f4f4f4',
    maxHeight: '100%',
    ...(Platform.OS === 'web' ? { maxWidth: 600 } : {}),
    width: '100%',
  },
  imageStyle: {
    width: '80%',
  },
  fileContainer: {
    width: '100%',
    height: percentScreenHeight(60),
  },
  descriptionContainer: {
    marginTop: -25,
    marginBottom: -10,
  },
  descriptionStyle: {
    marginTop: 15,
  },
  scrollContainer: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 50,
    height: 50,
  },
  deletePhotoButton: {
    marginTop: 20,
    backgroundColor: COLORS.TURQUOISE,
  },
})

export const LocationPhotoModal = ({
  isOpen,
  setModalOpen,
  image,
  eventId,
  date,
  locationHierarchyId,
}: TaskListModalProps) => {
  const closeModal = () => setModalOpen(false)
  const client = useApolloOfflineClient()
  const deletePhoto = async () => {
    const keepListComparer = ({ id }: { id: string }) => id !== eventId
    const deleteFromCache = getDeleteFromCacheFunction(
      keepListComparer,
      CACHE_KEYS.LOCATION_PHOTOS,
      {
        query: LOCATION_PHOTOS_QUERY,
        variables: {
          locationHierarchyId,
          date,
        },
      },
    )

    deleteEventBackend({
      client,
      id: eventId.toString(),
      variables: { eventType: EVENT_TYPES.LOCATION_PHOTO, imageUrl: '' },
      cacheUpdate: deleteFromCache,
    })
    closeModal()
  }

  const onDelete = () => {
    Alert.alert(
      'Delete Photo',
      'Are you sure you want to delete this photo?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        { text: 'Yes', onPress: () => deletePhoto() },
      ],
      { cancelable: false },
    )
  }

  return (
    <Modal isVisible={isOpen} style={stylesGlobal.modal}>
      <View style={[stylesGlobal.modalContent, styles.container]}>
        <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
          <Icon
            name={ICON_NAMES.CLOSE}
            size={30}
            style={stylesGlobal.modalCloseIcon}
            onPress={closeModal}
          />
        </TouchableOpacity>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <ImageWithZoom image={image} maxWidth={IMAGE_WIDTH} />
          <View>
            <Button
              buttonStyle={styles.deletePhotoButton}
              title="Delete Photo"
              onPress={onDelete}
            />
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}
