import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const SignatureOutlined = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 30 36" {...props}>
    <Rect
      x={1}
      y={1}
      width={28}
      height={34}
      rx={3}
      stroke="#50CEA2"
      strokeWidth={2}
    />
    <Path
      d="M5 31h20M16 13l4 4M11 26l-5 1 1-5 12-12 4 4-12 12z"
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default SignatureOutlined
