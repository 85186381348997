import React from 'react'
import styled from 'styled-components'
import { TangeloIcon } from './custom-icons'
import COLORS from 'constants/colors'
import { useTranslation } from 'react-i18next'

function PoweredByTangelo({ expanded = false }) {
  const { t } = useTranslation()

  return (
    <StyledPoweredBy>
      {!expanded && <span className="text">{t('poweredBy')}</span>}
      <span className="icon">
        <TangeloIcon />
      </span>
      <span className="tangelo">Tangelo</span>
    </StyledPoweredBy>
  )
}

const StyledPoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: ${COLORS.POWDER_BLUE};

  .text {
    font-weight: 300;
    font-size: 10px;
    color: ${COLORS.SIXES_GREY};
  }

  .icon {
    vertical-align: middle;
    margin-right: 2px;
    margin-left: 4px;
  }

  .tangelo {
    font-weight: bold;
    font-size: 12px;
    color: black;
  }
  z-index: 99;
`

export default PoweredByTangelo
