import React, { useState } from 'react'
import { View, Platform, Image, ImageURISource } from 'react-native'
import { Video, VideoReadyForDisplayEvent } from 'expo-av'
import { AVPlaybackSource } from 'expo-av/build/AV'
import EStyleSheet from 'react-native-extended-stylesheet'

import ImageWithZoom from 'components/common/ImageWithZoom'
import { IS_WEB } from 'constants/static'
import { percentScreenHeight, percentScreenWidth } from 'utils/animations'

type TaskListMediaProps = {
  isVideo: boolean
  fileSource: ImageURISource
}

const TaskListModalMedia = ({ isVideo, fileSource }: TaskListMediaProps) => {
  const [videoStyle, setVideoStyle] = useState<{
    width: number | string
    height: number | string
  }>({
    width: '100%',
    height: Platform.OS === 'web' ? 'auto' : percentScreenHeight(60),
  })

  const updateVideoStyle = (e: VideoReadyForDisplayEvent) => {
    if (Platform.OS === 'web') {
      return
    }
    const { naturalSize } = e
    const { width, height } = naturalSize

    const ratio = width / height
    const maxWidth = percentScreenWidth(70)
    const maxHeight = percentScreenHeight(60)

    let newWidth = maxWidth
    let newHeight = maxHeight

    if (newWidth / ratio <= maxHeight) {
      newHeight = newWidth / ratio
    }

    setVideoStyle({
      height: newHeight,
      width: newWidth,
    })
  }

  if (isVideo) {
    return (
      <Video
        source={fileSource as AVPlaybackSource}
        onReadyForDisplay={(e: VideoReadyForDisplayEvent) => {
          updateVideoStyle(e)
        }}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode="contain"
        useNativeControls
        style={[videoStyle, IS_WEB ? styles.webSize : {}]}
      />
    )
  } else
    return (
      <View style={[styles.imageHolder, IS_WEB ? { flex: 'unset' } : {}]}>
        <ImageWithZoom image={fileSource} />
      </View>
    )
}

const styles = EStyleSheet.create({
  imageHolder: {
    alignItems: 'center',
    paddingRight: IS_WEB ? 30 : 0,
  },
  webSize: {
    width: 102,
    height: 102,
    borderRadius: 8,
  },
  fileContainer: {
    width: '95%',
    height: IS_WEB ? 'auto' : percentScreenHeight(60),
  },
})

export default TaskListModalMedia
