import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const DocuSignIcon = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 32 32" {...props}>
    <Rect width={32} height={32} rx={2} fill="#50CEA2"/>
    <Path transform="scale(1.1)" transform-origin="-24px -24px" fill="#fff" width={1} d="M12 16c-.216 0-.422-.093-.564-.256l-5.25-6C5.992 9.522 5.945 9.208 6.067 8.94S6.456 8.5 6.75 8.5H9V3.75C9 3.336 9.336 3 9.75 3h4.5C14.664 3 15 3.336 15 3.75V8.5h2.25c.294 0 .562.172.683.44s.075.583-.119.804l-5.25 6C12.422 15.907 12.216 16 12 16zM8.403 10L12 14.111 15.597 10H14.25c-.414 0-.75-.336-.75-.75V4.5h-3v4.75c0 .414-.336.75-.75.75H8.403zM23.25 19.5H.75C.336 19.5 0 19.164 0 18.75S.336 18 .75 18h22.5c.414 0 .75.336.75.75S23.664 19.5 23.25 19.5z"/>
  </Svg>
)

export default DocuSignIcon
