import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const OptionOutlined = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 36" {...props}>
    <Rect
      x={1}
      y={1}
      width={34}
      height={34}
      rx={7}
      fill="#fff"
      stroke="#50CEA2"
      strokeWidth={2}
    />
    <Path
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeWidth={3}
      d="m12.117 15.859 5.742 5.024M18.141 20.883l5.742-5.024"
    />
  </Svg>
)

export default OptionOutlined
