import React, { SFC } from 'react'
import { Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import Centered from './Centered'

// component that fills parent and centers a text
const CenteredText = ({
  children,
  text,
}: {
  children?: ChildrenType
  text?: string
}) => <Centered>{children || <Text style={styles.text}>{text}</Text>}</Centered>

// styles
const styles = EStyleSheet.create({
  text: {
    fontSize: '$fontLarge',
  },
})

export default CenteredText
