import React, { useContext } from 'react'
import { ISODayString } from 'utils/date'

export const initialAppContextValue: AppContextValue = {
  selectedDay: ISODayString(new Date()),
  setAppContextValue: () => null as null,
}

export const AppContext = React.createContext(initialAppContextValue)

export function useAppContext() {
  return useContext(AppContext)
}
