import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { GET_CURRENT_USER } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import ScreenWrapper from 'components/common/ScreenWrapper'
import { default as MobileTaskTodayScreen } from './TaskTodayScreen'

const TaskTodayScreenWeb = () => {
  const { data: currentUserData } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  return (
    <ScreenWrapper
      currentUser={currentUserData?.user}
      menuComponent={<MobileTaskTodayScreen />}
    />
  )
}

export default TaskTodayScreenWeb
