import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'

import { useLocation } from 'react-router-native'
import { CLIENT_CONFIGURATION, GET_CLIENT_CONFIG } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import { useHeaderContext } from 'utils/header-context'
import { ROUTES_CONFIG } from 'navigation/constants'
import OptionsMenu from './OptionsMenu'
import LanguageMenu from './LanguageMenu'

const Header = ({ currentUser }: { currentUser: User }) => {
  const { options } = useHeaderContext()
  const location = useLocation()
  const { data: clientConfiguration } = useQuery(CLIENT_CONFIGURATION, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })
  const { data } = useQuery(GET_CLIENT_CONFIG, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  const showLocale = data?.clientConfig?.showLocale
  const isHome = !!options.title && options.title[0] === 'Home'

  useEffect(() => {
    const title =
      options.title || ROUTES_CONFIG[location.pathname]?.headerTitle || ''

    document.title = title
  }, [options.title, location.pathname])

  return (
    <StyledHeader>
      <div className="header-content">
        <div className="left-part-wrapper">
          {!!clientConfiguration &&
            !!clientConfiguration?.configuration[0]?.clientLogo && (
              <div className="header-logo">
                <img src={clientConfiguration.configuration[0].clientLogo} />
              </div>
            )}
        </div>
        <div className="right-part-wrapper">
          {options.headerRight && options.headerRight()}
          {isHome && showLocale && (
            <div className="language-menu">
              <LanguageMenu />
            </div>
          )}
          <div className="user-name">
            {`${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`}
          </div>
          <OptionsMenu />
        </div>
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  position: relative;
  z-index: 1;
  height: 48px;
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.06));
  background-color: white;
  padding-left: 24px;
  padding-right: 24px;
  .header-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }
  .left-part-wrapper {
    display: flex;
    align-items: center;
    .header-logo {
      height: 36px;
      box-sizing: border-box;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .right-part-wrapper {
    display: flex;
    align-items: center;
    .report-button {
      cursor: pointer;
      background-color: #50cea2;
      border-radius: 6px;
      padding: 8px 12px;
      color: #ffffff;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      text-decoration: none;
      svg {
        margin-right: 8px;
      }
    }
    .user-name {
      font-weight: 400;
      font-size: 12px;
      margin-right: 15px;
    }
    .language-menu {
      font-weight: 400;
      font-size: 12px;
      margin-right: 15px;
    }
  }
`

export default Header
