import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Hero1: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content, pictureUrl } = section
  if (content) {
    const { overline, text } = content
    return (
      <Container>
        <Content>
          <Overline>{overline}</Overline>
          <Text>{text}</Text>
        </Content>
        <Picture>
          {pictureUrl ? (
            <img src={pictureUrl} alt="" style={{ maxWidth: '390px' }} />
          ) : null}
        </Picture>
      </Container>
    )
  }
  return <Box>Hero1</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  padding: 36px;
  gap: 36px;
  width: 100%;
  box-sizing: border-box;
`

const Content = styled.div``

const Overline = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 20px;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #000000;
  word-wrap: break-word;
`

const Picture = styled.div`
  width: 50%;
`
