import { AnyObject } from 'react-native-extended-stylesheet'
import {
  digestStringAsync,
  CryptoDigestAlgorithm,
  CryptoEncoding,
} from 'expo-crypto'
import set from 'lodash/set'
import deepmerge from 'deepmerge'

export function uuid4() {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    // tslint:disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export const compareNumbers = (a: number | string, b: number | string) =>
  (typeof a === 'string' ? Number.parseInt(a, 10) : a) ===
  (typeof b === 'string' ? Number.parseInt(b, 10) : b)

const isObject = (value: any) =>
  Object.prototype.toString.call(value) === Object.prototype.toString.call({})

const isEmptyObject = (value: any) =>
  isObject(value) && Object.keys(value).length === 0

const isEmptyValue = (value: any) => value === null || value === ''

const mustBeDeletedDeepClean = (value: any) =>
  isEmptyValue(value) || isEmptyObject(value)

export const cleanDeep = (data: AnyObject) => {
  if (!isObject(data)) return data
  const clean = (copy: any) =>
    Object.keys(copy).reduce((acc: any, key: string) => {
      let value = copy[key]
      if (isObject(value) && !isEmptyObject(value)) value = clean(value)
      if (!mustBeDeletedDeepClean(value)) acc[key] = value
      return acc
    }, {})
  return clean(data)
}

const isSpreadable = (o: any) =>
  o?.constructor?.name === 'Object' || Array.isArray(o)

const updateViaValue = (
  value: any,
  oldValue: any,
  merge?: boolean,
  mergeOptions = {},
) =>
  isSpreadable(oldValue) && merge
    ? deepmerge(oldValue, value, mergeOptions)
    : value

const updateViaFunction = (
  value: (t?: any) => any,
  oldValue: any,
  merge?: boolean,
) =>
  isSpreadable(oldValue) && merge
    ? deepmerge(oldValue, value(oldValue))
    : value(oldValue)

export const doDynamicUpdate = (
  oldValue: any,
  value: any,
  merge?: boolean,
  mergeOptions = {},
) =>
  typeof value === 'function'
    ? updateViaFunction(value, oldValue, merge)
    : updateViaValue(value, oldValue, merge, mergeOptions)

export const capitalize = (name: string) =>
  name.charAt(0).toUpperCase() + name.slice(1)

export const mapObject = (
  obj: AnyObject,
  valSetter?: ObjectMapValueSetter,
  keySetter?: ObjectMapKeySetter,
) =>
  Object.fromEntries(
    Object.entries(obj || {}).map(([k, v]) => [
      keySetter ? keySetter(k, v, obj) : k,
      valSetter ? valSetter(v, k, obj) : v,
    ]),
  )

// tslint:disable-next-line no-empty
export function emptyFunction() {}

export function filterObject(
  o: AnyObjectType,
  filterFunction: ((objectKey: string, objectValue: any) => boolean) | string[],
  exlude: boolean = false,
) {
  return Object.keys(o).reduce((objRaw, key) => {
    const obj = objRaw
    const val = o[key]

    if (
      (typeof filterFunction === 'function' && filterFunction(key, val)) ||
      (Array.isArray(filterFunction) &&
        (exlude ? !filterFunction.includes(key) : filterFunction.includes(key)))
    ) {
      set(obj, key, val)
    }
    return obj
  }, {})
}

// Generate random string
export function randomString(numOfCharacters: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < numOfCharacters; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

// base64UrlEncode
export function base64URLEncode(str: string) {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export async function generatePKCE() {
  const codeVerifier = base64URLEncode(randomString(44))
  const cryptoChallenge = await digestStringAsync(
    CryptoDigestAlgorithm.SHA256,
    codeVerifier,
    { encoding: CryptoEncoding.BASE64 },
  )

  const codeChallenge = cryptoChallenge
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')

  return {
    codeVerifier,
    codeChallenge,
  }
}
