import enUS from 'i18n/en-US.json'
import frCA from 'i18n/fr-CA.json'
import fr from 'i18n/fr.json'
import ptBR from 'i18n/pt-BR.json'
import deDE from 'i18n/de-DE.json'
import jaJP from 'i18n/ja-JP.json'
import esES from 'i18n/es-ES.json'
import zhCN from 'i18n/zh-CN.json'
import zhTW from 'i18n/zh-TW.json'

const translations = {
  'en-US': enUS,
  fr: fr,
  'fr-CA': frCA,
  'es-ES': esES,
  'pt-BR': ptBR,
  'de-DE': deDE,
  'ja-JP': jaJP,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
}

export default translations
