import { CacheOperation } from 'offix-client'

import { CACHE_KEYS, MODEL_TYPES, EVENT_TYPES } from 'constants/types'
import {
  getDeleteFromCacheFunction,
  getAddOrUpdateCacheFunction,
} from 'utils/apollo-cache'
import { createEventBackend, callMutation } from 'graphql1/graphql-helpers'
import { compareNumbers, uuid4 } from 'utils/calculations'
import { ROUTE_NAMES } from 'navigation/constants'
import { CREATE_SESSION } from 'graphql1/mutations'
import { ROOT_FOLDER_NAME } from 'constants/static'
import { getUpdateQuerySessions } from 'utils/apollo'

export const startASession = async (
  client: ClientType,
  locationHierarchyId: string,
  selectedDay: string,
  locationTitle: string,
  appContextValue: AppContextValue,
  notes: string,
  history: any,
) => {
  const sessionUuid = uuid4()

  history.push({
    pathname: ROUTE_NAMES.TASKS_TODAY,
    search: `?locationHierarchyId=${locationHierarchyId}`,
    state: {
      locationHierarchyId,
      locationTitle,
      sessionUuid,
      notes,
      loadData: true,
    },
  })

  // new session data
  const newSession = {
    __typename: MODEL_TYPES.SESSION,
    day: selectedDay,
    uuid: sessionUuid,
    locationHierarchyId,
  }

  // call query after update
  const updateQuery = await getUpdateQuerySessions(appContextValue)

  // cache update before mutation
  const cacheUpdate = getAddOrUpdateCacheFunction(
    newSession,
    CACHE_KEYS.SESSIONS,
    updateQuery,
  )

  // call backend mutation
  callMutation({
    client,
    mutation: CREATE_SESSION,
    operationType: CacheOperation.ADD,
    returnType: MODEL_TYPES.SESSION,
    updateQuery,
    variables: newSession,
    cacheUpdate,
  })
}

export const endASession = async (
  client: ClientType,
  sessionUuid: string,
  appContextValue: AppContextValue,
) => {
  const eventType = EVENT_TYPES.END_SESSION

  const data = {
    eventType,
    sessionUuid,
  }

  const keepListComparer = ({ uuid }: { uuid: string }) => uuid !== sessionUuid

  // update query for sessions
  const updateQuery = await getUpdateQuerySessions(appContextValue)
  // cache update function
  const cacheUpdate = getDeleteFromCacheFunction(
    keepListComparer,
    CACHE_KEYS.SESSIONS,
    updateQuery,
  )

  // update backend
  return createEventBackend({
    client,
    variables: data,
    cacheUpdate,
    updateQuery,
  })
}

export const getLocationsToRender =
  (
    locations: LocationInterface[],
    parentId: string,
    groupedSessions: { [k: string]: SessionInterface[] },
    day: string,
  ) =>
  () => {
    let locationIdsToRender = [] as Array<string>

    if (!parentId) {
      locationIdsToRender = locations
        .filter((l) => l.title === '__root__')
        .map((l) => l.children.map((cl) => cl.id))
        .flat()
    } else {
      // ids to render
      const foundParent = locations.find(({ id }: { id: string }) =>
        compareNumbers(id, parentId),
      )
      if (!foundParent) {
        return []
      }

      locationIdsToRender = foundParent?.children?.map(
        ({ id }: { id: string }) => id,
      )
    }

    const childLocations = locations.filter(({ id }: { id: string }) =>
      locationIdsToRender.includes(id),
    )

    return childLocations.map((l: LocationInterface) => {
      const foundOpenSession = groupedSessions?.[l.id]?.find(
        ({ day: daySession }) => daySession === day,
      )
      return {
        ...l,
        sessionUuid: foundOpenSession?.uuid,
      }
    })
  }

export const getTitle = (locations: LocationInterface[], parentId: string) => {
  const parentTitle = locations.find(({ id }: { id: string }) =>
    compareNumbers(id, parentId),
  )?.title
  const title =
    !parentTitle || parentTitle === ROOT_FOLDER_NAME ? 'Home' : parentTitle
  return title
}

export const getLocationNotes = (
  locations: LocationInterface[],
  parentId: string,
) => {
  const parentNotes = locations.find(({ id }: { id: string }) =>
    compareNumbers(id, parentId),
  )?.notes
  return parentNotes
}

export const getLevelTree = (
  locations: LocationInterface[],
  parentId: string,
  results = [getTitle(locations, parentId)],
) => {
  const result = locations.find(
    (location) =>
      location.children &&
      location.children.some((child) => child.id == parentId),
  )
  if (result && result.title != '__root__') {
    results.push(result.title)
    return getLevelTree(locations, result.id, results)
  } else {
    return results.reverse()
  }
}
