import React from 'react'
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
} from 'react-native'
import Modal from 'react-native-modal'

import Icon from 'components/common/Icon'

import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'
import { CheckBox } from 'react-native-elements/dist/checkbox/CheckBox'

type MultipleOptionsModal = {
  openModal: boolean
  onClose: () => void
  title: string
  options: Array<MultipleOption> | null
  onOptionPress: (option: string) => void
  selectedOptionValue: string
}

const MultipleOptionsModal = ({
  openModal,
  onClose,
  title,
  options,
  onOptionPress,
  selectedOptionValue,
}: MultipleOptionsModal) => {
  return (
    <Modal isVisible={openModal} style={stylesGlobal.modal}>
      <View style={[stylesGlobal.modalContent, styles.container]}>
        <View style={styles.headerContainer}>
          <Text
            style={stylesGlobal.modalContentHeaderText}
            numberOfLines={3}
            ellipsizeMode="tail"
          >
            {title}
          </Text>
          <View style={styles.closeButtonContainer}>
            <TouchableOpacity onPress={() => onClose()}>
              <Icon name={ICON_NAMES.CLOSE} size={28} />
            </TouchableOpacity>
          </View>
        </View>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          {!!options &&
            options.map((item) => {
              const isChecked = selectedOptionValue === item.option
              return (
                <CheckBox
                  key={item.id}
                  onPress={(_e) => onOptionPress(item.option)}
                  title={item.option}
                  checked={isChecked}
                  checkedColor={COLORS.TURQUOISE}
                  uncheckedColor={COLORS.TURQUOISE}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  containerStyle={{
                    borderColor: COLORS.TURQUOISE,
                    borderWidth: isChecked ? 2 : 1,
                    borderRadius: 8,
                    backgroundColor: isChecked
                      ? COLORS.LIGHT_TURQUOISE
                      : 'white',
                  }}
                  textStyle={{
                    fontFamily: 'Poppins_400Regular',
                    color: isChecked ? COLORS.DARK_TURQUOISE : COLORS.TURQUOISE,
                    fontSize: 18,
                    fontWeight: isChecked ? '600' : '500',
                  }}
                />
              )
            })}
        </ScrollView>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 46,
    backgroundColor: 'white',
    maxHeight: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    alignContent: 'space-between',
    paddingLeft: 8,
    width: '100%',
  },
  titleText: {
    paddingTop: 12,
    flex: 1,
    flexWrap: 'wrap',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 20,
  },
  scrollContainer: {
    marginTop: 15,
    flexGrow: 1,
  },
  closeButtonContainer: {
    flex: 1,
    alignItems: 'end',
  },
})

export default MultipleOptionsModal
