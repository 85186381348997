import { ReactNativeFile } from 'apollo-upload-client'
import * as ImageManipulator from 'expo-image-manipulator'
import { CameraCapturedPicture } from 'expo-camera'
import { FileInfo } from 'expo-file-system'
import { moveFile } from 'utils/phone'

// resizes image and overwrites original file
export const resizeAndOverwriteImage = async (image: CameraCapturedPicture) => {
  const { width, height, uri: imageUri } = image

  // keeps image dimensions
  const largerDim = width > height ? width : height
  const newDim = 1200 // fits sqare of 800x800
  const factor = newDim / largerDim
  const newHeight = factor * height
  const newWidth = factor * width

  // compress and resize image
  const manipulationResult = await ImageManipulator.manipulateAsync(
    imageUri,
    [{ resize: { width: newWidth, height: newHeight } }],
    { compress: 0.8, format: ImageManipulator.SaveFormat.PNG },
  )

  await moveFile(manipulationResult.uri, imageUri)
}

export const createImageFile = async (
  image: CameraCapturedPicture | FileInfo | ImagePickerInfo,
) => {
  const imageUri = image?.uri
  // resize image

  // ReactNativeFile
  const uriParts = imageUri.split('.')
  const fileType = uriParts[uriParts.length - 1]

  // create form data
  return new ReactNativeFile({
    // @ts-ignore
    uri: imageUri,
    name: `photo_${+new Date()}.${fileType}`,
    type: `image/${fileType}`,
  })
}
