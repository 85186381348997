export const clearErrorData = (client: ClientType) => {
  client.writeData({
    data: {
      errorData: {
        __typename: 'error',
        hasError: false,
        status: '',
        message: '',
      },
    },
  })
}

export const writeErrorData = (
  client: ClientType,
  { message = '', status = '' }: ErrorWriteDataType,
) => {
  client.writeData({
    data: {
      errorData: {
        __typename: 'error',
        hasError: true,
        status,
        message,
      },
    },
  })
}
