import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Header2: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading, text } = content
    return (
      <Container>
        <Heading>{heading}</Heading>
        <Text>{text}</Text>
      </Container>
    )
  }
  return <Box>Header2</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  padding: 36px 20px 20px;
  border-bottom: 1px solid #333333;
  width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #333333;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_300Light';
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #555555;
  word-wrap: break-word;
`
