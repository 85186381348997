import React from 'react'
import EStyleSheet from 'react-native-extended-stylesheet'
import { View } from 'react-native'

const Filler = () => <View style={styles.Filler} />

const styles = EStyleSheet.create({
  Filler: {
    flex: 1,
  },
})

export default Filler
