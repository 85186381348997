import NetInfo from '@react-native-community/netinfo'
import get from 'lodash/get'
import {
  UPLOAD_END_POINT,
  END_POINT,
  ALIVE_ENDPOINT,
  ALIVE_RESPONSE_EXPECTED,
  ALIVE_CHECK_TIMEOUT,
  NOTIFY_SERVER_DOWN_EVERY_HOUR,
} from 'constants/api'
import { deleteFile, readAsyncCache, writeAsyncCache } from 'utils/phone'
import { CACHE_KEYS } from 'constants/types'
import { captureException } from 'utils/sentry'

export const checkIsConnected = async (): Promise<boolean> => {
  const { isConnected }: { isConnected: boolean } = await NetInfo.fetch()
  return isConnected
}

// upload an image related to event
export const uploadImage = async (
  uri: string,
  eventId: string,
  deleteAfterUpload: boolean = true,
) => {
  // find file type
  const uriParts = uri.split('.')
  const fileType = uriParts[uriParts.length - 1]

  // create form data
  const formData = new FormData()
  formData.append('image', {
    // @ts-ignore
    uri,
    name: `photo.${fileType}`,
    type: `image/${fileType}`,
  })
  formData.append('event_id', eventId)

  // define options for upload
  const options = {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  }

  // upload and get response
  const response: string = await (await fetch(UPLOAD_END_POINT, options)).text()
  const { success }: { success: string } = JSON.parse(response) || {}

  // delete image after successful upload by default
  if (success && deleteAfterUpload) {
    deleteFile(uri)
  }
}

export const prependEndpoint = (path: string) => `${END_POINT}${path}`

// calls and wraps a promise in error handling blocks
export const wrapInTry = (
  promiseCall: GeneralHandlerType,
  onCatchError?: MutationCallbackType,
) => (...params: any) => {
  try {
    // @ts-ignore
    return promiseCall(...params)?.catch((error) => {
      // tslint:disable-next-line no-console
      console.log(error)
      if (onCatchError?.onError) {
        onCatchError?.onError(error)
      }
      // do after queue element has been executed
      if (error?.offline && onCatchError?.onFulfilled) {
        // @ts-ignore
        error.watchOfflineChange().then((...paramsComplete) => {
          // @ts-ignore
          onCatchError?.onFulfilled(...paramsComplete)
        })
      }
    })
  } catch (error) {
    if (onCatchError?.onError) {
      onCatchError?.onError(error)
    }
    // tslint:disable-next-line no-console
    console.log(error)
  }
}

const onNotAlive = async (online: boolean, resolve: any) => {
  if (online) {
    const notified = await readAsyncCache(CACHE_KEYS.NOTIFIED_SERVER_DOWN)

    const dateNow = +new Date()
    const dateNotified = get(notified || {}, 'time') || dateNow
    const hours = Math.abs(dateNow - dateNotified) / 36e5

    if (!notified || hours > NOTIFY_SERVER_DOWN_EVERY_HOUR) {
      writeAsyncCache(CACHE_KEYS.NOTIFIED_SERVER_DOWN, {
        time: +new Date(),
      })
    }
  }
  resolve(false)
}

export const checkServerAlive = (online: boolean) =>
  new Promise(async (resolve: any) => {
    const timeout = setTimeout(() => {
      onNotAlive(online, resolve)
    }, ALIVE_CHECK_TIMEOUT)

    try {
      const response = await fetch(ALIVE_ENDPOINT, {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const { data: expectedId } = await response.json()
      const serverAlive = ALIVE_RESPONSE_EXPECTED === expectedId
      clearTimeout(timeout)
      resolve(serverAlive)
    } catch (error) {
      captureException(error)
      clearTimeout(timeout)
      onNotAlive(online, resolve)
    }
  })
