import { Platform } from 'react-native'
import { Native, Browser } from 'sentry-expo'

const isWeb = Platform.OS === 'web'

export const captureException = isWeb
  ? Browser.captureException
  : Native.captureException

export default isWeb ? Browser : Native
