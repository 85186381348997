import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const ArrowRightIcon = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 22 18" {...props}>
    <Path
      d="M0 9h20"
      stroke={props.stroke || '#50CEA2'}
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <Path
      d="m13 16 7-7-7-7"
      stroke={props.stroke || '#50CEA2'}
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
  </Svg>
)

export default ArrowRightIcon
