import { useEffect } from 'react'
import {
  makeRedirectUri,
  useAuthRequest,
  DiscoveryDocument,
} from 'expo-auth-session'
import { Platform } from 'react-native'
import { useMutation } from '@apollo/react-hooks'
import { useHeaderContext } from 'utils/header-context'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useHistory } from 'react-router-native'
import { getOktaToken } from 'utils/requests'
import { LOGIN_WITH_OKTA_TOKEN } from 'graphql1/mutations'
import { CACHE_KEYS } from 'constants/types'

const useProxy = Platform.select({ web: false, default: true })

interface OktaLoginHookProps {
  clientId: string
  email: string
  oktaDomain: string
  setLoginError: DispatchType<string>
  setTriggerOktaLogin: DispatchType<boolean>
  discovery: DiscoveryDocument
  triggerOktaLogin: boolean
}

const useOktaLogin = ({
  clientId,
  email,
  setLoginError,
  discovery,
  oktaDomain,
  triggerOktaLogin,
  setTriggerOktaLogin,
}: OktaLoginHookProps) => {
  const history = useHistory()
  const { saveCrossDomainToken } = useHeaderContext()
  const [authRequest, authRequestResponse, promptAsync] = useAuthRequest(
    {
      clientId,
      scopes: ['openid', 'profile', 'email'],
      redirectUri: makeRedirectUri({
        useProxy,
      }),
    },
    discovery,
  )

  const [oktaTokenLogin] = useMutation(LOGIN_WITH_OKTA_TOKEN, {
    onCompleted: (data) => {
      const { authenticationToken } = data.oktaTokenLogin
      checkTokenAndNavigate(authenticationToken)
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setLoginError(err.graphQLErrors[0].message)
      }
    },
  })

  const checkTokenAndNavigate = async (token: string) => {
    if (!!token) {
      await AsyncStorage.setItem(CACHE_KEYS.TOKEN, token)
      saveCrossDomainToken(token)
      history.push('/LOCATIONS')
    } else {
      setLoginError('Invalid credentials')
    }
  }

  useEffect(() => {
    async function exchangeCodeForToken(code: string) {
      const { codeVerifier, redirectUri } = authRequest
      const tokenResponseData = await getOktaToken({
        requestUrl: discovery.tokenEndpoint,
        redirectUri,
        code,
        codeVerifier,
        clientId,
      })

      if (tokenResponseData.error) {
        setLoginError('Okta error: ' + tokenResponseData.error_description)
        return
      }
      const oktaAccessToken = tokenResponseData?.access_token
      // login user internaly via OKTA token
      oktaTokenLogin({
        variables: { attributes: { accessToken: oktaAccessToken, email } },
      })
    }

    if (authRequestResponse?.type === 'success') {
      const { code } = authRequestResponse.params
      exchangeCodeForToken(code)
    } else if (authRequestResponse) {
    }
  }, [authRequestResponse])

  useEffect(() => {
    if (
      !authRequest ||
      (oktaDomain && !authRequest.url.includes(oktaDomain)) ||
      !triggerOktaLogin
    ) {
      return
    }

    setTriggerOktaLogin(false)
    promptAsync({ useProxy })
  }, [authRequest, promptAsync, triggerOktaLogin])
}

export default useOktaLogin
