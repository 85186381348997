import { INITIAL_ROUTE_NAME, ROUTES_CONFIG } from 'navigation/constants'
import { useEffect } from 'react'
import { captureException } from 'utils/sentry'

export const getNavigationOptions = (scene: { descriptor: { options: any } }) =>
  scene?.descriptor?.options

export const getRouteName = (scene: { route: { name: any } }) =>
  scene?.route?.name

const tryCall = (call: any) => () => {
  if (call && typeof call === 'function') {
    try {
      call()
      // tslint:disable-next-line no-empty
    } catch (error) {
      captureException(error)
    }
  }
}

export function useFocusEffect(navigation: any, call: (params: any) => any) {
  useEffect(() => {
    const callToTry = tryCall(call)
    return () => {}
  }, [])
}

// on refresh callback
export const onRefreshCall = async (
  online: boolean,
  getRefreshing: GetterType<boolean>,
  setRefreshing: DispatchType<boolean>,
  refetch: any,
) => {
  if (!online) {
    return
  }
  setRefreshing(true)
  if (!refetch) {
    setRefreshing(false)
    return
  }
  // stop refresh after timeout
  setTimeout(() => {
    if (getRefreshing()) {
      setRefreshing(false)
    }
  }, 2500)
  try {
    await refetch()
    // stop refreshing after fetch
    setRefreshing(false)
  } catch (error) {
    captureException(error)
    // stop refreshing if error
    setRefreshing(false)
  }
}
