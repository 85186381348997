import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { FETCH_POLICIES } from 'constants/api'
import { GET_CLIENT_CONFIG, GET_CURRENT_USER } from 'graphql/queries'
import { ROUTE_NAMES } from 'navigation/constants'
import { Redirect, Route } from 'react-router-native'

function ManagerRoute({ component: Component, ...restOfProps }) {
  const { data: currentUserData, loading: userLoading } = useQuery<{
    user: User
  }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  const isManager = currentUserData?.user?.isManager

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (userLoading) {
          return null
        }

        if (isManager) {
          return <Component {...props} />
        }

        return <Redirect to={ROUTE_NAMES.LOGIN} />
      }}
    />
  )
}

export default ManagerRoute
