import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import UpNextCard from './UpNextCard.web'
import UpNextDone from './UpNextDone.web'
import { useTranslation } from 'react-i18next'

const UpNext = ({
  tasks = [],
  snippetsMapping,
}: {
  tasks: TaskUncompleteType[]
  snippetsMapping: StringMap
}) => {
  const { t } = useTranslation()
  
  function renderContent() {
    if (tasks.length === 0) {
      return <UpNextDone />
    }

    return tasks
      .sort(sortByMostOverdue)
      .sort(sortByMostImportant)
      .slice(0, 5)
      .map((task) => (
        <UpNextCard task={task} snippetsMapping={snippetsMapping} />
      ))
  }

  function sortByMostOverdue(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (!a?.dueDate) {
      return 1
    }
    if (!b?.dueDate) {
      return -1
    }

    return new Date(a.dueDate) - new Date(b.dueDate)
  }

  function sortByMostImportant(a: TaskUncompleteType, b: TaskUncompleteType) {
    if (a?.important) {
      return -1
    }

    if (b?.important) {
      return 1
    }

    return 0
  }

  return (
    <StyledUpNext>
      <StyledTitle>{t('upNextTitle')}</StyledTitle>
      <StyledSubtitle>{t('upNextSubtitle')}</StyledSubtitle>
      {renderContent()}
    </StyledUpNext>
  )
}
const StyledTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
`

const StyledSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.SIXES_GREY};
  margin-bottom: 8px;
`

const StyledUpNext = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  padding: 24px;
  background-color: ${COLORS.LIGHT_POWDER_BLUE};
  border-radius: 32px;
`

export default UpNext
