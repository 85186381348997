import React from 'react'

import styled from 'styled-components'

import LockIconImage from 'assets/images/svg/lock-icon.svg'

type LockIconProps = {
  locked?: boolean
  size?: number
}

const LockIcon = (props: LockIconProps) => {
  return props.locked ? <Locked src={LockIconImage} size={props.size} /> : null
}

const Locked = styled.img<{ size?: number }>`
  margin: 0 !important;
  padding-right: 5px;
  align-self: center;
  height: ${(props) => props.size || 14}px;
`

export default LockIcon
