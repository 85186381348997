import React from 'react'
import EStyleSheet from 'react-native-extended-stylesheet'
import { View } from 'react-native'

const RowView = ({
  children,
  style,
  ...rest
}: {
  children?: ChildrenType
  style?: any
  rest?: any
}) => (
  <View {...rest} style={[styles.row, ...(style ? [style] : [])]}>
    {children}
  </View>
)

const styles = EStyleSheet.create({
  row: {
    flexDirection: 'row',
  },
})

export default RowView
