import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

function ExpandArrowRight(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Circle cx={12} cy={12} r={12} fill="#F8F8F8" />
      <Path
        d="M12.274 16.006l-.943-.941 3.067-3.067L11.33 8.93l.943-.938L16.281 12l-4.006 4.006zm-3.616 0l-.943-.941 3.066-3.067-3.066-3.062.943-.943L12.665 12l-4.007 4.006z"
        fill="#6E92C5"
      />
    </Svg>
  )
}

export default ExpandArrowRight
