import React, { useEffect, useState } from 'react'

import { Menu, Provider } from 'react-native-paper'
import { View } from 'react-native'
import translations from 'i18n/languages'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_USER_LOCALE } from 'graphql1/mutations'
import { FETCH_POLICIES } from 'constants/api'
import { GET_CURRENT_USER } from 'graphql/queries'
import Flags from 'country-flag-icons/react/3x2'
import { useHistory } from 'react-router-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CACHE_KEYS } from 'constants/types'

const LanguageMenu = () => {
  const [updateUserLocale] = useMutation(UPDATE_USER_LOCALE)
  const { data } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)
  const { t, i18n } = useTranslation()
  const languages = Object.keys(i18n.services.resourceStore.data)

  useEffect(() => {
    if (!data?.user?.preferredLocale) {
      updateUserLocale({
        variables: {
          attributes: {
            preferredLocale: i18n.resolvedLanguage,
          },
        },
      })
    }
  }, [data])

  const changeLanguage = async (language: string) => {
    i18n.changeLanguage(language)

    await AsyncStorage.setItem(CACHE_KEYS.SELECTED_LANGUAGE, language)

    await updateUserLocale({
      variables: {
        attributes: {
          preferredLocale: language,
        },
      },
    })

    history.push('/')
  }

  const getFlag = (language: string) => {
    let flag

    switch (language) {
      case 'en-US':
        flag = (
          <>
            <Flags.GB
              title="Britain"
              style={{
                width: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <Flags.US
              title="United States"
              style={{
                width: '30px',
              }}
            />
          </>
        )
        break
      case 'fr-CA':
        flag = (
          <>
            <Flags.CA
              title="Canada"
              style={{
                marginLeft: '5px',
                width: '30px',
              }}
            />
          </>
        )
        break
      case 'fr':
        flag = (
          <>
            <Flags.FR
              title="France"
              style={{
                marginLeft: '5px',
                width: '30px',
              }}
            />
          </>
        )
        break
      case 'es-ES':
        flag = (
          <>
            <Flags.MX
              title="Mexico"
              style={{
                width: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <Flags.ES
              title="Spain"
              style={{
                width: '30px',
              }}
            />
          </>
        )
        break
      case 'pt-BR':
      case 'pt-PT':
        flag = (
          <>
            <Flags.BR
              title="Brazil"
              style={{
                width: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <Flags.PT
              title="Portugal"
              style={{
                width: '30px',
              }}
            />
          </>
        )
        break
      case 'de-DE':
        flag = (
          <Flags.DE
            title="Germany"
            style={{
              marginLeft: '5px',
              width: '30px',
            }}
          />
        )
        break
      case 'ja-JP':
        flag = (
          <Flags.JP
            title="Japan"
            style={{
              marginLeft: '5px',
              width: '30px',
            }}
          />
        )
        break
      case 'zh-CN':
      case 'zh-TW':
        flag = (
          <Flags.CN
            title="China"
            style={{
              marginLeft: '5px',
              width: '30px',
            }}
          />
        )
        break
    }

    return <div style={{ display: 'flex' }}>{flag}</div>
  }

  const getItems = (languages: string[]) => {
    return languages.map((language: string) => {
      return {
        language,
        flag: getFlag(language),
        title: t(language),
      }
    })
  }

  return (
    <Provider>
      <View>
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <div
              onClick={openMenu}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {t(i18n.resolvedLanguage)}
              {getFlag(i18n.resolvedLanguage)}
            </div>
          }
          style={{
            left: 800,
            top: 45,
          }}
        >
          {getItems(languages).map(({ language, flag, title }) => (
            <Menu.Item
              onPress={() => changeLanguage(language)}
              contentStyle={{
                flex: 1,
              }}
              title={
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                  }}
                >
                  {title}
                  {flag}
                </div>
              }
            />
          ))}
        </Menu>
      </View>
    </Provider>
  )
}

export default LanguageMenu
