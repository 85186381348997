import React, { Fragment, useMemo } from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import format from 'utils/date-fns'
import { isToday, isBefore, differenceInDays } from 'date-fns'
import { useHistory } from 'react-router'
import { startASession } from '../locations-helper'
import { useApolloOfflineClient } from 'react-offix-hooks'
import { useAppContext } from 'utils/app-context'
import { FETCH_POLICIES } from 'constants/api'
import { GET_CURRENT_USER } from 'graphql/queries'
import { useQuery } from '@apollo/react-hooks'
import {
  CameraIcon,
  CheckmarkIcon,
  CommentIcon,
  OptionIcon,
  SignatureIcon,
  DocuSignIcon,
} from 'components/common/custom-icons'
import { TASK_TYPES } from 'constants/types'
import { replaceSnippetsInText } from 'utils/helpers/snippets-helper'
import { captureException } from 'utils/sentry'
import { useTranslation } from 'react-i18next'
import { ICON_NAMES } from 'constants/icons'
import Icon from 'components/common/Icon'

const defaultTaskImage = require('assets/images/png/default_task_image.png')

const UpNextCard = ({
  task,
  snippetsMapping,
}: {
  task: TaskUncompleteType
  snippetsMapping: StringMap
}) => {
  const history = useHistory()
  const client = useApolloOfflineClient()
  const appContextValue = useAppContext()
  const { selectedDay } = appContextValue
  const { t, i18n } = useTranslation()

  const { data: currentUserData } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })

  const handleMissingSnippet = useMemo(
    () => (key: string) => {
      captureException(`Missing snippet: ${key}, taskId: ${task.id}`)
    },
    [task],
  )
  const titleWithSnippets = useMemo(
    () =>
      replaceSnippetsInText(task.title, snippetsMapping, handleMissingSnippet),
    [task.title, snippetsMapping],
  )

  function renderDueDate() {
    const today = new Date()
    const dueDate = new Date(task.dueDate.replace(/-/g, '/'))
    const dueDateText = format(
      dueDate,
      t('monthDayYearDateFormat'),
      i18n.resolvedLanguage,
    )

    let dueDayText = ''
    if (isToday(dueDate)) {
      dueDayText = t('dueToday')
    } else if (isBefore(dueDate, today)) {
      const dueIn = differenceInDays(today, dueDate)
      dueDayText = `${t('due')} ${dueIn} ${t('days')} ${t('ago')}`
    } else {
      const dueIn = differenceInDays(dueDate, today)
      dueDayText = `${t('dueIn')} ${dueIn} ${t('days')}`
    }

    return (
      <Fragment>
        <div className="upnext-card-right-subtitle">{dueDayText}</div>
        <div className="upnext-card-right-title">{dueDateText}</div>
      </Fragment>
    )
  }

  function goToTasksToday() {
    // set selected task
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: task.id,
          __typename: 'AppData',
        },
      },
    })

    startASession(
      client,
      task.locationHierarchyId,
      selectedDay,
      null,
      appContextValue,
      null,
      history,
    )
  }

  function renderBreadcrumb() {
    let text = ''
    const { parent } = task.locationHierarchy

    if (parent) {
      if (parent.owner && currentUserData?.user?.id !== parent.owner.id) {
        text += `${parent.owner.fullName} > `
      }

      if (parent.title == '__root__') {
        text += task.locationHierarchy.templateCategoryTitle
      } else {
        text += `${parent.templateCategoryTitle} > ${task.locationHierarchy.templateCategoryTitle}`
      }

      return text
    }
  }

  function renderTaskIcon() {
    switch (task.taskType) {
      case TASK_TYPES.PHOTO:
        return <CameraIcon opacity={0.16} height={36} width={36} />
      case TASK_TYPES.SIGN:
        return <SignatureIcon opacity={0.16} height={36} width={36} />
      case TASK_TYPES.DOCUSIGN:
        return <DocuSignIcon opacity={0.16} height={36} width={36} />
      case TASK_TYPES.TEXT:
        return <CommentIcon opacity={0.16} height={36} width={36} />
      case TASK_TYPES.MULTIPLE_OPTION:
        return <OptionIcon opacity={0.16} height={36} width={36} />
      case TASK_TYPES.UPLOAD_MEDIA:
        return (
          <Icon
            name={ICON_NAMES.CLOUD_UPLOAD}
            size={36}
            color={COLORS.TURQUOISE}
            style={{
              opacity: 0.16,
            }}
          />
        )
      default:
        return <CheckmarkIcon opacity={0.16} height={36} width={36} />
    }
  }

  return (
    <StyledCard onClick={goToTasksToday}>
      <div className="upnext-card-left">
        <div className="upnext-card-left-icon">
          <img height={36} src={task.mediaUrl ?? defaultTaskImage} />
        </div>
        <div className="upnext-card-left-text">
          <div className="upnext-card-left-subtitle">{renderBreadcrumb()}</div>
          <div className="upnext-card-left-title">{titleWithSnippets}</div>
        </div>
      </div>
      <div className="upnext-card-right">
        <div className="upnext-card-right-text">
          {!!task.dueDate && renderDueDate()}
        </div>
        <div className="upnext-card-right-icon">{renderTaskIcon()}</div>
      </div>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.04);

  .upnext-card-left {
    display: flex;
    flex: 6;

    .upnext-card-left-icon {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .upnext-card-left-text {
      display: flex;
      flex-direction: column;
      max-width: 300px;

      .upnext-card-left-title {
        font-size: 14px;
        font-weight: 500;
        color: ${COLORS.NAVY_BLUE};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .upnext-card-left-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: ${COLORS.SIXES_GREY};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .upnext-card-right {
    display: flex;
    flex: 3;
    justify-content: space-between;
    margin-left: 10px;

    .upnext-card-right-text {
      display: flex;
      flex-direction: column;

      .upnext-card-right-title {
        font-size: 14px;
        font-weight: 500;
        color: ${COLORS.SIXES_GREY};
      }

      .upnext-card-right-subtitle {
        font-size: 12px;
        font-weight: 400;
        color: ${COLORS.FIVES_GREY};
      }
    }

    .upnext-card-right-icon {
      display: flex;
      align-items: center;
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
  }
`

export default UpNextCard
