import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  TextInputKeyPressEventData,
  NativeSyntheticEvent,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import LoginTextInput from './LoginTextInput'

function EmailField({
  value,
  setValue,
  editable,
  handleOnSubmit,
}: {
  value: string
  setValue: DispatchType<string>
  editable: boolean
  handleOnSubmit: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
}) {
  const { t } = useTranslation()
  
  return (
    <View style={styles.emailInputHolder}>
      <Text style={{ marginBottom: 8, fontFamily: 'Poppins_400Regular' }}>
        {t('emailAddress')}
      </Text>
      <LoginTextInput
        value={value}
        setValue={setValue}
        placeholder="example@youremail.com"
        editable={editable}
        handleOnSubmit={handleOnSubmit}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    marginBottom: 14,
    flexDirection: 'column',
  },
})

export default EmailField
