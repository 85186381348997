import React from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import LocationEmployee from './ManagerLocation'
import { ImageSourcePropType } from 'react-native'

const ManagerLocationsGroup = ({
  employeeLocations,
  onFolderChange,
  parentImageSource,
}: {
  employeeLocations: LocationInterface[]
  onFolderChange: (
    newParentId: string,
    newParentImageSource: ImageSourcePropType,
    owner?: User,
  ) => void
  parentImageSource: ImageSourcePropType
}) => {
  const employeeName = employeeLocations[0]?.owner.fullName

  return (
    <StyledManagerLocationsGroup>
      <div className="employee-name">{employeeName}</div>
      <div className="locations">
        {employeeLocations.map((location) => (
          <LocationEmployee
            location={location}
            onFolderChange={onFolderChange}
            parentImageSource={parentImageSource}
          />
        ))}
      </div>
    </StyledManagerLocationsGroup>
  )
}

const StyledManagerLocationsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 12px;
  background: white;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: ${COLORS.NAVY_BLUE};

  .employee-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  .locations {
    display: flex;
    flex-direction: column;
  }
`

export default ManagerLocationsGroup
