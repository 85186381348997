import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const WelcomeIcon = (props: SvgProps) => (
  <Svg width={144} height={144} fill="none" {...props}>
    <Path
      d="M44.167 41.093a4.219 4.219 0 0 1 0-5.966L62.63 16.662a4.219 4.219 0 0 1 5.967 5.966L50.134 41.093a4.218 4.218 0 0 1-5.967 0Z"
      fill="#08475E"
    />
    <Path
      d="M93.867 41.093 75.403 22.627a4.219 4.219 0 0 1 5.967-5.966l18.463 18.466a4.22 4.22 0 0 1-5.966 5.966Z"
      fill="#05303D"
    />
    <Path
      d="M139.781 144H4.219A4.219 4.219 0 0 1 0 139.781V38.109a4.219 4.219 0 0 1 4.219-4.218H139.78A4.219 4.219 0 0 1 144 38.109v101.672a4.22 4.22 0 0 1-4.219 4.219Z"
      fill="#50CEA2"
    />
    <Path
      d="M139.781 33.89H72V144h67.781a4.22 4.22 0 0 0 4.219-4.219V38.109a4.219 4.219 0 0 0-4.219-4.218Z"
      fill="#40AD87"
    />
    <Path
      d="M72.008 26.513c7.322 0 13.257-5.935 13.257-13.257C85.265 5.936 79.33 0 72.008 0c-7.32 0-13.256 5.935-13.256 13.256 0 7.322 5.935 13.257 13.256 13.257Z"
      fill="#F0F5FF"
    />
    <Path
      d="M72 0c7.31 0 13.256 5.947 13.256 13.256 0 7.31-5.947 13.257-13.256 13.257V0ZM101.707 92.776a4.22 4.22 0 0 0-4.746 1.985l-5.374 9.405-5.375-9.405a4.22 4.22 0 0 0-7.882 2.093v27.112a4.219 4.219 0 1 0 8.438 0V112.74l1.156 2.023a4.219 4.219 0 0 0 7.325 0l1.156-2.023v11.226a4.22 4.22 0 0 0 8.437 0V96.854a4.217 4.217 0 0 0-3.135-4.078Z"
      fill="#D8F1FB"
    />
    <Path
      d="M47.954 85.241a4.22 4.22 0 0 0 3.754-3.245l6.326-27.113a4.218 4.218 0 1 0-8.217-1.917l-3.369 14.437L42 56.81a4.219 4.219 0 0 0-7.78 0l-4.448 10.593-3.369-14.437a4.22 4.22 0 0 0-8.216 1.917l6.326 27.113a4.22 4.22 0 0 0 7.998.675l5.6-13.333 5.6 13.333c.166.397 1.356 2.814 4.244 2.57ZM67.781 85.255h31.331a4.219 4.219 0 0 0 0-8.437H72v-5.12h25.119a4.22 4.22 0 0 0 0-8.437H72v-5.118h27.112a4.219 4.219 0 0 0 0-8.438h-31.33a4.219 4.219 0 0 0-4.22 4.219v27.112a4.219 4.219 0 0 0 4.22 4.22Z"
      fill="#F0F5FF"
    />
    <Path
      d="M123.963 101.072a4.219 4.219 0 1 0 0-8.437h-9.037a4.219 4.219 0 0 0-4.219 4.218v27.113a4.22 4.22 0 0 0 4.219 4.219h9.037a4.22 4.22 0 0 0 0-8.438h-4.818v-5.118h2.559a4.22 4.22 0 0 0 0-8.438h-2.559v-5.119h4.818ZM123.965 85.255a4.219 4.219 0 0 0 0-8.437h-7.078V53.924a4.219 4.219 0 0 0-8.438 0v27.112a4.219 4.219 0 0 0 4.219 4.22h11.297Z"
      fill="#D8F1FB"
    />
    <Path
      d="M35.853 112.971a4.221 4.221 0 0 1 4.219 4.221 10.92 10.92 0 0 1-3.22 7.773 10.924 10.924 0 0 1-7.777 3.22c-6.064 0-10.997-4.933-10.997-10.997v-13.556c0-6.064 4.933-10.997 10.997-10.997s10.997 4.933 10.997 10.997a4.218 4.218 0 0 1-8.438 0 2.563 2.563 0 0 0-2.559-2.56 2.563 2.563 0 0 0-2.56 2.56v13.556a2.563 2.563 0 0 0 4.371 1.811 2.537 2.537 0 0 0 .748-1.807v-.004a4.217 4.217 0 0 1 4.22-4.217ZM58.745 92.635c-7.316 0-13.26 5.944-13.26 13.251v9.047c0 7.307 5.944 13.252 13.26 13.252 7.307 0 13.252-5.945 13.252-13.252v-9.047c0-7.307-5.945-13.251-13.252-13.251Zm4.814 22.298a4.822 4.822 0 0 1-4.823 4.814 4.82 4.82 0 0 1-4.814-4.814v-9.047a4.82 4.82 0 0 1 9.637 0v9.047Z"
      fill="#F0F5FF"
    />
    <Path
      d="M101.338 67.48a4.22 4.22 0 0 0-4.219-4.218H72v8.437h25.119a4.219 4.219 0 0 0 4.219-4.218ZM99.112 49.705H72v8.438h27.112a4.219 4.219 0 0 0 0-8.438ZM99.112 85.256a4.219 4.219 0 0 0 0-8.438H72v8.438h27.112Z"
      fill="#D8F1FB"
    />
  </Svg>
)

export default WelcomeIcon
