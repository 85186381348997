import Color from 'color'

const COLORS = {
  TRANSPARENT: 'transparent',
  BLUE: '#2E86AB',
  BLUEDARK: '#2969B1',
  BLUELIGHT: '#2f95dc',
  REDDARK: '#721121',
  YELLOWDARK: '#F18F01',
  ORANGE: '#FFC024',
  GREEN: '#388659',
  WHITE: 'white',
  LIGHTER_GREY: '#E7E7E7',
  LIGHTGRAY: '#f2f2f2',
  GRAYLIGHT1: '#fefefe',
  GRAYLIGHT2: 'rgba(248, 248, 248, 1)',
  GRAYLIGHT3: 'rgba(224, 224, 206, 1)',
  GRAYMEDIUM: 'rgba(188, 187, 181, 1)',
  GRAYDEFAULT: '#ccc',
  GRAYDARK1: 'rgba(71, 71, 71, 1)',
  GRAYDARK2: 'rgba(51, 50, 50, 1)',
  GRAYDARK3: '#333333',
  BLACK: '#000',
  LIGHT_TURQUOISE: '#E3FBF2',
  TURQUOISE: '#50CEA2',
  DARK_TURQUOISE: '#35928C',
  TURQUOISE_DISABLED: 'rgba(80, 206, 162, 0.4)',
  YELLOW: '#FFCE55',
  SECONDARY: '#E74035',
  RED_TRANSPARENT_75: 'rgba(231, 64, 53, 0.75)',
  RED_TRANSPARENT_13: 'rgba(231, 64, 53, 0.13)',
  ORANGE_TRANSPARENT_13: 'rgba(243, 170, 60, 0.13)',
  FIVES_GREY: '#555555',
  SIXES_GREY: '#666666',
  LIGHT_POWDER_BLUE: '#F5F7FB',
  POWDER_BLUE: '#DCE7F7',
  MIDDLE_GREY: '#CCCCCC',
  NAVY_BLUE: '#243162',
  LIGHT_HOT_PINK: '#FFF2F7',
  MID_HOT_PINK: '#F2729C',
  HOT_PINK: '#C90953',
  LIGHT_TAG_YELLOW: '#FFECBD',
  MID_TAG_YELLOW: '#FFD877',
  TAG_YELLOW: '#FFBD1B',
}

// set colors
const COLOR_SETTINGS = {
  tintColor: COLORS.BLUELIGHT,
  tabIconDefault: COLORS.GRAYDEFAULT,
  tabIconSelected: COLORS.BLUELIGHT,
  tabBar: COLORS.GRAYLIGHT1,
  placeholders: Color(COLORS.GRAYDARK2).alpha(0.75).string(),
  borderForm: Color(COLORS.GRAYMEDIUM).alpha(0.35).string(),
}

export default {
  ...COLORS,
  ...COLOR_SETTINGS,
}
