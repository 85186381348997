import * as React from 'react'
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg'

function ChevronDown(props: SvgProps) {
  return (
    <Svg width={16} height={16} fill="none" {...props}>
      <G clipPath="url(#prefix__clip0_1118_67557)">
        <Path
          d="M8 12a.948.948 0 01-.62-.23L0 5.446 1.24 4 8 9.796 14.762 4 16 5.445 8.62 11.77A.948.948 0 018 12z"
          fill="#555"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_1118_67557">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default ChevronDown
