import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Sections1: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content, pictureUrl } = section
  if (content) {
    const { text } = content
    return (
      <Container>
        <Icon>
          {pictureUrl ? (
            <img src={pictureUrl} alt="" style={{ maxWidth: '96px' }} />
          ) : null}
        </Icon>
        <Text>{text}</Text>
      </Container>
    )
  }
  return <Box>Sections1</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 26px;
  width: 100%;
  box-sizing: border-box;
`

const Icon = styled.div``

const Text = styled.div`
  max-width: 640px;
  font-style: normal;
  font-family: 'Poppins_400Regular';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  word-wrap: break-word;
`
