import RowView from 'components/common/fragments/RowView'
import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import COLORS from 'constants/colors'
import { STYLE_CONSTANTS } from 'constants/es-style'
import React, { useEffect, useRef, useState } from 'react'
import { Text, TextInput, View } from 'react-native'
import { Card } from 'react-native-elements'
import Image from 'components/common/Image'
import EStyleSheet from 'react-native-extended-stylesheet'

const IMAGE_SIZE = 84
const styles = EStyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
  },
  cardContainer: {
    borderWidth: 0,
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
    elevation: 0,
    marginTop: 0,
    borderBottomColor: COLORS.LIGHTGRAY,
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  content: {
    width: '100%',
    ...STYLE_CONSTANTS.FLEX_CENTER_VERT,
  },
  cardWrapper: {
    flexDirection: 'row',
  },
  imageContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    borderRadius: 22,
    overflow: 'hidden',
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  taskCompleteWrapper: {
    position: 'absolute',
    right: 0,
  },
  taskRemoveElement: {
    marginRight: 10,
  },
  itemTextWrap: {
    flexDirection: 'column',
    marginLeft: 20,
    width: '100% - 230',
  },
  titleText: {
    fontSize: 14,
    color: COLORS.GRAYDARK2,
    fontWeight: 'bold',
  },
  parText: {
    fontSize: 14,
    color: COLORS.GRAYDARK2,
    marginRight: 5,
    borderRadius: 5,
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  priceWrap: {
    marginTop: 5,
    flexDirection: 'row',
  },
  priceText: {
    fontSize: 14,
    color: COLORS.TURQUOISE,
  },
  quantityText: {
    fontSize: 14,
    marginLeft: 10,
    color: COLORS.GRAYLIGHT3,
  },
  textInput: {
    height: 40,
    borderColor: COLORS.GRAYLIGHT3,
    borderWidth: 1,
    width: 50,
    marginRight: 5,
    borderRadius: 5,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: 'center',
  },
  totalContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'row',
  },
  totalText: {
    width: '50%',
    fontSize: 18,
    fontWeight: 'bold',
  },
  sumText: {
    width: '50%',
    fontSize: 18,
    textAlign: 'right',
    fontWeight: 'bold',
    color: COLORS.TURQUOISE,
  },
})

interface OrderItemProps {
  product: ClientCustomerProductCatalogue
  doneEdit: (id: string, text?: string) => void
  valueChanged: (id: string) => void
  itemFocused: (id: string) => void
  focus: boolean
  value: string
}

export default function OrderItem({
  product,
  valueChanged,
  focus,
  value,
}: OrderItemProps) {
  const { price, parLevel, clientProduct } = product
  const { name, image } = clientProduct
  const quantity = 1
  const inputEl = useRef(null)

  useEffect(() => {
    if (focus && inputEl && !inputEl.current.isFocused()) {
      inputEl.current.focus()
    }
  }, [focus])

  const imageUri = image != null ? image : ''

  return (
    <View>
      <Card
        containerStyle={styles.cardContainer}
        wrapperStyle={styles.cardWrapper}
      >
        <RowView style={styles.content}>
          <Image
            containerStyle={styles.imageContainer}
            style={styles.imageStyle}
            source={imageUri.length != 0 ? { uri: imageUri } : null}
            PlaceholderContent={<GeneralActivityIndicator />}
          />
          <View style={styles.itemTextWrap}>
            <Text style={styles.titleText}>{name}</Text>
            <View style={styles.priceWrap}>
              <Text style={styles.priceText}>${price}</Text>
              <Text style={styles.quantityText}>({quantity})</Text>
            </View>
          </View>
          <View style={styles.taskCompleteWrapper}>
            <TextInput
              keyboardType={'number-pad'}
              returnKeyType="done"
              value={value}
              onChangeText={(text) => valueChanged(text)}
              style={styles.textInput}
            />
            {parLevel && <Text style={styles.parText}>Par:{parLevel}</Text>}
          </View>
        </RowView>
      </Card>
    </View>
  )
}
