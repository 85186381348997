import React, { FC } from 'react'

import { View } from 'react-native'

import { Columns1 } from './sections/Columns1'
import { Columns3 } from './sections/Columns3'
import { Header1 } from './sections/Header1'
import { Header2 } from './sections/Header2'
import { Header3 } from './sections/Header3'
import { Header7 } from './sections/Header7'
import { Hero1 } from './sections/Hero1'
import { Hero3 } from './sections/Hero3'
import { Sections1 } from './sections/Sections1'
import { Sections3 } from './sections/Sections3'
import { Sections4 } from './sections/Sections4'
import { Sections5 } from './sections/Sections5'
import { Sections7 } from './sections/Sections7'
import { Sections8 } from './sections/Sections8'

export interface SectionViewComponentProps {
  section: any
}

export interface SectionEditComponentProps {
  register: any
  section: any
  subsections: any[]
}

const viewComponents: Record<string, FC<any>> = {
  columns_1: Columns1,
  columns_3: Columns3,
  header_1: Header1,
  header_2: Header2,
  header_3: Header3,
  header_7: Header7,
  hero_1: Hero1,
  hero_3: Hero3,
  sections_1: Sections1,
  sections_3: Sections3,
  sections_4: Sections4,
  sections_5: Sections5,
  sections_7: Sections7,
  sections_8: Sections8,
}

const PageSection = ({ section }: { section: any }) => {
  const { sectionType } = section || {}

  const ViewComponent = viewComponents[section.sectionType]

  if (ViewComponent) {
    return <ViewComponent section={section} />
  }
  return <View>Section type: {sectionType}</View>
}

export default PageSection
