import React from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import Resource from './Resource.web'

const Level2ResourcesSection = ({
  resources,
  title,
}: {
  resources: [ResourceInterface]
  title: string
}) => {
  return (
    <Level2ResourcesSectionStyled>
      <h3 className="title">{title}</h3>
      {resources && (
        <ResourcesListStyled>
          {sortBy(resources, ['position']).map(
            (resource) => (
              <Resource
                key={resource.id}
                title={resource.title}
                url={resource.url}
              />
            ),
          )}
        </ResourcesListStyled>
      )}
    </Level2ResourcesSectionStyled>
  )
}

const Level2ResourcesSectionStyled = styled.div`
  flex-basis: 300px;

  .title {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 12px;
  }
`

const ResourcesListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
`

export default Level2ResourcesSection
