import React from 'react'
import styled from 'styled-components'

const Resource = ({ title, url }: { title: string; url: string }) => {
  return (
    <ResourceStyled>
      <a href={url} target="_blank">
        {title}
      </a>
    </ResourceStyled>
  )
}

const ResourceStyled = styled.li`
  margin-bottom: 12px;

  &:before {
    color: #b10000;
    content: '•';
    display: inline-block;
    width: 1em;
  }

  a {
    color: inherit;
  }
`

export default Resource
