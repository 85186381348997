import Feedback from 'components/common/Feedback'
import COLORS from 'constants/colors'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { IS_WEB } from 'constants/static'
import MESSAGES from 'constants/messages'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { useCallback, useEffect, useState } from 'react'
import { ImageSourcePropType, Platform, Text, View } from 'react-native'
import { Card } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useApolloOfflineClient } from 'react-offix-hooks'
import { useHistory, useLocation } from 'react-router'
import { useAppContext } from 'utils/app-context'
import { LocationElementDetailsModal } from './LocationElementDetailsModal'
import { startASession } from './locations-helper'
import LocationProgressCircle from './LocationProgressCircle'
import { useTranslation } from 'react-i18next'
import Image from 'components/common/Image'
import { GeneralActivityIndicator } from 'components/Index'

const defaultLocationImage = require('assets/images/jpg/location_default.jpg')

interface LocationElementProps extends LocationInterface {
  onFolderChange: (
    id: string,
    imgSrc: ImageSourcePropType,
    owner?: User,
  ) => void
  parentImageSource: ImageSourcePropType
}

const LocationElement = ({
  sessionUuid,
  completed,
  onFolderChange,
  parentImageSource,
  ...location
}: LocationElementProps) => {
  const {
    id: locationHierarchyId,
    title,
    clientCustomerId,
    clientAddress,
    invoiceFileUrls,
    children,
    logoUrl,
    notes,
    tasks,
    notApplicable,
    cantComplete,
  } = location
  const appContextValue = useAppContext()
  const { selectedDay } = appContextValue
  const client = useApolloOfflineClient()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [hasOpenSession, setHasOpenSession] = useState<boolean | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const locationApp = useLocation()
  const owner = locationApp?.state?.owner
  const numberOfCompletedTasks = completed + notApplicable + cantComplete
  const { t } = useTranslation()

  const imageSource = logoUrl
    ? { uri: logoUrl }
    : parentImageSource || defaultLocationImage

  const invoiceFileUrlJson = JSON.parse(invoiceFileUrls)
  // on folder change
  useEffect(() => {
    setHasOpenSession(Boolean(sessionUuid))
  }, [Boolean(sessionUuid)])

  const interactable = hasOpenSession !== null
  const hasChildLocations = children?.length > 0

  const navigationData = {
    locationHierarchyId,
    locationTitle: title,
    sessionUuid,
    completed,
    notes,
    loadData: true,
    owner,
  }

  const goToTasksToday = () => {
    if (hasOpenSession) {
      history.push({
        pathname: ROUTE_NAMES.TASKS_TODAY,
        search: `?locationHierarchyId=${locationHierarchyId}`,
        state: navigationData,
      })
    } else {
      startASession(
        client,
        locationHierarchyId,
        selectedDay,
        title,
        appContextValue,
        notes,
        history,
      )
    }
  }

  const changeToFolder = useCallback(() => {
    if (!interactable) {
      return
    }
    setLoading(true)
    if (hasChildLocations) {
      onFolderChange(locationHierarchyId, imageSource, owner)
    } else if (tasks == 0) {
      alert(MESSAGES.NO_TASKS_OR_SUBLOCATIONS)
    } else {
      goToTasksToday()
    }
  }, [navigationData, imageSource, hasOpenSession])

  const openModal = () => setModalOpen(true)
  const addressOrClientCustomerId = clientAddress || clientCustomerId

  function renderLocationSubtitle() {
    if (tasks == numberOfCompletedTasks) {
      return t('allTasksComplete', { tasks })
    } else if (numberOfCompletedTasks == 0) {
      return t(tasks == 1 ? 'taskToDo' : 'tasksToDo', { tasks })
    }

    return t('someTasksComplete', {
      numberOfCompletedTasks,
      numberOfTasks: tasks,
    })
  }

  return (
    <Feedback onPress={changeToFolder}>
      <Card
        key={locationHierarchyId}
        containerStyle={[
          styles.cardContainer,
          IS_WEB
            ? {
                shadowColor: 'rgba(0, 0, 0, 0.04)',
                shadowOffset: { width: 0, height: 1 },
                shadowSpread: 1,
                shadowRadius: 1,
              }
            : {},
        ]}
      >
        <View style={styles.listEntry}>
          <View style={styles.locationLabelling}>
            {!IS_WEB && invoiceFileUrlJson && (
              <Feedback onPress={openModal}>
                <View style={styles.imageWrapper}>
                  <Image
                    containerStyle={styles.imageContainer}
                    style={styles.imageStyle}
                    source={imageSource}
                    PlaceholderContent={<GeneralActivityIndicator />}
                  />
                </View>
              </Feedback>
            )}
            <View style={styles.locationContent}>
              <Text style={styles.locationName} numberOfLines={2}>
                {title}
              </Text>
              <Text style={styles.locationNameSubtitle}>
                {renderLocationSubtitle()}
              </Text>
              {addressOrClientCustomerId && (
                <Text style={styles.taskSubtitle}>
                  {'\n'}
                  {addressOrClientCustomerId}
                </Text>
              )}
            </View>
          </View>
          <LocationProgressCircle
            numberOfTasks={tasks}
            numberOfCompletedTasks={numberOfCompletedTasks}
          />
        </View>

        {invoiceFileUrlJson && (
          <LocationElementDetailsModal
            isOpen={modalOpen}
            {...{ setModalOpen, invoiceFileUrlJson }}
          />
        )}
      </Card>
    </Feedback>
  )
}

const IMAGE_SIZE = 60

// style
const styles = EStyleSheet.create({
  cardContainer: {
    padding: 0,
    marginLeft: IS_WEB ? 0 : 3,
    marginRight: IS_WEB ? 0 : 3,
    marginTop: 6,
    marginBottom: 6,
    height: 108,
    justifyContent: 'center',
    borderWidth: 0,
    borderRadius: 16,
  },
  listEntry: {
    alignItems: 'center',
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    flexDirection: 'row',
    borderRadius: 10,
    padding: 0,
  },
  imageWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER,
    marginLeft: -15,
  },
  imageContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  imageStyle: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  locationLabelling: {
    flexDirection: 'row',
    marginLeft: 15,
    alignItems: 'center',
    flex: 1,
  },
  locationContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    maxWidth: IS_WEB ? '100%' : '100% - 200',
  },
  locationName: {
    fontSize: 14,
    color: COLORS.NAVY_BLUE,
    fontWeight: '600',
    lineHeight: 24,
  },
  locationNameSubtitle: {
    color: COLORS.SIXES_GREY,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 18,
  },
  numberTasks: {
    fontSize: '$fontSmall',
  },
  locationDetails: {
    marginTop: 3,
    flexDirection: 'row',
  },
  Filler: {
    flex: 1,
  },
  toggleSessionButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  toggleSessionButtonTitle: {
    marginLeft: 5,
    fontSize: '$fontMediumSmall',
  },
  rightIconWrapper: {
    marginRight: 20,
    minWidth: IS_WEB ? 60 : 52,
    display: 'flex',
    alignItems: 'flex-end',
  },
  progressCircleWrapper: {
    marginRight: 20,
    flexShrink: 0,
  },
  taskSubtitle: {
    color: COLORS.SECONDARY,
    fontSize: 10,
  },
})

export default LocationElement
