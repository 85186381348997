import { useMemo, useEffect, useState } from 'react'
import COLORS from 'constants/colors'
import { EVENT_TYPES } from 'constants/types'
import { isCantCompleteEvent } from 'components/task-complete/helpers/task-complete-helpers'

type Props = {
  completedEvent: TaskCompleteType
}
const useCompleteElementStates = ({ completedEvent }: Props) => {
  const isNotApplicableCompletedEvent = useMemo(
    () => completedEvent?.eventType === EVENT_TYPES.NOT_APPLICABLE,
    [completedEvent],
  )
  const [isCantComplete, setIsCantComplete] = useState(
    isCantCompleteEvent(completedEvent),
  )
  const [completeState, setCompleteState] = useState(() => !!completedEvent)

  useEffect(() => {
    setCompleteState(!!completedEvent)
    setIsCantComplete(isCantCompleteEvent(completedEvent))
  }, [completedEvent, isNotApplicableCompletedEvent])

  const taskDone = useMemo(
    () => completeState && !isNotApplicableCompletedEvent && !isCantComplete,
    [completeState, isNotApplicableCompletedEvent, isCantComplete],
  )

  const iconColor = useMemo(() => {
    return isCantComplete
      ? COLORS.RED_TRANSPARENT_75
      : isNotApplicableCompletedEvent
      ? COLORS.ORANGE
      : COLORS.TURQUOISE
  }, [isCantComplete, isNotApplicableCompletedEvent])

  return {
    isNotApplicableCompletedEvent,
    isCantComplete,
    setCompleteState,
    completeState,
    taskDone,
    iconColor,
  }
}

export default useCompleteElementStates
