import React from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import COLORS from 'constants/colors'

function Separator() {
  return (
    <View style={styles.horizontalLineWrapper}>
      <View style={styles.horizontalLine} />
      <View>
        <Text style={styles.horizontalLineText}>or</Text>
      </View>
      <View style={styles.horizontalLine} />
    </View>
  )
}

const styles = EStyleSheet.create({
  horizontalLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#DBDBDB',
  },
  horizontalLineText: {
    width: 50,
    textAlign: 'center',
    color: '#DBDBDB',
  },
  horizontalLineWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 30,
  },
})

export default Separator
