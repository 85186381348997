import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import MESSAGES from 'constants/messages'
import { EventTypesUnion, EVENT_TYPES } from 'constants/types'
import completeButtonStyles from './constants/completeButtonStyles'
import COLORS from 'constants/colors'
import useCreateEvent from './hooks/useCreateEvent'
import {
  CheckmarkIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import CompleteButton from 'components/task-list/buttons/CompleteButton'

// toggle component to finish a task
const TaskToggleToComplete = (props: CompleteElementProps) => {
  const { sessionUuid, locationHierarchyId, id: taskId, completedEvent } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    setCompleteState,
    taskDone,
    iconColor,
  } = useCompleteElementStates({
    completedEvent,
  })

  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
    sessionUuid,
  })

  const toggleComplete = (eventType: EventTypesUnion) => {
    createEvent({ eventType, createEventData: { eventType } })
  }

  const onValueChange = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
    } else {
      const value =
        isCantComplete || isNotApplicableCompletedEvent ? true : !completeState
      const eventType = value
        ? EVENT_TYPES.COMPLETE_TASK
        : EVENT_TYPES.UNCOMPLETE_TASK
      setCompleteState(value)
      toggleComplete(eventType)

      if (props.showAsButton) {
        props.closeModal()
      }
    }
  }

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!completeState) {
      return (
        <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <CheckmarkIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  return !props.showAsButton ? (
    <View style={styles.container}>
      <TouchableOpacity style={styles.iconContainer} onPress={onValueChange}>
        {renderIcon()}
      </TouchableOpacity>
    </View>
  ) : (
    <View style={styles.buttonContainer}>
      <CompleteButton selected={taskDone} onPress={onValueChange} />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    ...completeButtonStyles.buttonContainer,
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskToggleToComplete
