export const debounce = (callback: (val: string) => any, timeout: number) => {
  let timeoutRef: number = null
  return (newVal: string) => {
    if (timeoutRef) {
      clearTimeout(timeoutRef)
    }

    timeoutRef = setTimeout(() => {
      callback(newVal)
    }, timeout)
  }
}
