import React from 'react'

import {
  CheckmarkIcon,
  CheckmarkOutlinedIcon,
} from 'components/common/custom-icons'
import DefaultButton from './DefaultButton'
import { useTranslation } from 'react-i18next'

const CompleteButton = ({
  selected,
  onPress,
}: {
  selected: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation();

  return (
    <DefaultButton
      title={t(selected ? 'completed' : 'complete')}
      icon={<CheckmarkIcon style={{ marginRight: 6 }} height={24} width={24} />}
      hoverIcon={
        <CheckmarkOutlinedIcon
          style={{ marginRight: 6 }}
          height={24}
          width={24}
        />
      }
      selected={selected}
      onPress={onPress}
    />
  )
}

export default CompleteButton
