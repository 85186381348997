import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import LoginTextInput from './LoginTextInput'

function PasswordField({
  value,
  setValue,
  handleOnSubmit,
}: {
  value: string
  setValue: DispatchType<string>
  handleOnSubmit: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
}) {
  const { t } = useTranslation()

  return (
    <View>
      <Text style={styles.container}>{t('password')}</Text>
      <LoginTextInput
        value={value}
        setValue={setValue}
        placeholder="Minimum 8 characters"
        secureTextEntry
        handleOnSubmit={handleOnSubmit}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    marginBottom: 8,
    marginTop: 32,
    fontFamily: 'Poppins_400Regular',
  },
})

export default PasswordField
