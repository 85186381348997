import { iframeModel, useHtmlIframeProps } from '@native-html/iframe-plugin'
import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

const IframeRenderer = function IframeRenderer(props: any) {
  const iframeProps = useHtmlIframeProps(props)

  if (!iframeProps) {
    return null
  }

  // WebView doesn't work on web se we need to do a custom render of iframe
  return (
    <View style={styles.containerOuter}>
      <View style={styles.containerInner}>
        <iframe {...iframeProps.htmlAttribs} height="100%" />
      </View>
    </View>
  )
}

export const HTMLRenderers = {
  iframe: IframeRenderer,
}

export const customHTMLElementModels = {
  iframe: iframeModel,
}

const styles = EStyleSheet.create({
  containerOuter: {
    width: '100%',
    paddingBottom: '66%',
    position: 'relative',
    overflowX: 'auto',
  },
  containerInner: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
})
