import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function Comment(props: SvgProps) {
  return (
    <Svg width={28} height={26} viewBox="0 0 28 26" fill="#50CEA2" {...props}>
      <Path d="M24.15 0H3.85C1.726 0 0 1.743 0 3.885v12.54c0 2.137 1.72 3.877 3.836 3.885V26l8.102-5.69H24.15c2.122 0 3.849-1.743 3.849-3.886V3.885C28 1.743 26.273 0 24.15 0zm-3.644 14.572H7.494v-1.656h13.012v1.656zm0-3.532H7.494V9.383h13.012v1.655zm0-3.533H7.494V5.851h13.012v1.656z" />
    </Svg>
  )
}

export default Comment
