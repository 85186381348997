import React from 'react'
import { View, Image, Text, Dimensions } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { IS_WEB } from 'constants/static'
import { useTranslation } from 'react-i18next'

export default function LeftSide({ children }: { children: ChildrenType }) {
  const windowHeight = Dimensions.get('window').height
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View
        style={{
          alignItems: 'center',
        }}
      >
        <Image
          source={require('assets/images/png/tangelo_logo.png')}
          style={styles.logoImage}
        />
        <Text
          style={[
            styles.subtitle,
            windowHeight <= 800 ? styles.subtitleSmall : {},
          ]}
        >
          {t('signIn')}
        </Text>
        <View style={styles.content}>{children}</View>
        <Text
          style={{
            color: 'rgba(42, 55, 73, 0.6)',
            marginTop: windowHeight <= 800 ? 20 : 60,
          }}
        >
          Copyright © Tangelo.ai INC 2023
        </Text>
      </View>
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    maxHeight: 882,
    flex: 1,
    justifyContent: 'center',
    alignItems: IS_WEB ? 'flex-end' : 'center',
    paddingRight: IS_WEB ? 60 : 0,
  },
  content: {
    minWidth: IS_WEB ? 424 : 0,
    minHeight: IS_WEB ? 450 : 0,
    justifyContent: 'center',
  },
  logoImage: {
    width: 218,
    height: 59,
  },
  subtitle: {
    marginTop: 80,
    marginBottom: 40,
    fontSize: 32,
    fontWeight: '700',
    color: '#2a3749',
    fontFamily: 'Poppins_400Regular',
  },
  subtitleSmall: {
    marginTop: 40,
    marginBottom: 0,
  },
})
