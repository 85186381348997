import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-native'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { GET_CURRENT_USER, GET_RESOURCES_HIERARCHY } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import Level1ResourcesSection from './Level1ResourcesSection.web'
import { ROUTE_NAMES } from 'navigation/constants'

import Header from 'components/navigator/Header.web'
import Background from 'components/common/Background'
import ResourcesHeader from './ResourcesHeader.web'

const ResourcesScreenWeb = () => {
  const history = useHistory()

  const { data: currentUserData } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })

  const { data } = useQuery(GET_RESOURCES_HIERARCHY, {
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
  })
  const sections = useMemo(getResourcesLevelTree, [data])

  function getResourcesLevelTree() {
    const resourcesHierarchy = data?.resourcesHierarchy || []

    let level1Sections = resourcesHierarchy.filter(
      (resourceSection) => resourceSection.children.length > 0,
    )

    level1Sections = level1Sections.map((level1Section) => {
      let level2Sections = level1Section.children.map((level2Section) =>
        resourcesHierarchy.find((resource) => resource.id == level2Section.id),
      )
      level2Sections = level2Sections.filter(
        (level2Section) =>
          !!level2Section && level2Section.resources.length > 0,
      )
      level1Section.children = level2Sections
      return level1Section
    })

    return level1Sections.filter(
      (level1Section) => level1Section.children.length > 0,
    )
  }

  useEffect(() => {
    if (currentUserData?.user.personalEmailSession) {
      history.push(ROUTE_NAMES.LOCATIONS)
    }
  }, [currentUserData])

  return (
    <Background>
      <StyledResourcesScreen>
        <Header currentUser={currentUserData?.user} />
        <StyledContent>
          <ResourcesHeader />
          <div>
            {!!sections &&
              sortBy(sections, ['position']).map((section) => (
                <Level1ResourcesSection
                  key={section.id}
                  title={section.title}
                  children={section.children}
                />
              ))}
          </div>
        </StyledContent>
        <StyledFooter />
      </StyledResourcesScreen>
    </Background>
  )
}

const StyledResourcesScreen = styled.div`
  @media (min-width: 992px) {
    width: 782px;
  }
  @media (min-width: 1200px) {
    width: 982px;
  }
  @media (min-width: 1400px) {
    width: 1132px;
  }
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  font-family: SourceSansPro_400Regular;
`

const StyledContent = styled.div`
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.06));
  flex: 1;
  overflow: auto;
  background: white;
`

const StyledFooter = styled.div`
  height: 36px;
  background: black;
`
export default ResourcesScreenWeb
