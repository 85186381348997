import React from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import { useHistory } from 'react-router-native'
import { ROUTE_NAMES } from 'navigation/constants'
import { useTranslation } from 'react-i18next'

function ManagerToggle({ isTasksSelected = true }) {
  const history = useHistory()
  const { t } = useTranslation();

  return (
    <StyledToggle>
      <StyledOption
        selected={isTasksSelected}
        onClick={() => {
          history.push({
            pathname: ROUTE_NAMES.LOCATIONS,
          })
        }}
      >
        {t('tasks')}
      </StyledOption>
      <StyledOption
        selected={!isTasksSelected}
        onClick={() => {
          history.push({
            pathname: ROUTE_NAMES.REPORTS,
          })
        }}
      >
        {t('reports')}
      </StyledOption>
    </StyledToggle>
  )
}

const StyledOption = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 16px;
  font-size: 22px;
  cursor: pointer;
  background-color: ${({ selected }) =>
    selected ? `${COLORS.NAVY_BLUE}` : 'white'};
  color: ${({ selected }) => (selected ? 'white' : `${COLORS.SIXES_GREY}`)};
  font-weight: ${({ selected }) => (selected ? '600' : '300')};
`

const StyledToggle = styled.div`
  display: flex;
  margin: 18px 0px 6px 0px;
  background-color: white;
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
`

export default ManagerToggle
