import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

interface SubsectionValues {
  heading: string
  text: string
}

export const Sections7: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading: mainHeading, text: mainText, subsections } = content
    return (
      <Container>
        <Background>
          <Heading>{mainHeading}</Heading>
          <Text>{mainText}</Text>

          <Subsections>
            {subsections?.map(
              ({ heading, text }: SubsectionValues, index: number) =>
                heading || text ? (
                  <Subsection key={`${heading}_${text}`}>
                    <SubsectionIcon>
                      {section[`icon${index + 1}Url`] ? (
                        <img
                          src={section[`icon${index + 1}Url`]}
                          alt=""
                          style={{ maxWidth: '36px' }}
                        />
                      ) : null}
                    </SubsectionIcon>
                    <SubsectionText>
                      <SubHeading>{heading}</SubHeading>
                      <SubText>{text}</SubText>
                    </SubsectionText>
                  </Subsection>
                ) : null,
            )}
          </Subsections>
        </Background>
      </Container>
    )
  }
  return <Box>Sections7</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Background = styled.div`
  background: #ebecec;
  padding: 36px;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 6px;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_300Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 72px;
  word-wrap: break-word;
`

const Subsections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`

const Subsection = styled.div`
  width: calc(33% - 12px);
  display: flex;
  flex-direction: row;
`
const SubsectionIcon = styled.div`
  width: 20%;
  text-align: center;
`
const SubsectionText = styled.div`
  width: 80%;
`

const SubHeading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.005em;
  color: #000000;
  word-wrap: break-word;
`

const SubText = styled.div`
  font-style: normal;
  font-family: 'Poppins_400Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  word-wrap: break-word;
`
