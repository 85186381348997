import COLORS from 'constants/colors'
import { Platform } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

// global style config to use in styles
export const GLOBAL_STYLE = {
  $fontLarge: '1.5rem',
  $fontMediumLarge: '1.4rem',
  $fontSizeFormTitle: '1.1rem',
  $fontMedium: '1rem',
  $fontMediumSmall: '0.8rem',
  $fontSmall: '0.7rem',
  $blue: COLORS.BLUE,
  $reddark: COLORS.REDDARK,
  $yellowdark: COLORS.YELLOWDARK,
  $orange: COLORS.ORANGE,
  $green: COLORS.GREEN,
  $white: COLORS.WHITE,
  $graylight1: COLORS.GRAYLIGHT2,
  $graylight2: COLORS.GRAYLIGHT3,
  $graymedium: COLORS.GRAYMEDIUM,
  $graydark1: COLORS.GRAYDARK1,
  $graydark2: COLORS.GRAYDARK2,
}

EStyleSheet.build(GLOBAL_STYLE)
export const STYLE_CONSTANTS = {
  FLEX_ROW: {
    flexDirection: 'row',
  },
  FLEX_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  FLEX_CENTER_VERT: {
    alignItems: 'center',
  },
  FLEX_CENTER_HORIZ: {
    justifyContent: 'center',
  },
  FILL: {
    flex: 1,
  },
  ABSOLUTE: {
    position: 'absolute',
  },
  FORM_BOX: {
    borderWidth: 1,
    borderColor: COLORS.borderForm,
    borderRadius: 5,
  },
  FORM_TEXT: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.borderForm,
  },
  TEXT_INPUT: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.borderForm,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
  },
  NO_PADDING: {
    padding: 0,
  },
  ICON_SIZE_DEFAULT: 35,
  MAIN_CONTAINER: {
    ...(Platform.OS === 'web' ? { maxWidth: 600 } : {}),
    width: '100%',
    alignSelf: 'center',
    borderLeftColor: '#E6EAF1',
    borderLeftWidth: 1,
    borderRightColor: '#E6EAF1',
    borderRightWidth: 1,
  },
}

export const stylesCreator = {
  marginRight: (amount: number) =>
    EStyleSheet.create({
      marginRight: amount,
    }),
}

export const stylesGlobal = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  centeredVertically: {
    alignItems: 'center',
  },
  fontMedium: {
    fontSize: '$fontMedium',
  },
  centerHorizontally: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  toastError: {
    backgroundColor: COLORS.REDDARK,
  },
  toastErrorText: {
    backgroundColor: COLORS.WHITE,
  },
  modal: {
    ...(Platform.OS === 'web'
      ? {
          alignSelf: 'center',
          maxWidth: 600,
          width: '100%',
        }
      : {}),
  },
  modalContent: {
    borderRadius: 12,
    backgroundColor: COLORS.WHITE,
    paddingHorizontal: 32,
    paddingVertical: 40,
    ...(Platform.OS === 'web'
      ? {
          maxWidth: 600,
          width: '100%',
        }
      : {}),
  },
  modalCloseIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  modalContentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  modalContentHeaderText: {
    color: COLORS.NAVY_BLUE,
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 27,
  },
  modalButtonsContainer: {
    alignItems: 'center',
    flex: 1,
    marginTop: 32,
  },
})
