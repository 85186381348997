import React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

import { useQuery } from '@apollo/react-hooks'
import { CLIENT_CONFIGURATION } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'

const Background = (props: { children: {} }) => {
  const { data: clientConfiguration } = useQuery(CLIENT_CONFIGURATION, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })
  const clientBackgroundImage =
    clientConfiguration?.configuration[0]?.backgroundUrl

  if (!!clientBackgroundImage) {
    return (
      <ImageBackground
        source={clientBackgroundImage}
        resizeMode="cover"
        style={styles.background}
      >
        {props.children}
      </ImageBackground>
    )
  }

  return <View style={styles.background}>{props.children}</View>
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
  },
})

export default Background
