import { Animated, Dimensions } from 'react-native'

const spinValue = new Animated.Value(0)

// First set up animation
Animated.timing(spinValue, {
  toValue: 1,
  duration: 3000,
  useNativeDriver: true,
}).start()

// Second interpolate beginning and end values (in this case 0 and 1)
export const spin = spinValue.interpolate({
  inputRange: [0, 1],
  // @ts-ignore
  outputRange: ['0deg', '360deg'],
})

const getScreenHeight = () => Math.round(Dimensions.get('window').height)
const getScreenWidth = () => Math.round(Dimensions.get('window').width)

export const percentScreenHeight = (percent: number) => {
  const screenHeight = getScreenHeight()
  return (percent / 100) * screenHeight
}

export const percentScreenWidth = (percent: number) => {
  const screenWidth = getScreenWidth()
  return (percent / 100) * screenWidth
}