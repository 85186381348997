import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const Signature = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 30 36" {...props}>
    <Rect width={30} height={36} rx={4} fill="#50CEA2"/>
    <Path
      d="M5 31h20M16 13l4 4M11 26l-5 1 1-5 12-12 4 4-12 12z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default Signature
