import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function TangeloIcon(props: SvgProps) {
  return (
    <Svg width={16} height={16} {...props}>
      <Path
        d="M12.084 5.636l.57.583 1.138-1.167-.569-.583-1.139 1.167zM7.654 14.025c-.331 0-.663-.129-.915-.387l-4.75-4.862a1.33 1.33 0 01-.378-.937c0-.355.134-.687.378-.938l4.75-4.863c.253-.258.584-.386.915-.386V.002c-.776 0-1.505.31-2.054.871L.848 5.737A3.027 3.027 0 00.85 9.94l4.75 4.863c.566.58 1.31.87 2.054.87v-1.649z"
        fill="#50CEA2"
      />
      <Path
        d="M14.46 5.74l-2.198-2.263-1.138 1.166 2.196 2.262a1.35 1.35 0 010 1.874l-4.751 4.863a1.275 1.275 0 01-1.83 0l-4.75-4.863a1.33 1.33 0 01-.378-.937c0-.354.134-.687.378-.937l4.75-4.863a1.274 1.274 0 011.83 0l1.235 1.265 1.14-1.166L9.708.877a2.851 2.851 0 00-2.054-.87c-.776 0-1.505.308-2.054.87L.848 5.74A3.027 3.027 0 00.85 9.944l4.75 4.864a2.86 2.86 0 004.108 0l4.751-4.863a3.028 3.028 0 000-4.206z"
        fill="#50CEA2"
      />
      <Path
        d="M14.913 2.052a.925.925 0 00-1.332.001L7.738 8.034l-2-2.048a.927.927 0 00-1.332 0 .982.982 0 000 1.363l2.666 2.73a.927.927 0 001.331 0l.004-.005 6.505-6.659a.98.98 0 00.001-1.363z"
        fill="#50BD97"
      />
      <Path
        d="M7.728 8.044L5.734 6.005a.926.926 0 00-1.328 0 .976.976 0 000 1.358l2.658 2.718c.183.187.424.28.664.28V8.044z"
        fill="#50CEA2"
      />
    </Svg>
  )
}

export default TangeloIcon
