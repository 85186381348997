import React, { useState, ChangeEvent, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'

import { debounce } from 'utils/helpers/debounce'
import { ISODayString } from 'utils/date'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import { useAppContext } from 'utils/app-context'
import COLORS from 'constants/colors'
import { FETCH_POLICIES } from 'constants/api'
import { EVENT_TYPES } from 'constants/types'

import { TASK_TEXT_QUERY } from 'graphql/queries'
import { useTranslation } from 'react-i18next'

type Props = {
  uniqueId: string
  taskId: string
  sessionUuid: string
  locationHierarchyId: string
}

const TaskNotes = ({
  uniqueId,
  taskId,
  sessionUuid,
  locationHierarchyId,
}: Props) => {
  const [text, setText] = useState('')
  const { t } = useTranslation()

  const appContextValue = useAppContext()
  const dayISO = ISODayString(appContextValue.selectedDay)
  const createEvent = useCreateEvent({
    sessionUuid,
    taskId,
    locationHierarchyId,
  })

  const { data: taskTextData } = useQuery(TASK_TEXT_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables: { taskId, date: dayISO },
  })

  const textComplete = (text: string) => {
    createEvent({ eventType: EVENT_TYPES.TASK_TEXT, createEventData: { text } })
  }

  const debouncedSave = useCallback(
    debounce((nextValue) => textComplete(nextValue), 2000),
    [],
  )

  const onChangeText = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setText(value)
    debouncedSave(value)
  }

  useEffect(() => {
    if (!taskTextData) {
      return
    }
    const { taskText } = taskTextData
    setText(taskText[0]?.text)
  }, [taskTextData])

  return (
    <StyledNotes>
      <div className="notes-title">{t('notes')}</div>
      <div className="notes-subtitle">{t('taskNotesDescription')}</div>
      <textarea
        placeholder={t('typeHereToAddText')}
        onChange={onChangeText}
        value={text}
      />
    </StyledNotes>
  )
}

const StyledNotes = styled.div`
  padding: 0px 30px 0px 45px;
  width: 100%;
  box-sizing: border-box;
  .notes-title {
    color: ${COLORS.NAVY_BLUE};
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .notes-subtitle {
    color: ${COLORS.SIXES_GREY};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 12px;
  }
  textarea {
    outline: 0;
    resize: none;
    border: 2px solid ${COLORS.POWDER_BLUE};
    padding: 20px;
    height: 110px;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    border-radius: 6px;
  }
  textarea::placeholder {
    color: ${COLORS.MIDDLE_GREY};
  }
`

export default TaskNotes
