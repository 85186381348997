import React, { useEffect, useCallback } from 'react'
import { Animated, Easing } from 'react-native'

// component that spins its children infitiviely
const SpinningView = ({
  children,
  style: styleRaw = {},
  reverse,
  ...props
}: SpinningViewPropsType) => {
  const spinValue = new Animated.Value(0)

  // spin animation
  const spin = useCallback(() => {
    spinValue.setValue(0)
    // First set up animation
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(spin)
  }, [])

  // start spinning on mount
  useEffect(spin, [])

  // translation effect in style
  const rotate = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', reverse ? '-360deg' : '360deg'],
  })

  return (
    <Animated.View style={[{ transform: [{ rotate }] }, styleRaw]} {...props}>
      {children}
    </Animated.View>
  )
}

export default SpinningView
