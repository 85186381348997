import FileSystem, {
  moveAsync,
  deleteAsync,
  writeAsStringAsync,
} from 'expo-file-system'
import { Dimensions } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CacheKeysUnion } from 'constants/types'
import { doDynamicUpdate } from './calculations'
import { SUPPORTED_IMAGE_TYPES, SUPPORTED_VIDEO_TYPES } from 'constants/static'
import { captureException } from 'utils/sentry'

// deletes a file
export const deleteFile = (uri: string) => {
  try {
    return deleteAsync(uri)
  } catch (error) {
    captureException(error)
    // tslint:disable-next-line no-console
    console.log(error)
  }
}

// write a file
export const writeImageFile = (uri: string, content: string) => {
  try {
    return writeAsStringAsync(uri, content, {
      encoding: FileSystem.EncodingType.Base64,
    })
  } catch (error) {
    captureException(error)
    // tslint:disable-next-line no-console
    console.log(error)
  }
}

// write a file
export const moveFile = (from: string, to: string) => {
  try {
    return moveAsync({ from, to })
  } catch (error) {
    captureException(error)
    // tslint:disable-next-line no-console
    console.log(error)
  }
}

// read from local cache
export const readAsyncCache = async (cacheKey: CacheKeysUnion) => {
  const readout = await AsyncStorage.getItem(cacheKey)
  let parsedReadout = readout
  try {
    parsedReadout = JSON.parse(readout)
    return parsedReadout
  } catch (error) {
    captureException(error)
    return ''
  }
}

// write to local cache
export const writeAsyncCache = async (
  cacheKey: CacheKeysUnion,
  data: any,
  merge?: boolean,
) => {
  const oldValue = await readAsyncCache(cacheKey)
  const newValue = JSON.stringify(doDynamicUpdate(oldValue, data, merge))
  return AsyncStorage.setItem(cacheKey, newValue)
}

export const checkIsLocalFile = (uri: string | undefined) =>
  uri?.indexOf('file://') === 0

export const DEVICE_WIDTH = Math.round(Dimensions.get('window').width)

const getExtension = (fileUrl: string) => fileUrl.split('.').pop().toLowerCase()

export const isSupportedImage = (fileUrl: string) =>
  Object.values(SUPPORTED_IMAGE_TYPES).includes(getExtension(fileUrl))

export const isSupportedVideo = (fileUrl: string) =>
  Object.values(SUPPORTED_VIDEO_TYPES).includes(getExtension(fileUrl))
