import React, { useState, useCallback } from 'react'
import {
  View,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  TouchableOpacity,
  Text,
} from 'react-native'
import { useHistory } from 'react-router-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useMutation, useQuery } from '@apollo/react-hooks'

import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import { UPDATE_USER_PASSWORD } from 'graphql/mutations'
import { GET_CURRENT_USER } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { ROUTE_NAMES } from 'navigation/constants'

enum PasswordField {
  password = 'password',
  repeatPassword = 'repeatPassword',
}

const ResetPasswordScreen = () => {
  const history = useHistory()
  const [{ password, repeatPassword }, setPasswordValues] = useState({
    password: '',
    repeatPassword: '',
  })
  const [showPasswordsMustMatchError, setShowPasswordsMustMatchError] =
    useState(false)

  const [updatePassword, { loading: isUpdatingPassword }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      onCompleted: () => {
        history.push(ROUTE_NAMES.LOCATIONS)
      },
    },
  )

  const { data: currentUserData, loading: loadingCurrentUser } = useQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    },
  )

  const onChangeText = (field: PasswordField, text: string) => {
    setShowPasswordsMustMatchError(false)
    setPasswordValues((preValues) => ({ ...preValues, [field]: text }))
  }

  const handleSubmit = useCallback(() => {
    if (password !== repeatPassword) {
      setShowPasswordsMustMatchError(true)
      return
    }

    updatePassword({
      variables: {
        id: currentUserData?.user.id,
        attributes: {
          password,
        },
      },
    })
  }, [password, repeatPassword])

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
    >
      <View style={styles.formWrapper}>
        {isUpdatingPassword || loadingCurrentUser ? (
          <GeneralActivityIndicator size="large" />
        ) : (
          <>
            <TextInput
              style={styles.textInput}
              placeholder="New password"
              onChangeText={(newValue) =>
                onChangeText(PasswordField.password, newValue)
              }
              value={password}
              secureTextEntry
            />
            <TextInput
              style={styles.textInput}
              placeholder="Repeat password"
              onChangeText={(newValue) =>
                onChangeText(PasswordField.repeatPassword, newValue)
              }
              value={repeatPassword}
              secureTextEntry
            />
            {showPasswordsMustMatchError && (
              <Text style={styles.error}>Passwords must match</Text>
            )}
            <TouchableOpacity
              style={[
                styles.submitButton,
                !password || !repeatPassword ? styles.disabled : {},
              ]}
              onPress={handleSubmit}
              activeOpacity={0.8}
              disabled={!password || !repeatPassword}
            >
              <Text style={styles.submitButtonText}>Reset password</Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  submitButton: {
    marginTop: 30,
    width: 220,
    backgroundColor: COLORS.TURQUOISE,
    padding: 10,
    borderRadius: 4,
  },
  submitButtonText: {
    textAlign: 'center',
    color: 'white',
  },
  textInput: {
    padding: 10,
    borderRadius: 4,
    borderColor: COLORS.TURQUOISE,
    borderWidth: 1,
    width: 220,
    marginBottom: 14,
  },
  disabled: {
    backgroundColor: COLORS.TURQUOISE_DISABLED,
  },
  error: {
    color: COLORS.RED_TRANSPARENT_75,
  },
})

export default ResetPasswordScreen
