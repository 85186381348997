import React from 'react'
import { Button } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'

import Icon from 'components/common/Icon'
import COLORS from 'constants/colors'
import { IconNamesUnion } from 'constants/icons'

type BorderedButtonProps = {
  onPress: (p: any) => void
  title?: string
  iconName?: IconNamesUnion
  color?: string
  backgroundColor?: string
}

const BorderedButton = ({
  onPress,
  iconName,
  title,
  color = COLORS.BLACK,
  backgroundColor = COLORS.WHITE,
  ...rest
}: BorderedButtonProps) => {
  return (
    <Button
      onPress={onPress}
      buttonStyle={[
        styles.buttonStyles,
        { backgroundColor, borderColor: color },
      ]}
      titleStyle={{ color }}
      icon={
        iconName && (
          <Icon
            name={iconName}
            style={{ marginLeft: title ? 10 : 0 }}
            size={20}
            color={color}
          />
        )
      }
      title={title}
      iconRight
    />
  )
}

const styles = EStyleSheet.create({
  buttonStyles: {
    borderWidth: 1,
    width: 'auto',
    borderRadius: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 3,
    paddingBottom: 3,
  },
})

export default BorderedButton
