import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { View, TouchableOpacity, Text } from 'react-native'
import { useNetworkStatus } from 'react-offix-hooks'
import EStyleSheet from 'react-native-extended-stylesheet'
import { CREATE_DOCUSIGN_ENVELOPE } from 'graphql/mutations'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import SignatureModal from 'components/task-complete/SignatureModal'
import { STYLE_CONSTANTS } from 'constants/es-style'
import { checkIsLocalFile } from 'utils/phone'
import MESSAGES from 'constants/messages'
import completeButtonStyles from './constants/completeButtonStyles'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import useCreateEvent from './hooks/useCreateEvent'
import DocuSignButton from 'components/task-list/buttons/DocuSignButton'
import CompleteButton from 'components/task-list/buttons/CompleteButton'
import { EventTypesUnion, EVENT_TYPES } from 'constants/types'
import {
  DocuSignIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'

// photo icon to take picture for task completition
const TaskDocuSignToComplete = (props: CompleteElementProps) => {
  const online = useNetworkStatus()
  const {
    sessionUuid,
    id: taskId,
    locationHierarchyId,
    closeModal,
    completedEvent,
  } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    setCompleteState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

   
  const [showCompleteButton, setShowCompleteButton] = useState<boolean | null>(completeState)
  const [docusignUrlLoading, setDocusignUrlLoading] = useState<boolean | null>(false)
  const [docusignRequestFailed, setDocusignRequestFailed] = useState<boolean | null>(false)

  const imageUrl = props?.completedEvent?.imageUrl
  const isLocalImage = checkIsLocalFile(imageUrl)

  const [getDocusignEnvelopeUrl] = useMutation(CREATE_DOCUSIGN_ENVELOPE, {
    variables: {
      attributes: {
        taskId,
        returnEndpoint: 'LOCATIONS?signedTaskId=' + taskId,
        sessionUuid 
      },
    },
    onCompleted: (response) => {
      setDocusignUrlLoading(false)
      if (response.createDocusignEnvelope.url) {
        window.open(response.createDocusignEnvelope.url, '_blank')
        setShowCompleteButton(true)
      } else {
        setDocusignRequestFailed(true)
      }
    },
    onError: (err) => {
      setDocusignUrlLoading(false)
      setDocusignRequestFailed(true)
      setShowCompleteButton(false)
      console.error(err)
    },
  })

  const onCompleteChange = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
    } else {
      const value = !completeState
      const eventType = value
        ? EVENT_TYPES.COMPLETE_TASK
        : EVENT_TYPES.UNCOMPLETE_TASK
      setCompleteState(value)
      toggleComplete(eventType)

      if (props.showAsButton) {
        props.closeModal()
      }
    }
  }
  
  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
    sessionUuid,
  })

  const toggleComplete = (eventType: EventTypesUnion) => {
    createEvent({ eventType, createEventData: { eventType } })
  }

  const openDocusignSign = async () => {
    if (docusignUrlLoading) {
      return
    }

    setDocusignUrlLoading(true)
    setDocusignRequestFailed(false)
    await getDocusignEnvelopeUrl({})
  }
  
  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!completeState) {
      return (
        <DocuSignIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <DocuSignIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  const renderSignButton = () => {
    if (!props.showAsButton) {
      return (
        <View style={styles.container}>
          {!!imageUrl?.length && (
            <View style={styles.imageModalThumbnailWrapper}>
              {(online || isLocalImage) && (
                <ImageModal
                  swipeToDismiss={false}
                  resizeMode="contain"
                  imageBackgroundColor={COLORS.TRANSPARENT}
                  style={styles.imageModalThumbnail}
                  source={{ uri: imageUrl }}
                />
              )}
              <ImageVisibleWhenOnline {...{ online, isLocalImage }} />
            </View>
          )}
          <TouchableOpacity
            onPress={onCompleteChange}
            style={styles.iconContainer}
          >
            {renderIcon()}
          </TouchableOpacity>
        </View>
      )
    } else if (showCompleteButton) {
      return <DocuSignButton selected={taskDone} onPress={openDocusignSign} />
    } else {
      return <DocuSignButton onPress={openDocusignSign} />
    }
  }

  return (
    <>
    {renderSignButton()}
    </>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
  imageModalThumbnailWrapper: {
    ...STYLE_CONSTANTS.FLEX_CENTER_HORIZ,
  },
  offlineImageTextWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  offlineImageText: {
    fontSize: 8,
  },
  imageModalThumbnail: {
    width: 30,
    height: 40,
  },
  image: {
    width: 30,
    height: 40,
    marginRight: 5,
  },
})

export default TaskDocuSignToComplete
