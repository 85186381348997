import { format } from 'date-fns'
import { enUS, fr, frCA, pt, de, es, ja, zhCN, zhTW } from 'date-fns/locale'

const locales: { [key: string]: Locale } = {
  'en-US': enUS,
  fr: fr,
  'fr-CA': frCA,
  'pt-BR': pt,
  'de-DE': de,
  'es-ES': es,
  'ja-JP': ja,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
}

// To avoid bloating the build with all the locales by default date-fns forces
// the developer to manually require locales when needed
export default function (date: Date, formatStr: string, locale = 'en-US') {
  return format(date, formatStr, {
    locale: locales[locale],
  })
}
