import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Platform } from 'react-native'
import { useLocation } from 'react-router-native'
import { useMutation } from '@apollo/react-hooks'
import { makeRedirectUri, startAsync } from 'expo-auth-session'
import { parse } from 'query-string'

import {
  LOGIN_WITH_WORKOS_TOKEN,
  LOGIN_WITH_WORKOS_REDIRECT_URL,
  LOGIN_WITH_WORKOS_MAGIC_LINK,
} from 'graphql1/mutations'

interface Params {
  setUseSSOSignIn: DispatchType<boolean>
  setLoginError: DispatchType<string>
  setShowLoginForm: DispatchType<boolean>
  setMagicLinkSent: DispatchType<boolean>
  checkTokenAndNavigate: (
    token: string,
    isTemporaryPassword?: boolean,
  ) => Promise<void>
}

const useProxy = Platform.select({ web: false, default: true })

const useWorkOSLogin = ({
  setInfoMessage,
  setUseSSOSignIn,
  setLoginError,
  setShowLoginForm,
  setMagicLinkSent,
  checkTokenAndNavigate,
}: Params) => {
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [ssoToken] = useState(() => parse(location.search)['code'] as string)

  const [getSSORedirectUrl] = useMutation(LOGIN_WITH_WORKOS_REDIRECT_URL, {
    onError: (err) => {
      setUseSSOSignIn(false)
      console.error(err)
    },
  })

  const [workosTokenLogin] = useMutation(LOGIN_WITH_WORKOS_TOKEN, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        if (err.graphQLErrors[0]?.message === 'User not found') {
          let userNotFoundMessage = 'Hello and welcome to Tangelo.\n\nYou haven\'t been assigned any workflow. Please contact your HR/admin if you think this is a mistake.'
          if (err.graphQLErrors[0].extensions?.customMessage) {
            userNotFoundMessage = err.graphQLErrors[0].extensions?.customMessage
          }
          setInfoMessage(userNotFoundMessage)
        } else {
          setLoginError(err.graphQLErrors[0].message)
        }
      }
      setShowLoginForm(true)
      setMagicLinkSent(false)
    },
  })

  const [sendWorkOSMagicLink] = useMutation(LOGIN_WITH_WORKOS_MAGIC_LINK, {
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setLoginError(err.graphQLErrors[0].message)
        setMagicLinkSent(false)
      }
    },
    onCompleted: (data) => {
      if (data?.workosMagicLink?.email === email) {
        setMagicLinkSent(true)
      }
    },
  })

  const validateSSOTokenAndNavigate = async (token: string) => {
    const workosTokenLoginResponse = await workosTokenLogin({
      variables: { attributes: { token } },
    })

    if (!workosTokenLoginResponse) {
      return
    }

    const { data: result } = workosTokenLoginResponse
    const authenticationToken = result?.workosTokenLogin?.authenticationToken
    checkTokenAndNavigate(authenticationToken)
  }

  useEffect(() => {
    if (!ssoToken) {
      return
    }
    validateSSOTokenAndNavigate(ssoToken)
  }, [ssoToken])

  const handleWorkOSflow = async (variables: { attributes: any }) => {
    const redirectUrlResponse = await getSSORedirectUrl({ variables })

    const workosAuthUrl =
      redirectUrlResponse?.data?.workosRedirectUrl?.redirectUrl

    if (!workosAuthUrl) {
      return false
    }

    // redirect website tab
    if (Platform.OS === 'web') {
      window.location = workosAuthUrl
      return true
    }

    const returnUrl = makeRedirectUri({
      useProxy,
    })

    const ssoResponse = await startAsync({
      authUrl: workosAuthUrl,
      returnUrl,
    })

    if (ssoResponse.type === 'success') {
      validateSSOTokenAndNavigate(ssoResponse.params.code)
    }

    return true
  }

  const handleSendWorkOSMagicLink = (variables: { attributes: { email: string } }) => {
    setEmail(variables.attributes.email)
    return sendWorkOSMagicLink({ variables })
  }

  return {
    handleWorkOSflow,
    handleSendWorkOSMagicLink,
  }
}

export default useWorkOSLogin
