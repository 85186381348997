import { ApolloProvider } from '@apollo/react-hooks'
import ErrorBoundary from 'components/common/ErrorBoundary'
import NavigatorScreens from 'components/navigator/NavigatorScreens'
import { GLOBAL_STYLE } from 'constants/es-style'
import {
  ENVIRONMENT,
  FULLSTORY_ORG_ID,
  CROSS_DOMAIN_STORAGE_URL,
  IS_WEB,
} from 'constants/static'
import { FETCH_POLICIES } from 'constants/api'
import { CACHE_KEYS } from 'constants/types'
import { GET_CLIENT_CONFIG, GET_CURRENT_USER } from 'graphql/queries'
import AppLoading from 'expo-app-loading'
import * as SplashScreen from 'expo-splash-screen'
import { HeaderContext } from 'utils/header-context'
import {
  useFonts,
  Poppins_100Thin,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
} from '@expo-google-fonts/poppins'
import {
  SourceSansPro_300Light,
  SourceSansPro_400Regular,
  SourceSansPro_600SemiBold,
} from '@expo-google-fonts/source-sans-pro'
import { ApolloOfflineClient } from 'offix-client'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import EStyleSheet from 'react-native-extended-stylesheet'
import 'react-native-gesture-handler'
import { ApolloOfflineProvider } from 'react-offix-hooks'
import * as Sentry from 'sentry-expo'
import client from 'utils/init'
import CrossDomainStorage from 'components/cross-domain-storage/CrossDomainStorage'
import { captureException } from 'utils/sentry'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import { Platform } from 'react-native'
import FullStory, { identify } from 'react-fullstory'
import { useQuery } from '@apollo/react-hooks'
import { parse } from 'query-string'
import { Provider as PaperProvider } from 'react-native-paper'
import TangeloTheme from 'theme/TangeloTheme'
import i18n from 'i18n/setup'
import AsyncStorage from '@react-native-async-storage/async-storage'

// create navigator for root pages
EStyleSheet.build(GLOBAL_STYLE)

Sentry.init({
  dsn: 'https://9639a285794a4e3593b9f5ad6b176f23@o425962.ingest.sentry.io/5453433',
  enableInExpoDevelopment: true,
  environment: ENVIRONMENT || 'development',
  debug: ENVIRONMENT === 'development', // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
})

// initial effect
const getSetLoadingComplete = (
  setLoadingComplete: DispatchType<boolean>,
  setApolloClient: DispatchType<ApolloOfflineClient>,
) =>
  async function loadResourcesAndDataAsync() {
    try {
      await SplashScreen.preventAutoHideAsync()
      // init apollo client
      setApolloClient(client)
      await client.init()
    } catch (e) {
      captureException(e)
    } finally {
      setLoadingComplete(true)
      await SplashScreen.hideAsync()
    }
  }

// main component
const App = () => {
  const crossDomainIFrame = useRef<HTMLIFrameElement>(null)

  const [isLoadingComplete, setLoadingComplete] = useState(false)
  const [apolloClient, setApolloClient] = useState(null)
  const [enableSessionTracking, setEnableSessionTracking] = useState(false)
  const [crossDomainIFrameLoaded, setCrossDomainIFrameLoaded] = useState(false)
  const [options, setOptions] = useState({
    title: '',
    headerRight: () => {},
    showBack: false,
    hideHeader: false,
  })
  const [fontsLoaded] = useFonts({
    Poppins_100Thin,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    SourceSansPro_300Light,
    SourceSansPro_400Regular,
    SourceSansPro_600SemiBold,
  })

  // close window after succesful signature on DocuSign
  useEffect(() => {
    if (!window?.location?.search) {
      return
    }
    const event = parse(window.location.search)['event']
    if (event === 'signing_complete') {
      window.close()
    }
  }, [window?.location?.search])

  const saveCrossDomainToken = (value: string) => {
    if (Platform.OS !== 'web' || !crossDomainIFrameLoaded) {
      return
    }

    crossDomainIFrame!.current!.contentWindow!.postMessage(
      {
        action: 'save',
        key: CACHE_KEYS.CROSS_DOMAIN_TOKEN,
        value,
      },
      CROSS_DOMAIN_STORAGE_URL || '*',
    )
  }

  const { data: clientConfigData } = useQuery(GET_CLIENT_CONFIG, {
    client: client,
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })
  const { data: currentUserData } = useQuery<{
    user: User
  }>(GET_CURRENT_USER, {
    client: client,
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })

  useEffect(() => {
    if (!clientConfigData) {
      return
    }
    setEnableSessionTracking(
      clientConfigData?.clientConfig?.enableSessionTracking,
    )
  }, [clientConfigData])

  useEffect(() => {
    if (enableSessionTracking && currentUserData?.user) {
      const { user } = currentUserData
      identify(user.id, {
        displayName: [user.firstName, user.lastName].join(' '),
        email: user.email,
        clientId: user.clientId,
        environment: ENVIRONMENT,
      })
    }
  }, [enableSessionTracking, currentUserData])

  useEffect(() => {
    const showLocale = clientConfigData?.clientConfig?.showLocale
    const preferredLocale = currentUserData?.user?.preferredLocale

    if (IS_WEB && showLocale && preferredLocale) {
      i18n.changeLanguage(preferredLocale)
      AsyncStorage.setItem(CACHE_KEYS.SELECTED_LANGUAGE, preferredLocale)
    }
  }, [currentUserData, clientConfigData])

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    getSetLoadingComplete(setLoadingComplete, setApolloClient)()
  }, [])

  if (!isLoadingComplete && !fontsLoaded) {
    return <AppLoading />
  }

  return (
    <Fragment>
      <CrossDomainStorage
        setCrossDomainIFrameLoaded={setCrossDomainIFrameLoaded}
        ref={crossDomainIFrame}
      />
      {!apolloClient && <AppLoading />}
      {apolloClient && (!CROSS_DOMAIN_STORAGE_URL || crossDomainIFrameLoaded) && (
        <ApolloOfflineProvider client={apolloClient}>
          <ApolloProvider client={apolloClient}>
            <HeaderContext.Provider
              value={{ options, setOptions, saveCrossDomainToken }}
            >
              <ErrorBoundary>
                <PaperProvider theme={TangeloTheme}>
                  <NavigatorScreens />
                </PaperProvider>
              </ErrorBoundary>
            </HeaderContext.Provider>
          </ApolloProvider>
          {IS_WEB && enableSessionTracking && FULLSTORY_ORG_ID && (
            <FullStory org={FULLSTORY_ORG_ID} />
          )}
        </ApolloOfflineProvider>
      )}
    </Fragment>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

export default App
