import React from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-native'
import { useHeaderContext } from 'utils/header-context'
import { HouseIcon } from 'components/common/custom-icons'
import COLORS from 'constants/colors'
import { ROUTE_NAMES } from 'navigation/constants'
import { Ionicons } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = ({
  showLocationName = true,
  currentUser,
}: {
  showLocationName?: boolean
  currentUser: User
}) => {
  const { options } = useHeaderContext()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const owner = location.state?.owner

  if (!!options.title && options.title[0] === 'Home') {
    return <></>
  }

  const renderBreadCrumbs = () => {
    return options.title.map((title, index) => {
      if (index == options.title.length - 1) {
        return <span>{title}</span>
      } else {
        return (
          <span>
            <div className="link-text" onClick={() => history.goBack()}>
              {title}
            </div>
            <span>{' > '}</span>
          </span>
        )
      }
    })
  }

  return (
    <StyledBreadcrumbs showLocationName={showLocationName}>
      <div className="home">
        <HouseIcon width={16} height={16} style={{ marginRight: 8 }} />
        <a href="#" onClick={() => history.push(ROUTE_NAMES.LOCATIONS)}>
          {t('home')}
        </a>
      </div>
      {showLocationName && owner && (
        <div className="tasks">
          {t('tasksFor')}{' '}
          <span className="employee-name">{owner.fullName}</span>
        </div>
      )}
      {showLocationName ? (
        <div className="bread-crumbs">
          {options.title && renderBreadCrumbs()}
        </div>
      ) : (
        <Ionicons
          name="arrow-back"
          size={24}
          color="black"
          onPress={() => history.goBack()}
        />
      )}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled.div<{
  showLocationName: boolean
}>`
  margin-left: ${(props) => (props.showLocationName ? '24px' : '0px')};
  margin-right: ${(props) => (props.showLocationName ? '24px' : '0px')};
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 18px;
  color: ${COLORS.NAVY_BLUE};

  .home {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .link-text {
    text-decoration: underline;
    color: ${COLORS.DARK_TURQUOISE};
    cursor: pointer;
  }

  .employee-name {
    font-weight: 600;
  }

  .bread-crumbs {
    overflow: hidden;
  }
`

export default Breadcrumbs
