import React, { FC } from 'react'
import styled from 'styled-components'

import { Box } from './Box'

import LockIcon from '../LockIcon'
import { SectionViewComponentProps } from '../PageSection'

interface SubsectionValues {
  heading: string
  text: string
  more: string
  protectedLink?: boolean
}

export const Columns1: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { subsections } = content
    return (
      <Container>
        {subsections?.map(
          ({ heading, text, more, protectedLink }: SubsectionValues) =>
            heading || text || more ? (
              <Section key={`${heading}_${text}_${more}`}>
                <Heading>{heading}</Heading>
                <Text>{text}</Text>
                {more && (
                  <More>
                    <LockIcon locked={protectedLink} />
                    <a href={more} target="_blank">
                      Learn More
                    </a>
                  </More>
                )}
              </Section>
            ) : null,
        )}
      </Container>
    )
  }
  return <Box>Columns1</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Section = styled.div`
  background: #ebecec;
  padding: 10px;
  width: 20%;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #e30613;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_500Medium';
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #333333;
  word-wrap: break-word;
`

const More = styled.div`
  font-style: normal;
  font-family: 'Poppins_500Medium';
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.015em;
  color: #333333;
  word-wrap: break-word;

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
