import React, { FC } from 'react'

import { Box } from './Box'

import LockIcon from '../LockIcon'
import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Header7: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading, more, protectedLink } = content
    return (
      <Container>
        <Heading>{heading}</Heading>
        {more && (
          <More>
            <div>
              <LockIcon locked={protectedLink} />
              <a href={more} target="_blank">
                Learn More
              </a>
            </div>
            <a href={more} target="_blank">
              <img
                src={require('assets/images/png/learnMore28.png')}
                width="28"
                alt=""
              />
            </a>
          </More>
        )}
      </Container>
    )
  }
  return <Box>Header7</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_300Light';
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #333333;
  word-wrap: break-word;
`

const More = styled.div`
  display: flex;
  align-items: center;

  div {
    font-style: normal;
    font-family: 'Poppins_600SemiBold';
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.375px;
    color: #000000;
    word-wrap: break-word;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  img {
    margin-left: 12px;
  }
`
