import React from 'react'
import COLORS from 'constants/colors'
import { View, Text } from 'react-native'
import CustomProgressCircle from 'components/common/CustomProgressCircle'
import EStyleSheet from 'react-native-extended-stylesheet'
import {
  ArrowRightRoundedIcon,
  CheckmarkIcon,
} from 'components/common/custom-icons'
import { IS_WEB } from 'constants/static'

const LocationProgressCircle = ({
  numberOfTasks,
  numberOfCompletedTasks,
}: {
  numberOfTasks: number
  numberOfCompletedTasks: number
}) => {
  const percent =
    numberOfCompletedTasks > 0
      ? Math.round((numberOfCompletedTasks / numberOfTasks) * 100)
      : 0

  const radius = IS_WEB ? 48 : 42

  function renderChild() {
    if (percent == 0) {
      return <ArrowRightRoundedIcon height={24} width={24} />
    }

    return <Text style={{ fontSize: 18 }}>{`${percent}%`}</Text>
  }

  return (
    <View style={styles.progressCircleWrapper}>
      {percent == 100 ? (
        <CheckmarkIcon width={84} height={84} />
      ) : (
        <CustomProgressCircle
          percent={percent}
          radius={radius}
          borderWidth={12}
          color={COLORS.TURQUOISE}
          shadowColor="#f5f7fb"
          bgColor="#fff"
          markerColor={COLORS.TURQUOISE}
        >
          {renderChild()}
        </CustomProgressCircle>
      )}
    </View>
  )
}

const styles = EStyleSheet.create({
  progressCircleWrapper: {
    marginLeft: 12,
    marginRight: 12,
    flexShrink: 0,
  },
})

export default LocationProgressCircle
