import React from 'react'

import COLORS from 'constants/colors'
import styled from 'styled-components'
import {
  OptionArrow,
  OptionIcon,
  OptionOutlineIcon,
} from 'components/common/custom-icons'
import { useTranslation } from 'react-i18next'

const DefaultButton = ({
  selected,
  onPress,
}: {
  selected: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation()

  return (
    <StyledSelectButton selected={selected} onClick={onPress}>
      <div className="button-content">
        {t('pleaseSelect')}
        {!selected && (
          <span className="select-button-default-icon">
            <OptionArrow height={16} width={14} />
          </span>
        )}
        <span className="button-icon">
          {selected ? (
            <OptionIcon height={24} width={24} />
          ) : (
            <OptionOutlineIcon height={24} width={24} />
          )}
        </span>
      </div>
    </StyledSelectButton>
  )
}

const StyledSelectButton = styled.button`
  width: 192px;
  height: 56px;
  background-color: ${({ selected }) =>
    selected ? COLORS.LIGHT_TURQUOISE : 'white'};

  color: ${COLORS.DARK_TURQUOISE};
  font-weight: 600;
  font-size: 16px;

  border: ${({ selected }) => (selected ? '2px' : '1px')} solid
    ${COLORS.TURQUOISE};
  box-sizing: border-box;
  border-radius: 8px;

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .select-button-default-icon {
    display: flex;
    width: 24px;
    margin-left: 10px;
    justify-content: center;
  }

  &:hover .select-button-default-icon {
    display: none;
  }

  .button-icon {
    margin-left: 10px;
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
  }

  &:hover .button-icon {
    display: flex;
  }

  &:hover {
    cursor: pointer;
    border-width: 2px;
  }
`

export default DefaultButton
