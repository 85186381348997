import React from 'react'

import COLORS from 'constants/colors'
import { NopeIcon } from 'components/common/custom-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const CantCompleteButton = ({
  selected = false,
  onPress,
  hover,
}: {
  selected?: boolean
  hover?: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation()

  return (
    <StyledCantCompleteButton
      selected={selected}
      hover={hover}
      onClick={onPress}
    >
      <div className="button-content">
        {!!selected && (
          <span className="button-icon">
            {<NopeIcon height={24} width={24} />}
          </span>
        )}
        {t('cantComplete')}
      </div>
    </StyledCantCompleteButton>
  )
}

const StyledCantCompleteButton = styled.button`
  width: 192px;
  height: 56px;
  background-color: ${({ selected }) =>
    selected ? COLORS.LIGHT_HOT_PINK : 'white'};

  color: ${({ selected, hover }) =>
    selected || hover ? COLORS.HOT_PINK : COLORS.SIXES_GREY};
  font-weight: ${({ selected, hover }) => (selected || hover ? '600' : '400')};
  font-size: 16px;

  border: ${({ selected, hover }) => (selected || hover ? '2px' : '1px')} solid
    ${({ selected, hover }) =>
      selected || hover ? COLORS.HOT_PINK : COLORS.LIGHTER_GREY};
  box-sizing: border-box;
  border-radius: 8px;

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-icon {
    display: flex;
    margin-right: 6px;
  }

  &:hover {
    cursor: pointer;
    border-width: 2px;
    border-color: ${COLORS.HOT_PINK};
    font-weight: 600;
    color: ${COLORS.HOT_PINK};
  }
`

export default CantCompleteButton
