import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const CheckmarkOutlined = (props: SvgProps) => (
  <Svg viewBox="0 0 36 36" {...props}>
    <Circle
      cx={18}
      cy={18}
      r={17}
      fill="#fff"
      stroke="#50CEA2"
      strokeWidth={2}
    />
    <Path
      d="m15.3 19.8-3.6-3.6L9 18.9l6.3 6.3L27 13.5l-2.7-2.7-9 9z"
      fill="#50CEA2"
    />
  </Svg>
)

export default CheckmarkOutlined
