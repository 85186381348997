import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

export default function RightSide() {
  return (
    <View style={styles.container}>
      <img
        src={require('assets/images/png/login_side_image.png')}
        style={{
          height: '100%',
        }}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    maxHeight: 882,
    alignItems: 'start',
    height: '100%',
    width: '100%',
    paddingLeft: 60,
  },
})
