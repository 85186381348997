import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'

function Chart(props: SvgProps) {
  return (
    <Svg width={24} height={24} fill="none" {...props}>
      <G
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M7.37 10.202v6.86M12.039 6.92v10.142M16.628 13.827v3.235" />
        <Path
          clipRule="evenodd"
          d="M16.686 2H7.314C4.048 2 2 4.312 2 7.585v8.83C2 19.688 4.038 22 7.314 22h9.372C19.962 22 22 19.688 22 16.415v-8.83C22 4.312 19.962 2 16.686 2z"
        />
      </G>
    </Svg>
  )
}

export default Chart
