import * as React from 'react'
import Svg, { SvgProps, Rect, Path } from 'react-native-svg'

const Option = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 36" {...props}>
    <Rect width={36} height={36} rx={8} fill="#50CEA2" />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={3}
      d="m12.117 15.859 5.742 5.024M18.141 20.883l5.742-5.024"
    />
  </Svg>
)

export default Option
