import { AccessTimeIcon, PriorityIcon } from 'components/common/custom-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import COLORS from 'constants/colors'

const TaskSubtitle = ({
  estimateInMins,
  important,
}: {
  estimateInMins?: number
  important: boolean
}) => {
  const { t } = useTranslation()

  const EstimateTime = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 12,
      }}
    >
      <AccessTimeIcon
        style={{
          marginRight: 6,
        }}
        width={16}
        height={16}
      />
      <Text
        style={{
          fontSize: 12,
        }}
      >
        {estimateInMins} {t('min')}
      </Text>
    </View>
  )

  const Important = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <PriorityIcon
        width={14}
        height={14}
        style={{
          marginRight: 4,
        }}
      />
      <Text
        style={{
          color: COLORS.SECONDARY,
          fontSize: 12,
        }}
      >
        {t('high')}
      </Text>
    </View>
  )

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {!!estimateInMins && <EstimateTime />}
      {!!important && <Important />}
    </View>
  )
}

export default TaskSubtitle
