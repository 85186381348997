import { useQuery } from '@apollo/react-hooks'
import { NavigationContainer } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  LoginScreen,
  TaskCompleteByPhotoScreen,
  TaskTodayScreen,
  TaskTodayScreenWeb,
  LocationScreenWrapper,
  ResetPasswordScreen,
  // ClientPagesComponent,
  ResourcesScreen,
  ReportsScreen,
} from 'components/Index'
import LocationTextAndPhotos from 'components/locations/LocationTextAndPhotos'
import { ERROR_DATA } from 'graphql1/queries'
import { createBrowserHistory } from 'history'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StatusBar, View } from 'react-native'
import Toast from 'react-native-easy-toast'
import EStyleSheet from 'react-native-extended-stylesheet'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { useApolloOfflineClient } from 'react-offix-hooks'
import { NativeRouter, Redirect, Route, Router } from 'react-router-native'
import { AppContext, initialAppContextValue } from 'utils/app-context'
import { CACHE_KEYS } from 'constants/types'
import { clearErrorData } from 'utils/error-handling'

import Header from './Header'
import ManagerRoute from './ManagerRoute'

const TaskTodayComponent = Platform.select({
  web: TaskTodayScreenWeb,
  default: TaskTodayScreen,
})

const history: any = Platform.OS === 'web' ? createBrowserHistory() : {}
const AppRouter = ({ children }: any) => {
  if (Platform.OS === 'web') {
    return <Router history={history}>{children}</Router>
  }

  return <NativeRouter>{children}</NativeRouter>
}

// build global styles
function NavigatorScreens() {
  const client = useApolloOfflineClient()
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [appContextValue, setAppContextValue] = useState<AppContextValue>(
    initialAppContextValue,
  )

  const toastRef = useRef<Toast>()
  const { data: errorCache } = useQuery(ERROR_DATA)
  const errorData = errorCache?.errorData

  useEffect(() => {
    if (errorData?.status === '401') {
      AsyncStorage.removeItem(CACHE_KEYS.TOKEN).then(() => {
        clearErrorData(client)
        setRedirectToLogin(true)
      })
    } else {
      setRedirectToLogin(false)
    }
  }, [errorData?.status])

  const appContextValueFinal = { ...appContextValue, setAppContextValue }

  return (
    <SafeAreaProvider>
      {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
      <AppContext.Provider value={appContextValueFinal}>
        <NavigationContainer
          documentTitle={{
            formatter: () => {
              return 'team-app'
            },
          }}
        >
          <AppRouter>
            {redirectToLogin && <Redirect to={ROUTE_NAMES.LOGIN} />}
            <View style={{ position: 'relative', height: '100%' }}>
              <View
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                }}
              >
                <View style={styles.container}>
                  {Platform.OS !== 'web' && <Header></Header>}

                  <Route
                    exact
                    path={ROUTE_NAMES.LOGIN}
                    component={LoginScreen}
                  />
                  <Route
                    exact
                    path={ROUTE_NAMES.RESET_PASSWORD}
                    component={ResetPasswordScreen}
                  />
                  <Route
                    exact
                    path={ROUTE_NAMES.TASKS_TODAY}
                    component={TaskTodayComponent}
                  />
                  <Route
                    exact
                    path={ROUTE_NAMES.LOCATIONS}
                    component={LocationScreenWrapper}
                  />
                  <Route
                    exact
                    path={ROUTE_NAMES.CAMERA_COMPLETE_TASK}
                    component={TaskCompleteByPhotoScreen}
                  />
                  <Route
                    exact
                    path={ROUTE_NAMES.LOCATION_TEXT_AND_PHOTOS}
                    component={LocationTextAndPhotos}
                  />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={ROUTE_NAMES.CLIENT_PAGES}*/}
                  {/*  component={ClientPagesComponent}*/}
                  {/*/>*/}
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={ROUTE_NAMES.CLIENT_PAGE}*/}
                  {/*  component={ClientPagesComponent}*/}
                  {/*/>*/}
                  <Route exact path="/" component={LoginScreen} />
                  <Route
                    exact
                    path={ROUTE_NAMES.RESOURCES}
                    component={ResourcesScreen}
                  />
                  <ManagerRoute
                    exact
                    path={ROUTE_NAMES.REPORTS}
                    component={ReportsScreen}
                  />
                </View>
              </View>
            </View>
          </AppRouter>
        </NavigationContainer>
      </AppContext.Provider>
      <Toast ref={toastRef} />
    </SafeAreaProvider>
  )
}

// styles
const styles = EStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: 'relative',
    ...(Platform.OS === 'web'
      ? {
          alignSelf: 'center',
          height: '100%',
        }
      : {}),
    width: '100%',
  },
})

export default NavigatorScreens
