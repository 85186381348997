import React from 'react'
import { TouchableOpacity, View, Text } from 'react-native'
import Modal from 'react-native-modal'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import { stylesGlobal } from 'constants/es-style'
import EStyleSheet from 'react-native-extended-stylesheet'

function InfoModal({
  isVisible,
  onClose,
  message,
}: {
  isVisible: boolean
  message: string
  onClose: () => void
}) {
  return (
    <Modal isVisible={isVisible} style={stylesGlobal.modal}>
      <View style={stylesGlobal.modalContent}>
        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
          <Icon
            name={ICON_NAMES.CLOSE}
            size={30}
            style={stylesGlobal.modalCloseIcon}
            onPress={onClose}
          />
        </TouchableOpacity>
        <View>
          <Text style={styles.infoMessage}>{message}</Text>
        </View>
      </View>
    </Modal>
  )
}

const styles = EStyleSheet.create({
  infoMessage: {
    fontSize: 14,
    textAlign: 'left',
    margin: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 50,
    height: 50,
    zIndex: 1000,
  },
})

export default InfoModal
