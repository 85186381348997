import React, { useState, useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'

import { NopeIcon, NotApplicableIcon } from 'components/common/custom-icons'
import UploadMediaButton from 'components/task-list/buttons/UploadMediaButton'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import useCreateEvent from './hooks/useCreateEvent'
import completeButtonStyles from './constants/completeButtonStyles'
import { EVENT_TYPES } from 'constants/types'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'
import { IS_WEB } from 'constants/static'
import { createImageFile } from './helpers/image-helpers'

const TaskUploadMediaToComplete = (props: CompleteElementProps) => {
  const { sessionUuid, completedEvent, id, locationHierarchyId } = props

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const [isUploading, setIsUploading] = useState(false)

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!completeState) {
      return (
        <Icon
          name={ICON_NAMES.CLOUD_UPLOAD}
          size={ICON_SIZE}
          color={COLORS.TURQUOISE}
          style={{
            opacity: 0.16,
          }}
        />
      )
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return (
      <Icon
        name={ICON_NAMES.CLOUD_UPLOAD}
        size={ICON_SIZE}
        color={COLORS.TURQUOISE}
      />
    )
  }

  const createEvent = useCreateEvent({
    taskId: id,
    locationHierarchyId,
    sessionUuid,
  })

  const pickImage = async () => {
    const imageResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 1,
    })

    if (imageResult.cancelled == false) {
      setIsUploading(true)
      const reactNativeImage = await createImageFile(imageResult)

      await createEvent({
        eventType: EVENT_TYPES.UPLOAD_MEDIA,
        createEventData: {
          media: reactNativeImage,
        },
      })

      setIsUploading(false)
    }
  }

  const pickDocumentWeb = async () => {
    if (!IS_WEB) {
      return
    }
    let documents = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      multiple: true,
    })

    if (documents?.type == 'success') {
      setIsUploading(true)

      await createEvent({
        eventType: EVENT_TYPES.UPLOAD_MEDIA,
        createEventData: {
          media: documents.output,
        },
      })

      setIsUploading(false)
    }
  }

  const handleUploadDocument = useMemo(() => {
    return IS_WEB ? pickDocumentWeb : pickImage
  }, [IS_WEB])

  return !props.showAsButton ? (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={handleUploadDocument}
        style={styles.iconContainer}
        disabled={!IS_WEB}
      >
        {renderIcon()}
      </TouchableOpacity>
    </View>
  ) : (
    <UploadMediaButton
      selected={taskDone}
      onPress={handleUploadDocument}
      loading={isUploading}
      disabled={!IS_WEB}
    />
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskUploadMediaToComplete
