import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-native'
import { ArrowRightIcon } from 'components/common/custom-icons'
import COLORS from 'constants/colors'
import { useTranslation } from 'react-i18next'

const ResourcesHeader = () => {
  const history = useHistory()
  const { t } = useTranslation()

  function goBack() {
    history.goBack()
  }

  return (
    <StyledResourcesHeader>
      <StyledBack onClick={goBack}>
        <StyledBackIcon>
          <ArrowRightIcon height={16} width={16} stroke="black" />
        </StyledBackIcon>
        <span>{t('back')}</span>
      </StyledBack>
      <StyledTitle>{t('resources')}</StyledTitle>
      <StyledSubtitle>{t('resourcesSubtitle')}</StyledSubtitle>
    </StyledResourcesHeader>
  )
}

const StyledResourcesHeader = styled.div`
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.06));
  background: white;
  border-bottom: 1px solid ${COLORS.GRAYDARK3};
  border-top: 1px solid ${COLORS.GRAYDARK3};
  padding: 16px 36px;
`

const StyledBack = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
`

const StyledBackIcon = styled.div`
  display: flex;
  align-self: center;
  transform: rotate(180deg);
  margin-right: 8px;
`

const StyledTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 6px;
`

const StyledSubtitle = styled.div`
  font-size: 22px;
  color: ${COLORS.FIVES_GREY};
`

export default ResourcesHeader
