import { useLazyQuery } from '@apollo/react-hooks'
import AsyncStorage from '@react-native-async-storage/async-storage'
import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'
import { FETCH_POLICIES } from 'constants/api'
import { IS_WEB } from 'constants/static'
import { CACHE_KEYS } from 'constants/types'
import { GET_CURRENT_USER } from 'graphql1/queries'
import { ROUTE_NAMES } from 'navigation/constants'
import { default as React, useEffect, useRef, useState } from 'react'
import { Animated, KeyboardAvoidingView, Platform } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useHistory } from 'react-router-native'
import { percentScreenHeight } from 'utils/animations'
import { useHeaderContext } from 'utils/header-context'
import { useFocusEffect } from 'utils/navigation'
import Sentry from 'utils/sentry'
import LeftSide from './components/LeftSide'
import RightSide from './components/RightSide'
import LoginForm from './form/LoginForm'

// config, next page after login
const nextPageNavigate = ROUTE_NAMES.LOCATIONS

// gets authenticate callback
const authenticate =
  (setShowLoginForm: DispatchType<boolean>, getCurrentUser: any) => () => {
    async function authenticateAsync() {
      const token = await AsyncStorage.getItem(CACHE_KEYS.TOKEN)
      if (!token) {
        setShowLoginForm(true)
      } else {
        getCurrentUser()
      }
    }
    authenticateAsync()
  }

const SMALLER_MARGIN = percentScreenHeight(20)
const LARGER_MARGIN = percentScreenHeight(35)

// login screen
export default function LoginScreen({ route }: { route: any }) {
  const history = useHistory()
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false)
  const [animationFinished, setAnimationFinished] = useState<boolean>(false)
  const [loginError, setLoginError] = useState('')
  const [infoMessage, setInfoMessage] = useState('')

  const fresh = route?.params?.fresh
  const [getCurrentUser, { data: currentUserData }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      onError: () => {
        setShowLoginForm(true)
      },
      fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    },
  )
  const { setOptions } = useHeaderContext()

  useFocusEffect(history, () => {
    if (fresh) {
      setShowLoginForm(fresh)
    }
  })

  useEffect(() => {
    if (fresh) {
      setShowLoginForm(fresh)
    }
  }, [route, fresh])

  useEffect(() => {
    if (currentUserData?.user) {
      Sentry.configureScope((scope: any) => {
        const { user } = currentUserData
        scope.setUser({ id: user.id })
      })

      // Navigate only after user data is loaded
      history.push({ pathname: nextPageNavigate })
    }
  }, [currentUserData])

  useEffect(() => {
    setOptions({ hideHeader: true })
    authenticate(setShowLoginForm, getCurrentUser)()
  }, [])

  const logoMarginTop = useRef(new Animated.Value(SMALLER_MARGIN)).current

  useEffect(() => {
    Animated.timing(logoMarginTop, {
      toValue: showLoginForm ? SMALLER_MARGIN : LARGER_MARGIN,
      duration: 300,
      useNativeDriver: true,
    }).start(() => setAnimationFinished(true))
  }, [showLoginForm])

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
    >
      <LeftSide>
        {!showLoginForm && <GeneralActivityIndicator size="large" />}
        {showLoginForm && animationFinished && (
          <LoginForm
            {...{
              setShowLoginForm,
              nextPageNavigate,
              loginError,
              setLoginError,
              infoMessage,
              setInfoMessage,
            }}
          />
        )}
      </LeftSide>

      {IS_WEB && <RightSide />}
    </KeyboardAvoidingView>
  )
}

const styles = EStyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
})
