import React, { useState } from 'react'
import {
  ScrollView,
  Text,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import Modal from 'react-native-modal'
import * as FileSystem from 'expo-file-system'

import SignatureCanvas from 'components/common/SignatureCanvas'
import COLORS from 'constants/colors'
import { EVENT_TYPES } from 'constants/types'
import { ICON_NAMES } from 'constants/icons'
import Icon from 'components/common/Icon'
import { stylesGlobal } from 'constants/es-style'
import useCreateEvent from './hooks/useCreateEvent'

type SignatureModalProps = {
  isOpen: boolean
  sessionUuid: string
  taskId: string
  locationHierarchyId: string
  onClose: () => void
  closeTaskListModal: (removeModalFromCache?: boolean) => void
}

export default function SignatureModal({
  isOpen,
  sessionUuid,
  taskId,
  locationHierarchyId,
  onClose,
  closeTaskListModal,
}: SignatureModalProps) {
  const [sign, setSign] = useState<string | null>(null)

  const eventType = EVENT_TYPES.TAKE_PHOTO
  const createEvent = useCreateEvent({
    taskId,
    locationHierarchyId,
    sessionUuid,
  })

  const handleSignature = (signature: string) => {
    setSign(signature)
    const path = FileSystem.cacheDirectory + 'sign.png'
    FileSystem.writeAsStringAsync(
      path,
      signature.replace('data:image/png;base64,', ''),
      { encoding: FileSystem.EncodingType.Base64 },
    )
      .then((res) => {
        FileSystem.getInfoAsync(path, { size: true, md5: true }).then(
          async (file) => {
            await createEvent({ eventType, createEventData: { file } })
            onClose()
            if (closeTaskListModal) {
              closeTaskListModal(true)
            }
          },
        )
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const handleSignatureWeb = async (signature: string) => {
    await createEvent({
      eventType,
      createEventData: {
        imageString: signature,
      },
    })
    onClose()
  }

  const handleClear = () => {}
  const handleEmpty = () => {}

  return (
    <Modal isVisible={isOpen} style={stylesGlobal.modal}>
      <View style={stylesGlobal.modalContent}>
        <View style={stylesGlobal.modalContentHeader}>
          <Text style={stylesGlobal.modalContentHeaderText}>
            Please sign below
          </Text>
          <TouchableOpacity onPress={onClose}>
            <Icon name={ICON_NAMES.CLOSE} size={30} />
          </TouchableOpacity>
        </View>
        <View style={styles.canvasWrapper}>
          <SignatureCanvas
            onOK={Platform.OS === 'web' ? handleSignatureWeb : handleSignature}
            onEmpty={handleEmpty}
            onClear={handleClear}
            backgroundColor={COLORS.WHITE}
          />
        </View>
      </View>
    </Modal>
  )
}

const styles = EStyleSheet.create({
  container: {
    flex: 1,
  },
  cancelIconWrapper: {
    alignItems: 'flex-end',
    paddingRight: 10,
  },
  canvasWrapper: {
    height: '80%',
  },
})
