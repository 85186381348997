import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const OptionArrow = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 15 16" {...props}>
    <Path
      transform="matrix(.70711 .70711 -.60791 .79401 .5 6)"
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeWidth={3}
      d="M1.5-1.5h6.899"
    />
    <Path
      transform="matrix(.70711 -.70711 .60791 .79401 7.5 13)"
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeWidth={3}
      d="M1.5-1.5h6.899"
    />
  </Svg>
)

export default OptionArrow
