import React, { useState, useCallback, useEffect } from 'react'
import {
  View,
  Text,
  Platform,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNetworkStatus, useApolloOfflineClient } from 'react-offix-hooks'
import { useMutation } from '@apollo/react-hooks'
import * as WebBrowser from 'expo-web-browser'
import { fetchDiscoveryAsync, DiscoveryDocument } from 'expo-auth-session'
import getOktaCredentials from 'utils/helpers/getOktaCredentials'
import { useHeaderContext } from 'utils/header-context'
import { useHistory } from 'react-router-native'
import COLORS from 'constants/colors'
import MESSAGES from 'constants/messages'
import { LOGIN_BY_CREDS } from 'graphql1/mutations'
import { CACHE_KEYS } from 'constants/types'
import { ROUTE_NAMES } from 'navigation/constants'
import { writeErrorData } from 'utils/error-handling'
import useOktaLogin from './hooks/useOktaLogin'
import useWorkOSLogin from './hooks/useWorkOSLogin'
import ForgotPasswordButton from './components/ForgotPasswordButton'
import GoBackButton from './components/GoBackButton'
import SignInWithGoogle from './components/SignInWithGoogle'
import Separator from './components/Separator'
import InfoModal from './components/InfoModal'
import EmailField from './components/EmailField'
import PasswordField from './components/PasswordField'
import LoginButton from './components/LoginButton'
import { IS_WEB } from 'constants/static'
import { useTranslation } from 'react-i18next'

// serves for web only
WebBrowser.maybeCompleteAuthSession()

const isMobile = ['ios', 'android'].includes(Platform.OS)

// login form
const LoginForm = ({
  setShowLoginForm,
  nextPageNavigate,
  loginError,
  setLoginError,
  infoMessage,
  setInfoMessage,
}: {
  setShowLoginForm: DispatchType<boolean>
  nextPageNavigate: string
  loginError: string
  setLoginError: DispatchType<string>
  infoMessage: string
  setInfoMessage: DispatchType<string>
}) => {
  const client = useApolloOfflineClient()
  const online = useNetworkStatus()
  const history = useHistory()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [useSSOSignIn, setUseSSOSignIn] = useState(true)
  const [triggerOktaLogin, setTriggerOktaLogin] = useState(false)
  const [oktaClientId, setOktaClientId] = useState('')
  const [oktaDomain, setOktaDomain] = useState('')
  const [oktaDiscovery, setOktaDiscovery] = useState<DiscoveryDocument>(null)
  const [loadingGoogleOauth, setLoadingGoogleOauth] = useState(false)
  const [useMagicLink, setUseMagicLink] = useState(false)
  const [magicLinkSent, setMagicLinkSent] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const { saveCrossDomainToken } = useHeaderContext()

  const [login] = useMutation(LOGIN_BY_CREDS, {
    onCompleted: (data) => {
      const token = data?.login?.authenticationToken
      const isTemporaryPassword = data?.login?.isTemporaryPassword
      checkTokenAndNavigate(token, isTemporaryPassword)
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.message) {
        setLoginError(err.graphQLErrors[0].message)
      }
      setShowLoginForm(true)
    },
  })

  useOktaLogin({
    clientId: oktaClientId,
    discovery: oktaDiscovery,
    email,
    setLoginError,
    oktaDomain,
    triggerOktaLogin,
    setTriggerOktaLogin,
  })

  const checkTokenAndNavigate = async (
    token: string,
    isTemporaryPassword = false,
  ) => {
    if (!!token) {
      await AsyncStorage.setItem(CACHE_KEYS.TOKEN, token)
      saveCrossDomainToken(token)
      if (isTemporaryPassword) {
        history.push(ROUTE_NAMES.RESET_PASSWORD)
      } else {
        history.push(nextPageNavigate)
      }
    } else {
      writeErrorData(client, { message: 'invalid credentials' })
      setShowLoginForm(true)
    }
  }

  const { handleWorkOSflow, handleSendWorkOSMagicLink } = useWorkOSLogin({
    setInfoMessage,
    setLoginError,
    setUseSSOSignIn,
    setShowLoginForm,
    setMagicLinkSent,
    checkTokenAndNavigate,
  })

  const handleGoogleOauthLogin = async () => {
    setLoadingGoogleOauth(true)
    const data = {
      attributes: {
        isMobile,
        isGoogleOauth: true,
      },
    }
    const result = await handleWorkOSflow(data)
    if (!result) {
      setLoadingGoogleOauth(false)
      setLoginError('Error signing in using Google Auth')
    }
  }

  const handleSSOLogin = async () => {
    const data = {
      attributes: {
        email,
        isMobile,
      },
    }
    const result = await handleWorkOSflow(data)

    if (!result) {
      setUseSSOSignIn(false)
    }
  }

  // handle Okta login
  const handleOktaLogin = async () => {
    setLoginError('')
    setInfoMessage('')

    const { isOktaDomain, oktaClientId, oktaDomain } = getOktaCredentials(
      email.split('@').pop().toLocaleLowerCase(),
    )

    // continue with SSO login if not OKTA email
    if (!isOktaDomain) {
      await handleSSOLogin()
      return
    }

    setOktaClientId(oktaClientId)
    setOktaDomain(oktaDomain)

    const discovery = await fetchDiscoveryAsync(`https://${oktaDomain}`)
    setOktaDiscovery(discovery)
    setTriggerOktaLogin(true)
  }

  // handle login
  const handleLogin = useCallback(async () => {
    if (!online) {
      alert(MESSAGES.ERROR_FIRST_LOGIN)
      return
    }
    setShowLoginForm(false) // show loading
    const attributes = { email, password }
    login({ variables: { attributes } })
  }, [email, password, online])

  const handleOnSubmit = ({
    nativeEvent: { key },
  }: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (key === 'Enter' && !!email) {
      !useSSOSignIn ? handleLogin() : handleOktaLogin()
    }
  }

  const handleEmailChange = (text: string) => {
    setLoginError('')
    setEmail(text.trim())
  }

  const handleForgottenPassword = () => {
    setUseMagicLink(true)
  }

  const handleSendMagicLink = async () => {
    await handleSendWorkOSMagicLink({ attributes: { email: email } })
  }

  const handleGoBack = () => {
    setUseSSOSignIn(true)
    setUseMagicLink(false)
    setMagicLinkSent(false)
    setLoginError('')
    setInfoMessage('')
  }

  const closeModal = () => setModalOpen(false)

  useEffect(() => {
    setModalOpen(!!infoMessage)
  }, [infoMessage])

  const isFirstStep = useSSOSignIn // First step shows only email field
  const isSecondStep = !useSSOSignIn && !useMagicLink // Second step shows email and password
  const forgotPasswordStep = !useSSOSignIn && useMagicLink

  return (
    <View style={styles.loginForm}>
      <EmailField
        value={email}
        setValue={handleEmailChange}
        editable={isFirstStep}
        handleOnSubmit={handleOnSubmit}
      />

      {isSecondStep && (
        <>
          <PasswordField
            value={password}
            setValue={setPassword}
            handleOnSubmit={handleOnSubmit}
          />
        </>
      )}

      <View>
        <Text style={styles.errorMessage}>{loginError}</Text>
      </View>

      {magicLinkSent ? (
        <View>
          <Text style={{ fontFamily: 'Poppins_400Regular' }}>
            {t('checkSignInLink')}
          </Text>
        </View>
      ) : (
        <LoginButton
          disabled={!email || (isSecondStep && !password)}
          onPress={
            !useSSOSignIn
              ? useMagicLink
                ? handleSendMagicLink
                : handleLogin
              : handleOktaLogin
          }
          text={
            !useSSOSignIn
              ? useMagicLink
                ? t('sendSignInLink')
                : t('login')
              : t('next')
          }
          showNextArrow={isFirstStep}
        />
      )}

      {IS_WEB && isFirstStep && (
        <View>
          <Separator />

          <SignInWithGoogle
            onPress={handleGoogleOauthLogin}
            loading={loadingGoogleOauth}
          />
        </View>
      )}

      {(isSecondStep || forgotPasswordStep) && (
        <GoBackButton onPress={handleGoBack} />
      )}

      <InfoModal
        isVisible={modalOpen}
        onClose={closeModal}
        message={infoMessage}
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  loginForm: {
    width: '100%',
    alignItems: 'center',
    marginTop: 10,
  },
  errorMessage: {
    marginTop: 20,
    width: IS_WEB ? 420 : 300,
    color: COLORS.SECONDARY,
    fontSize: 14,
    textAlign: 'center',
    margin: 'auto',
    fontFamily: 'Poppins_400Regular',
  },
})

export default LoginForm
