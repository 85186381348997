import { MODEL_TYPES } from 'constants/types'
import { CREATE_EVENT, DELETE_EVENT } from 'graphql1/mutations'
import { CacheOperation } from 'offix-client'
import { filterObject } from 'utils/calculations'
import { wrapInTry } from 'utils/network'

// helper to get attribute object
const getAttributeObject = (data: object, idObject: object) => {
  const dataFinal = {
    ...idObject,
    ...data,
  }
  const exclude = true
  const attributeObject = {
    attributes: filterObject(dataFinal, ['__typename'], exclude),
  }
  return data ? attributeObject : {}
}

// helper to call a mutation, handles offline status, local storage out of the box
export const callMutation = (
  options: CallMutationOptions,
  callback?: MutationCallbackType,
) =>
  wrapInTry(
    ({
      client,
      mutation,
      variables,
      id,
      operationType,
      returnType,
      idField: idFieldRaw,
      cacheUpdate,
      ...rest
    }: CallMutationOptions) => {
      // id on demand (update mutation)
      const idObject = id ? { id } : {}
      // attributes object, not needed for delete
      const attrObject = getAttributeObject(variables, idObject)
      // update local cache on demand before mutation
      if (cacheUpdate) {
        // @ts-ignore
        cacheUpdate(client.cache)
      }
      const variablesFinal = {
        ...idObject,
        ...attrObject,
      }

      // execute mutation
      return client.offlineMutate({
        mutation,
        variables: variablesFinal,
        operationType,
        returnType,
        idField: idFieldRaw || 'id',
        ...rest,
      })
    },
    callback,
  )(options)

// helper to add an event
export const createEventBackend = async ({
  client,
  variables: data,
  callback,
  ...rest
}: CreateEventOptions) => {
  const result = await callMutation(
    {
      client,
      mutation: CREATE_EVENT,
      variables: {
        imageUrl: '', // Needed for optimistic responses
        createdAt: null,
        ...data,
      },
      ...rest,
      operationType: CacheOperation.ADD,
      returnType: MODEL_TYPES.EVENT,
    },
    callback,
  )
  return result?.data
}

export const deleteEventBackend = async ({
  client,
  variables: data,
  callback,
  ...rest
}: CreateEventOptions) => {
  const result = await callMutation(
    {
      client,
      mutation: DELETE_EVENT,
      variables: {
        ...data,
      },
      ...rest,
      operationType: CacheOperation.DELETE,
      returnType: MODEL_TYPES.EVENT,
    },
    callback,
  )

  return result?.data
}
