import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import MESSAGES from 'constants/messages'
import { TextModal } from 'components/task-complete/TextModal'
import { EVENT_TYPES } from 'constants/types'
import completeButtonStyles from './constants/completeButtonStyles'
import {
  CommentIcon,
  NopeIcon,
  NotApplicableIcon,
} from 'components/common/custom-icons'
import useCompleteElementStates from './hooks/useCompleteElementStates'
import CommentButton from 'components/task-list/buttons/CommentButton'

// photo icon to take picture for task completition
const TaskTextToComplete = (props: CompleteElementProps) => {
  const [taskTextCompleteModalOpen, setTaskTextCompleteModalOpen] =
    useState(false)

  const sessionUuid = props?.sessionUuid
  const locationHierarchyId = props?.locationHierarchyId
  const taskId = props?.id
  const completedEvent = props?.completedEvent

  const {
    isNotApplicableCompletedEvent,
    isCantComplete,
    completeState,
    taskDone,
  } = useCompleteElementStates({
    completedEvent,
  })

  const openTextScreen = () => {
    if (!sessionUuid) {
      alert(MESSAGES.START_SESSION_TO_INTERACT)
    } else {
      if (props.onNavigate) {
        props.onNavigate()
      }

      setTaskTextCompleteModalOpen(true)
    }
  }

  const onTextSubmit = () => {
    setTaskTextCompleteModalOpen(false)
    if (props.closeModal) {
      props.closeModal(true)
    }
  }

  const renderIcon = () => {
    const ICON_SIZE = 36

    if (!completeState) {
      return <CommentIcon height={ICON_SIZE} width={ICON_SIZE} opacity={0.16} />
    } else if (isCantComplete) {
      return <NopeIcon height={ICON_SIZE} width={ICON_SIZE} />
    } else if (isNotApplicableCompletedEvent) {
      return <NotApplicableIcon height={ICON_SIZE} width={ICON_SIZE} />
    }

    return <CommentIcon height={ICON_SIZE} width={ICON_SIZE} />
  }

  const renderTaskTextButton = () => {
    return !props.showAsButton ? (
      <View style={styles.container}>
        <TouchableOpacity onPress={openTextScreen} style={styles.iconContainer}>
          {renderIcon()}
        </TouchableOpacity>
      </View>
    ) : (
      <View style={styles.buttonContainer}>
        <CommentButton selected={taskDone} onPress={openTextScreen} />
      </View>
    )
  }

  return (
    <>
      {renderTaskTextButton()}
      <TextModal
        isOpen={taskTextCompleteModalOpen}
        setModalOpen={setTaskTextCompleteModalOpen}
        onSubmit={onTextSubmit}
        completeText={props?.completedEvent?.text}
        eventType={EVENT_TYPES.ADD_TEXT}
        submitButtonText="Confirm"
        title="Add note to the task"
        {...{ sessionUuid, locationHierarchyId, taskId }}
      />
    </>
  )
}

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    ...completeButtonStyles.buttonContainer,
  },
  iconContainer: {
    ...completeButtonStyles.iconContainer,
  },
})

export default TaskTextToComplete
