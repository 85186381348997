import React, { useMemo } from 'react'

import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import ScreenWrapper from 'components/common/ScreenWrapper'
import { default as MobileLocationScreen } from './LocationScreen'
import WelcomeMessage from './WelcomeMessage.web'
import {
  CLIENT_CONFIGURATION,
  GET_CURRENT_USER,
  GET_SNIPPETS,
  LOCATION_SCREEN_QUERY,
  UP_NEXT_TASKS_QUERY,
} from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import UpNext from './up-next/UpNext.web'
import OnboardingProgress from './onboarding-progress/OnboardingProgress.web'
import { getDefaultQueryVariables } from 'utils/apollo'
import { useAppContext } from 'utils/app-context'
import { useHeaderContext } from 'utils/header-context'
import { useLocation } from 'react-router-native'
import { mapSnippetsForLocation } from 'utils/helpers/snippets-helper'

const LocationScreenWeb = () => {
  const { options } = useHeaderContext()
  const isHome = !!options.title && options.title[0] === 'Home'

  const location = useLocation()
  const { parentId } = location.state || {}

  const { data: currentUserData } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })

  const { data: snippetsData } = useQuery<{ snippets: Snippet[] }>(
    GET_SNIPPETS,
    {
      fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    },
  )

  const { data: clientConfiguration } = useQuery(CLIENT_CONFIGURATION, {
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
  })

  const { data: upNextTasks } = useQuery(UP_NEXT_TASKS_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })

  const appContextValue = useAppContext()
  const variables = getDefaultQueryVariables(appContextValue)
  const { data: locationHierarchies } = useQuery(LOCATION_SCREEN_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_ONLY,
    variables,
  })

  function getProgress() {
    const currentLocation = locationHierarchies?.locationHierarchiesNew?.find(
      (location: LocationInterface) => location.id === parentId,
    )

    return {
      title: currentLocation?.title || '',
      numberOfTasks: currentLocation?.tasks || 0,
      numberOfCompletedTasks:
        currentLocation?.completed +
          currentLocation?.cantComplete +
          currentLocation?.notApplicable || 0,
    }
  }

  function getFilteredUpNextTasks() {
    if (parentId) {
      return upNextTasks?.upNextTasks?.filter(
        (upNextTask: TaskUncompleteType) =>
          upNextTask.locationHierarchy.parent.id == parentId,
      )
    }

    return upNextTasks?.upNextTasks || []
  }

  const snippetsMapping: StringMap = useMemo(() => {
    if (!currentUserData?.user || !snippetsData?.snippets) {
      return {}
    }

    return mapSnippetsForLocation(snippetsData.snippets, currentUserData.user)
  }, [currentUserData, snippetsData])

  return (
    <ScreenWrapper
      currentUser={currentUserData?.user}
      menuComponent={<MobileLocationScreen />}
      contentComponent={
        <StyledContent>
          {isHome ? (
            <WelcomeMessage
              currentUser={currentUserData?.user}
              configuration={clientConfiguration?.configuration[0]}
            />
          ) : (
            <OnboardingProgress {...getProgress()} />
          )}

          <UpNext
            tasks={getFilteredUpNextTasks()}
            snippetsMapping={snippetsMapping}
          />
        </StyledContent>
      }
    />
  )
}

const StyledContent = styled.div`
  display: flex;
  heigth: 100%;
  width: 100%;
  flex-direction: column;
  margin: 36px;
`

export default LocationScreenWeb
