import NetInfo, { NetInfoState } from '@react-native-community/netinfo'
import { NetworkStatusChangeCallback } from 'offix-client'
import { checkServerAlive } from 'utils/network'

// set to true for offline simulation TODO: remove
const debugOffline: boolean = false

/**
 * Web networks status implementation based on: Mozilla
 * See: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
 */
export class ReactNativeNetworkStatus {
  listeners: NetworkStatusChangeCallback[] = []
  online: false
  serverAlive: false | true

  constructor() {
    NetInfo.addEventListener(this.handleNetworkStatusChange.bind(this))
    this.serverAlive = false
    this.updateServerAlive()
  }

  addListener(listener: NetworkStatusChangeCallback) {
    this.listeners.push(listener)
  }

  async updateServerAlive() {
    const online = await !this.isOffline()
    // keep for now
    // this.serverAlive = Boolean(await checkServerAlive(online))
    this.serverAlive = true
    this.handleNetworkStatusChange(await NetInfo.fetch())
  }

  removeListener(listener: NetworkStatusChangeCallback) {
    const index = this.listeners.indexOf(listener)
    if (index >= 0) {
      this.listeners.splice(index, 1)
    }
  }

  isOffline() {
    if (debugOffline) {
      return Promise.resolve(true)
    }
    return new Promise((resolve) => {
      NetInfo.fetch().then((state) => {
        resolve(!state.isInternetReachable || !this.serverAlive)
      })
    })
  }

  async handleNetworkStatusChange(state: NetInfoState) {
    const online = state.isInternetReachable
    this.listeners.forEach((listener) => {
      listener({ online: debugOffline ? false : online && this.serverAlive })
    })
  }
}
