import React, { ReactNode, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-native'

import Header from 'components/navigator/Header'
import Breadcrumbs from 'components/navigator/Breadcrumbs'
import COLORS from 'constants/colors'
import {
  ExpandArrowRightIcon,
  TangeloIcon,
} from 'components/common/custom-icons'
import { ROUTE_NAMES } from 'navigation/constants'
import Background from './Background'
import PoweredByTangelo from './PoweredBy.web'

const ScreenWrapper = ({
  menuComponent,
  contentComponent,
  currentUser,
}: {
  menuComponent?: ReactNode
  contentComponent?: ReactNode
  currentUser: User
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showExpandIcon, setShowExpandIcon] = useState(false)
  const location = useLocation()

  const handleExpandClick = () => {
    setExpanded((prevValue) => !prevValue)
  }

  useEffect(() => {
    if (location.pathname !== ROUTE_NAMES.TASKS_TODAY) {
      setExpanded(false)
      setShowExpandIcon(false)
    } else {
      setShowExpandIcon(true)
    }
  }, [location.pathname])

  return (
    <Background>
      <StyledScreenWrapper>
        <Header currentUser={currentUser} />
        <div className="content-wrapper">
          <div
            className="categories-wrapper"
            style={expanded ? { maxWidth: '110px' } : { minWidth: '360px' }}
          >
            <div className="categories">
              <Breadcrumbs
                currentUser={currentUser}
                showLocationName={!expanded}
              />
              {menuComponent && menuComponent}
            </div>
            <PoweredByTangelo expanded={expanded} />
          </div>
          <div id="screen-wrapper-content" className="content">
            {showExpandIcon && (
              <div
                className="expand-icon"
                onClick={handleExpandClick}
                style={!expanded ? { transform: 'rotate(180deg)' } : {}}
              >
                <ExpandArrowRightIcon />
              </div>
            )}
            {contentComponent && contentComponent}
          </div>
        </div>
      </StyledScreenWrapper>
    </Background>
  )
}

const StyledScreenWrapper = styled.div`
  @media (min-width: 992px) {
    width: 782px;
  }
  @media (min-width: 1200px) {
    width: 982px;
  }
  @media (min-width: 1400px) {
    width: 1132px;
  }
  flex: 1;
  align-self: center;
  width: 100%;
  height: 100%;
  div,
  span,
  p {
    font-family: 'Poppins_400Regular';
  }
  input,
  textarea {
    font-family: 'Poppins_400Regular';
    color: #000000 !important;
    font-size: 16px;
    &::placeholder {
      font-family: 'Poppins_400Regular';
      font-size: 16px;
    }
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .categories-wrapper {
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    height: calc(100% - 48px);
    display: flex;

    .categories {
      flex: 1;
      max-width: 475px;
      width: 100%;
      box-sizing: border-box;
      border-top-width: 0px;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: ${COLORS.LIGHT_POWDER_BLUE};
      padding: 6px 12px;
      transition: all 0.2s ease;
      z-index: 99;
    }

    .content {
      background-color: white;
      display: flex;
      width: 100%;
      border-right: 1px solid ${COLORS.borderForm};
      overflow-y: scroll;
      padding-left: 12px;
      margin-left: -12px;
      .expand-icon {
        height: fit-content;
        position: absolute;
        margin-top: 15px;
        margin-left: -20px;
        cursor: pointer;
        z-index: 999;
      }
    }
  }
`

export default ScreenWrapper
