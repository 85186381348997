import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const NotApplicableIcon = (props: SvgProps) => (
  <Svg
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <Circle cx={18} cy={18} r={18} fill="#FFBD1B" />
    <Path
      d="M12.591 16.527c.633 0 1.136.212 1.508.637.371.417.557.985.557 1.702v3.2h-1.918v-2.945c0-.313-.081-.558-.244-.734-.163-.183-.382-.274-.656-.274-.287 0-.512.091-.675.274-.163.176-.245.42-.245.734v2.945H9v-5.49h1.918v.783c.17-.248.398-.447.685-.597.287-.156.617-.235.989-.235ZM20.279 12.75l-2.613 11.205h-1.85L18.43 12.75h1.85ZM21.128 19.317c0-.568.101-1.064.304-1.488.208-.424.489-.75.841-.979a2.178 2.178 0 0 1 1.194-.342c.379 0 .705.075.979.225.274.15.486.352.636.607v-.764H27v5.49h-1.918v-.763c-.15.255-.365.457-.646.607-.274.15-.597.225-.969.225-.437 0-.835-.114-1.194-.343a2.39 2.39 0 0 1-.841-.978c-.203-.43-.304-.93-.304-1.497Zm3.954 0c0-.353-.098-.63-.294-.832a.926.926 0 0 0-.704-.304.937.937 0 0 0-.715.304c-.189.195-.284.473-.284.832 0 .352.095.632.284.841.19.202.428.303.715.303.28 0 .515-.1.704-.303.196-.202.294-.483.294-.841Z"
      fill="#243162"
    />
  </Svg>
)

export default NotApplicableIcon
