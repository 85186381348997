import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import LinearProgressBar from './LinearProgressBar.web'
import { useTranslation } from 'react-i18next'

const OnboardingProgress = ({
  title,
  numberOfTasks,
  numberOfCompletedTasks,
}: {
  title: string
  numberOfTasks: number
  numberOfCompletedTasks: number
}) => {
  const { t } = useTranslation()
  
  const percentage =
    numberOfCompletedTasks > 0
      ? Math.round((numberOfCompletedTasks / numberOfTasks) * 100)
      : 0

  return (
    <StyledOnboardingProgress>
      <StyledTitle>{`${title} ${t('progress')}`}</StyledTitle>
      <LinearProgressBar percentage={percentage} />
      <StyledSubtitle>
        <div>{percentage}%</div>
        <div>
          {t('someTasksComplete', { numberOfCompletedTasks, numberOfTasks })}
        </div>
      </StyledSubtitle>
    </StyledOnboardingProgress>
  )
}

const StyledTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: ${COLORS.NAVY_BLUE};
  margin-bottom: 26px;
`

const StyledSubtitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.SIXES_GREY};
  margin-top: 20px;
`

const StyledOnboardingProgress = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
`

export default OnboardingProgress
