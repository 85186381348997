import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

// component that fills parent and centers its children
const Centered = ({ children }: { children: ChildrenType }) => (
  <View style={styles.container}>{children}</View>
)

// styles
const styles = EStyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    ...EStyleSheet.absoluteFillObject,
  },
})

export default Centered
