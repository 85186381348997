import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const HouseIcon = (props: SvgProps) => (
  <Svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <Path
      d="m8 .5-6.5 7v8h5v-4h3v4h5v-8L8 .5Z"
      stroke="#243162"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default HouseIcon
