import React from 'react'
import { View } from 'react-native'
import { ImageProps } from 'react-native-elements'
import styled from 'styled-components'
import get from 'lodash/get'

interface ImgProps {
  src: string
}
const Image = (props: ImageProps) => {
  const uri = get(props, 'source.uri', props.source)

  return (
    <View style={props.containerStyle}>
      <WebImage src={uri}></WebImage>
    </View>
  )
}

export default Image

const WebImage = styled.div<ImgProps>`
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`
