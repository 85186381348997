import React, { useMemo, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { WelcomeIcon } from 'components/common/custom-icons'
import format from 'utils/date-fns'
import COLORS from 'constants/colors'
import { GET_CLIENT_CONFIG, GET_PAGES } from 'graphql/queries'
import { FETCH_POLICIES } from 'constants/api'
import ContentPage from 'components/pages/ContentPage.web'
import Modal from 'react-native-modal'
import { useHistory } from 'react-router-native'
import { ROUTE_NAMES } from 'navigation/constants'
import { useTranslation } from 'react-i18next'

const WelcomeMessage = ({
  currentUser,
  configuration,
}: {
  currentUser: User
  configuration?: Configuration
}) => {
  const { t, i18n } = useTranslation()
  const dayOfTheWeek = format(new Date(), 'EEEE', i18n.resolvedLanguage)
  const monthDayYear = format(
    new Date(),
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )

  const [currentPageId, setCurrentPageId] = useState(null)
  const history = useHistory()

  const { data, loading } = useQuery(GET_PAGES, {
    fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
  })

  const { data: clientConfigData, loading: clientConfigLoading } = useQuery(
    GET_CLIENT_CONFIG,
    {
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    },
  )

  const contentPages = useMemo(getContentPages, [
    data,
    clientConfigData,
    currentUser,
    t,
  ])

  function getContentPages() {
    const contentPages =
      data?.contentPages.map(({ id, title }) => (
        <div key={id} onClick={() => setCurrentPageId(id)}>
          {title}
        </div>
      )) || []

    if (
      clientConfigData?.clientConfig.showResources &&
      !currentUser?.personalEmailSession
    ) {
      contentPages.push(
        <div
          onClick={() =>
            history.push({
              pathname: ROUTE_NAMES.RESOURCES,
            })
          }
        >
          {t('resources')}
        </div>,
      )
    }

    return contentPages
  }

  function renderIcon() {
    if (configuration?.welcomeIconUrl) {
      return <img src={configuration.welcomeIconUrl}></img>
    }

    return <WelcomeIcon />
  }

  return (
    <StyledWelcome
      fontColor={configuration?.welcomeFontColor}
      backgroundColor={configuration?.welcomeBackgroundColor}
    >
      <div className="welcome">
        <div className="welcome-left">
          <div className="welcome-date">
            <span className="day-of-the-week">{dayOfTheWeek}</span>
            <span className="month-day-year">{monthDayYear}</span>
          </div>
          <div className="welcome-greeting">
            {t('welcome', { name: currentUser?.firstName })}
          </div>
        </div>
        <div className="welcome-right">{renderIcon()}</div>
      </div>
      {
        <div className="welcome-menu">
          {contentPages}
          <Modal
            isVisible={!!currentPageId}
            hasBackdrop={false}
            style={{ margin: 0 }}
          >
            <ModalContent>
              <ContentPage
                pageId={currentPageId}
                close={() => setCurrentPageId(null)}
              />
            </ModalContent>
          </Modal>
        </div>
      }
    </StyledWelcome>
  )
}

const ModalContent = styled.div`
  @media (min-width: 1200px) {
    width: 982px;
  }
  @media (min-width: 1400px) {
    width: 1132px;
  }
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-height: 100%;
  overflow-y: scroll;
  background-color: #eeeeee;
`

const StyledWelcome = styled.div<{
  fontColor?: string
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: column;
  color: ${({ fontColor }) => fontColor ?? 'white'};

  .welcome {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? COLORS.NAVY_BLUE};
    border-radius: 32px;
    padding: 36px;
  }

  .welcome-right {
    img {
      width: 144px;
      height: 144px;
    }
  }

  .welcome-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 46px;

    & > div {
      font-family: 'Poppins_500Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 0px 24px;
      color: #243162;
      cursor: pointer;
    }
  }

  .welcome-date {
    font-size: 18px;
    line-height: 27px;
    margin: 4px;

    .day-of-the-week {
      font-weight: 600;
      margin-right: 8px;
    }

    .month-day-year {
      font-weight: 300;
    }
  }

  .welcome-greeting {
    font-weight: 800;
    font-size: 44px;
    line-height: 54px;
    white-space: pre-line;
  }
`

export default WelcomeMessage
