import { useQuery } from '@apollo/react-hooks'
import { MaterialIcons } from '@expo/vector-icons'
import { Loader } from 'components/common/Loader'
import { GeneralActivityIndicator } from 'components/Index'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { CACHE_KEYS, EVENT_TYPES, MODEL_TYPES } from 'constants/types'
import { LOCATION_PHOTOS_QUERY, TASK_PHOTOS_QUERY } from 'graphql1/queries'
import { ROUTE_NAMES } from 'navigation/constants'
import React, { Fragment, useEffect, useState } from 'react'
import {
  FlatList,
  ImageURISource,
  Platform,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native'
import { Button } from 'react-native-elements'
import Image from 'components/common/Image'
import { useHistory } from 'react-router-native'
import { useAppContext } from 'utils/app-context'
import { ISODayString } from 'utils/date'
import { LocationPhotoModal } from './LocationPhotoModal'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cancelIconWrapper: {
    alignItems: 'flex-end',
    paddingRight: 10,
  },
  inputContainer: {
    margin: 10,
    padding: 10,
    paddingBottom: 0,
  },
  input: {
    minHeight: 200,
    maxHeight: 400,
    borderColor: COLORS.TURQUOISE,
    borderWidth: 1,
    borderRadius: 20,
    padding: 20,
  },
  doneButton: {
    margin: 10,
    backgroundColor: COLORS.TURQUOISE,
  },
  imagesContainer: {
    flex: 1,
  },
  divider: {
    marginBottom: 10,
  },
  addPhotoButton: {
    width: '100%',
    maxHeight: 60,
    height: '100%',
    backgroundColor: COLORS.WHITE,
  },
  imageItem: {
    flex: 1,
    flexDirection: 'column',
    margin: 1,
    aspectRatio: 1,
  },
  imageThumbnail: {
    width: 100,
    height: 100,
    borderRadius: 5,
    backgroundColor: 'gray',
  },
  filePreviewContainer: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    overflow: 'hidden',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
})

interface Params {
  locationHierarchyId?: string
  sessionUuid: string
  taskId?: string
  loadData?: boolean
}

const imagesPerRow = 3

export default function LocationPhotosSection({
  locationHierarchyId,
  sessionUuid,
  taskId,
  loadData,
}: Params) {
  const navigation = useHistory()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<ImageURISource>({ uri: '' })
  const [currentEventId, setCurrentEventId] = useState<number>()
  const appContextValue = useAppContext()
  const dayISO = ISODayString(appContextValue.selectedDay)

  const { data: locationPhotosData, loading: loadingPhotos } = useQuery(
    LOCATION_PHOTOS_QUERY,
    {
      fetchPolicy: loadData
        ? FETCH_POLICIES.CACHE_AND_NETWORK
        : FETCH_POLICIES.CACHE_ONLY,
      variables: { locationHierarchyId, date: dayISO },
      skip: Boolean(taskId),
    },
  )
  const { data: taskPhotosData, loading: loadingText } = useQuery(
    TASK_PHOTOS_QUERY,
    {
      fetchPolicy: loadData
        ? FETCH_POLICIES.CACHE_AND_NETWORK
        : FETCH_POLICIES.CACHE_ONLY,
      variables: { taskId, date: dayISO },
      skip: !Boolean(taskId),
    },
  )

  const [images, setImages] = useState([
    { add: true },
    { dummy: true },
    { dummy: true },
  ])

  useEffect(() => {
    if (!locationPhotosData) {
      return
    }

    const { locationPhotos } = locationPhotosData
    const mappedImages = [...locationPhotos, { add: true }]

    for (let i = 0; i < mappedImages.length % imagesPerRow; i++) {
      mappedImages.push({ dummy: true })
    }

    setImages(mappedImages)
  }, [locationPhotosData])

  useEffect(() => {
    if (!taskPhotosData) {
      return
    }

    const { taskPhotos } = taskPhotosData
    const mappedImages = [...taskPhotos, { add: true }]

    for (let i = 0; i < mappedImages.length % imagesPerRow; i++) {
      mappedImages.push({ dummy: true })
    }

    setImages(mappedImages)
  }, [taskPhotosData])

  const showImageModal = (imageUrl: string, id: number) => {
    setCurrentImage({ uri: imageUrl })
    setCurrentEventId(id)
    setModalOpen(true)
  }

  if ((loadingPhotos || loadingText) && !images.length) {
    return <Loader />
  }

  return (
    <Fragment>
      <FlatList
        style={styles.imagesContainer}
        data={images}
        renderItem={({ item }) => (
          <View style={styles.imageItem}>
            {item.add && (
              <Button
                disabled={Platform.OS === 'web'}
                icon={
                  <MaterialIcons
                    name="add-a-photo"
                    size={40}
                    color={COLORS.TURQUOISE}
                  />
                }
                buttonStyle={styles.addPhotoButton}
                onPress={() => {
                  const navParams = !taskId
                    ? {
                        sessionUuid,
                        eventType: EVENT_TYPES.LOCATION_PHOTO,
                        cacheKey: CACHE_KEYS.LOCATION_PHOTOS,
                        locationHierarchyId,
                        query: LOCATION_PHOTOS_QUERY,
                        modelType: MODEL_TYPES.LOCATION_PHOTO,
                        uniqueId: `${locationHierarchyId}:${Date.now()}`,
                      }
                    : {
                        sessionUuid,
                        eventType: EVENT_TYPES.TASK_PHOTO,
                        cacheKey: CACHE_KEYS.TASK_PHOTOS,
                        id: taskId,
                        query: TASK_PHOTOS_QUERY,
                        modelType: MODEL_TYPES.TASK_PHOTO,
                        uniqueId: `${taskId}:${Date.now()}`,
                      }
                  navigation.push({
                    pathname: ROUTE_NAMES.CAMERA_COMPLETE_TASK,
                    state: navParams,
                  })
                }}
              />
            )}
            {item.imageUrl && (
              <TouchableHighlight
                onPress={() => showImageModal(item.imageUrl, item.id)}
              >
                <Image
                  source={{ uri: item.imageUrl }}
                  containerStyle={styles.filePreviewContainer}
                  style={styles.imageStyle}
                  PlaceholderContent={<GeneralActivityIndicator />}
                />
              </TouchableHighlight>
            )}
          </View>
        )}
        numColumns={3}
        keyExtractor={(item, index) => index.toString()}
      />
      <LocationPhotoModal
        isOpen={modalOpen}
        {...{
          setModalOpen,
          image: currentImage,
          eventId: currentEventId,
          locationHierarchyId,
          date: dayISO,
        }}
      />
    </Fragment>
  )
}
