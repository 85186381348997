import React, { FC } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

export const Header3: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content } = section
  if (content) {
    const { heading } = content
    return (
      <Container>
        <Heading>{heading}</Heading>
      </Container>
    )
  }
  return <Box>Header3</Box>
}

const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.005em;
  color: #000000;
  word-wrap: break-word;
`
