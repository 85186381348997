import React from 'react'
import { View, Text } from 'react-native'
import Image from 'components/common/Image'
import EStyleSheet from 'react-native-extended-stylesheet'
import COLORS from 'constants/colors'
import format from 'utils/date-fns'
import { isToday } from 'utils/date'
import { EVENT_TYPES } from 'constants/types'
import { useTranslation } from 'react-i18next'
import { AccessTimeIcon, PriorityIcon } from 'components/common/custom-icons'

const TaskModalHeader = ({
  completedEvent,
  task,
}: {
  completedEvent: TaskCompleteType
  task: TaskInterface
}) => {
  const { t, i18n } = useTranslation()
  const isCompleted = !!completedEvent?.createdAt
  const dueDate = getDateFromString(
    isCompleted ? completedEvent.createdAt : task.dueDate,
  )
  const dueDateText = format(
    dueDate,
    t('monthDayYearDateFormat'),
    i18n.resolvedLanguage,
  )
  const isDueToday = isToday(dueDate)
  const shouldShowDate = !!task.dueDate || isCompleted

  function getDateFromString(date: string) {
    if (date?.length == 10) {
      // then it is in YYYY-MM-DD format, so we add the time
      return new Date(date.replace(/-/g, '/'))
    }

    return new Date(date)
  }

  function renderEventText() {
    const { eventType } = completedEvent

    switch (eventType) {
      case EVENT_TYPES.ADD_CANT_TEXT:
        return t('markedCantComplete')
      case EVENT_TYPES.NOT_APPLICABLE:
        return t('markedNotApplicable')
      default:
        return t('completed')
    }
  }

  const Separator = () => (
    <Text
      style={{
        marginHorizontal: 16,
        color: '#7C8DA3',
      }}
    >
      |
    </Text>
  )

  const EstimateTime = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Separator />
      {t('timeToComplete')}{' '}
      <AccessTimeIcon
        width={18}
        height={18}
        style={{
          marginLeft: 10,
          marginRight: 6,
        }}
      />
      {task.estimateInMins} {t('min')}
    </View>
  )

  const Important = () => (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Separator />
      <PriorityIcon
        width={16}
        height={16}
        style={{
          marginRight: 4,
        }}
      />
      <Text
        style={{
          color: COLORS.SECONDARY,
        }}
      >
        {t('high')}
      </Text>
    </View>
  )

  return (
    <View style={[styles.headerContainer]}>
      <Image
        source={{
          uri:
            task.mediaUrl ??
            require('assets/images/png/default_task_image.png'),
        }}
        containerStyle={{
          width: 48,
          height: 48,
          marginRight: 36,
          alignSelf: 'center',
        }}
      />
      <View style={{ flex: 1 }}>
        {shouldShowDate && (
          <View style={styles.title}>
            <Text style={styles.titleDue}>
              {isCompleted
                ? renderEventText()
                : isDueToday
                ? t('dueToday')
                : t('due')}
            </Text>
            <Text style={styles.titleDate}>{dueDateText}</Text>
            {completedEvent?.completedBy && (
              <Text style={styles.titleDate}>{` ${t('by')} ${
                completedEvent?.completedBy.fullName
              }`}</Text>
            )}
            {!!task.estimateInMins && <EstimateTime />}
            {!!task.important && <Important />}
          </View>
        )}
        <Text style={styles.titleText}>{task.title}</Text>
      </View>
    </View>
  )
}

const styles = EStyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    borderBottomColor: '#E6EAF1',
    borderBottomWidth: 1,
    paddingBottom: 24,
    marginLeft: 40,
    marginRight: 40,
    minHeight: 78,
  },
  title: {
    flexDirection: 'row',
    color: COLORS.FIVES_GREY,
    fontSize: 14,
    lineHeight: 21,
    alignItems: 'baseline',
  },
  titleText: {
    flex: 1,
    flexWrap: 'wrap',
    color: COLORS.NAVY_BLUE,
    fontSize: 22,
    fontWeight: '600',
    lineHeight: 33,
  },
  titleDue: {
    fontWeight: '600',
    marginRight: 4,
  },
  titleDate: {
    fontWeight: 300,
  },
})

export default TaskModalHeader
