// screen components and their helper components
export { default as LoginScreen } from 'components/login/LoginScreen'
export { default as ResetPasswordScreen } from 'components/reset-password/ResetPasswordScreen'
export { default as TaskListByFolderScreen } from './locations/LocationScreen'
export { default as TaskTodayScreen } from './task-list/TaskTodayScreen'
export { default as TaskCompleteByPhotoScreen } from './task-complete/TaskCompleteByPhotoScreen'
export { default as TaskDocuSignToComplete } from './task-complete/TaskDocuSignToComplete'
export { default as LocationScreen } from 'components/locations/LocationScreen'
export { default as LocationScreenWrapper } from 'components/locations/LocationScreenWrapper'
export { default as TaskTodayScreenWeb } from 'components/task-list/TaskTodayScreenWrapper'
// export { default as ClientPagesComponent } from 'components/pages/ClientPagesComponent.web'
export { default as ResourcesScreen } from 'components/resources/ResourcesScreen'
export { default as ReportsScreen } from 'components/reports/ReportsScreen'

// common components
export { default as Camera } from './common/Camera'
export { default as CenteredText } from './common/CenteredText'
export { default as Centered } from './common/Centered'
export { default as Icon } from './common/Icon'
export { default as SpinningView } from './common/SpinningView'
export { default as ButtonWithIcon } from './common/ButtonWithIcon'
export { default as Filler } from './common/fragments/Filler'
export { default as GeneralActivityIndicator } from './common/GeneralActivityIndicator'
export { default as ImageWithZoom } from './common/ImageWithZoom'
