import React, { useState, useCallback, useMemo } from 'react'
import {
  ImageSourcePropType,
  Platform,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  View,
} from 'react-native'
import { SearchBar } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { useQuery } from '@apollo/react-hooks'

import { getDefaultQueryVariables } from 'utils/apollo'
import { FETCH_POLICIES } from 'constants/api'
import { debounce, SAVE_DEBOUNCE_TIMEOUT } from 'utils/debounce'
import { useAppContext } from 'utils/app-context'
import { GET_CLIENT_CONFIG, GET_CURRENT_USER } from 'graphql/queries'
import { Loader } from 'components/common/Loader'
import LocationElement from './LocationElement'
import LocationDatePicker from 'components/locations/LocationsDatePicker'
import { IS_WEB } from 'constants/static'
import { groupBy } from 'lodash'
import ManagerLocationsGroup from './ManagerLocationsGroup'
import COLORS from 'constants/colors'
import ManagerToggle from './ManagerToggle'
import { useTranslation } from 'react-i18next'

interface LocationsListParams {
  locationsToRender: LocationInterface[]
  refreshing: boolean
  onRefresh: () => void
  onFolderChange: (
    newParentId: string,
    newParentImageSource: ImageSourcePropType,
    owner?: User,
  ) => void
  parentImageSource: ImageSourcePropType
  hideSearch: boolean
  loading: boolean
  refetchLocationScreen: (variables?: {
    startDate: string
    endDate: string
    locationTitle?: string
  }) => void
}

const LocationsList = ({
  locationsToRender,
  refreshing,
  onRefresh,
  onFolderChange,
  parentImageSource,
  hideSearch,
  refetchLocationScreen,
  loading,
}: LocationsListParams) => {
  const [search, setSearch] = useState<String>('')
  const appContextValue = useAppContext()
  const variables = getDefaultQueryVariables(appContextValue)
  const { t } = useTranslation()

  const { data, loading: clientConfigLoading } = useQuery(GET_CLIENT_CONFIG, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  const showOnboarding = data?.clientConfig?.showOnboarding
  const { data: currentUserData, loading: userLoading } = useQuery<{
    user: User
  }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_FIRST,
  })

  const isManager = currentUserData?.user?.isManager || false

  const debouncedRefetch = useCallback(
    debounce((text) => {
      refetchLocationScreen({ ...variables, locationTitle: text })
    }, SAVE_DEBOUNCE_TIMEOUT),
    [],
  )

  const handleSearch = (text: string) => {
    setSearch(text)
    debouncedRefetch(text)
  }

  const getNormalAndManagerLocations = (locations: LocationInterface[]) => {
    const normalLocations: LocationInterface[] = locations.filter(
      (location) =>
        !location.owner || location.owner.id == currentUserData.user.id,
    )
    const managerLocations = locations.filter(
      (location) =>
        location.owner && location.owner.id !== currentUserData.user.id,
    )
    const groupedLocations = groupBy(managerLocations, 'owner.id')
    const locationsByEmployee: LocationInterface[][] = Object.values(
      groupedLocations,
    ).sort(sortGroupByMostRecent)

    return [normalLocations, locationsByEmployee]
  }

  const sortGroupByMostRecent = (
    a: LocationInterface[],
    b: LocationInterface[],
  ) => {
    if (!a[0]?.owner?.employee?.startDate) {
      return 1
    }
    if (!b[0]?.owner?.employee?.startDate) {
      return -1
    }

    return (
      new Date(b[0].owner.employee.startDate) -
      new Date(a[0].owner.employee.startDate)
    )
  }

  const [normalLocations, locationsByEmployee] = useMemo(() => {
    if (!currentUserData?.user) {
      return [[], []]
    }
    return getNormalAndManagerLocations(locationsToRender)
  }, [locationsToRender, currentUserData])

  const renderNormalLocationElements = (
    normalLocations: LocationInterface[],
  ) => {
    return normalLocations.map((location: LocationInterface) => (
      <LocationElement
        key={location.id}
        {...location}
        {...{ onFolderChange, parentImageSource }}
      />
    ))
  }

  const renderManagerLocationElements = (
    locationsByEmployee: LocationInterface[][],
  ) => {
    return locationsByEmployee.map(
      (employeeLocations: LocationInterface[], i: number) => {
        return (
          <ManagerLocationsGroup
            key={i}
            employeeLocations={employeeLocations}
            onFolderChange={onFolderChange}
            parentImageSource={parentImageSource}
          />
        )
      },
    )
  }

  const renderLocationElements = () => {
    if (loading || clientConfigLoading || userLoading) {
      return <Loader></Loader>
    }

    return (
      <>
        {renderNormalLocationElements(normalLocations)}
        {renderManagerLocationElements(locationsByEmployee)}
      </>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {IS_WEB && isManager && <ManagerToggle />}
        {!hideSearch && (
          <View style={styles.locationSearchWrapper}>
            <SearchBar
              lightTheme={true}
              platform={Platform.OS}
              placeholder={t('search')}
              onChangeText={handleSearch}
              value={search}
              clearIcon={{
                iconStyle: [styles.searchCancelButton],
              }}
              containerStyle={{
                backgroundColor: 'transparent',
                width: IS_WEB && !showOnboarding ? 'calc(100% - 90px)' : '100%',
                borderBottomWidth: 0,
                borderTopWidth: 0,
                paddingHorizontal: 0,
                paddingTop: 18,
                paddingBottom: 18,
              }}
              inputContainerStyle={{
                backgroundColor: 'white',
                borderRadius: 16,
                shadowColor: 'rgba(0, 0, 0, 0.08)',
              }}
              inputStyle={[
                {
                  fontSize: IS_WEB ? 18 : 14,
                  paddingLeft: 6,
                  color: COLORS.MIDDLE_GREY,
                },
                IS_WEB ? { outline: 'none' } : {},
              ]}
              searchIcon={{
                iconStyle: { fontSize: 24, color: COLORS.NAVY_BLUE },
              }}
            />
            {IS_WEB && !showOnboarding && <LocationDatePicker />}
          </View>
        )}
        {renderLocationElements()}
      </ScrollView>
    </SafeAreaView>
  )
}

// styles
const styles = EStyleSheet.create({
  container: {
    paddingTop: 0,
    flex: 1,
    paddingBottom: IS_WEB ? 0 : 10,
  },
  searchCancelButton: {
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  locationSearchWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollView: {
    paddingBottom: 12,
  },
})

export default LocationsList
