import React, { useState } from 'react'

import { useHistory } from 'react-router-native'
import { TEAM_METRICS_URL } from 'constants/static'
import { useHeaderContext } from 'utils/header-context'
import { ROUTE_NAMES } from 'navigation/constants'
import { Menu, Provider } from 'react-native-paper'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { CACHE_KEYS } from 'constants/types'
import { clearErrorData } from 'utils/error-handling'
import { useApolloOfflineClient } from 'react-offix-hooks'
import Sentry from 'utils/sentry'
import { ChevronDown } from 'components/common/custom-icons'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

const OptionsMenu = () => {
  const { saveCrossDomainToken } = useHeaderContext()
  const history = useHistory()
  const client = useApolloOfflineClient()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  const logout = async () => {
    await AsyncStorage.removeItem(CACHE_KEYS.TOKEN)
    saveCrossDomainToken('')

    history.push(ROUTE_NAMES.LOGIN)

    clearErrorData(client)
    client.clearStore()
    Sentry.configureScope((scope: any) => scope.setUser(null))
  }

  const teamMetrics = () => {
    window.open(TEAM_METRICS_URL, '_blank')
  }

  return (
    <Provider>
      <View>
        <Menu
          visible={visible}
          onDismiss={closeMenu}
          anchor={
            <div
              onClick={openMenu}
              style={{
                cursor: 'pointer',
              }}
            >
              <ChevronDown />
            </div>
          }
          style={{
            left: 1000,
            top: 45,
          }}
        >
          <Menu.Item onPress={logout} title={t('logout')} />
        </Menu>
      </View>
    </Provider>
  )
}

export default OptionsMenu
