import React from 'react'
import { TouchableOpacity, Text, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { Icon } from 'components/Index'
import { ICON_NAMES } from 'constants/icons'
import { useTranslation } from 'react-i18next'

function GoBackButton({ onPress }: { onPress: () => void }) {
  const { t } = useTranslation()

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.goBack}>
        <Icon name={ICON_NAMES.GO_BACK} style={styles.goBackArrow} />
        <Text style={styles.goBackText}>{t('back')}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = EStyleSheet.create({
  goBack: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  goBackText: {
    color: '#7C8DA3',
    fontWeight: '600',
    fontSize: 16,
    fontFamily: 'Poppins_400Regular',
  },
  goBackArrow: {
    paddingVertical: 10,
    paddingRight: 10,
    fontSize: 22,
    color: '#7C8DA3',
  },
})

export default GoBackButton
