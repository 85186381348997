import {
  JLL_CLIENT_ID,
  JLL_DOMAINS,
  JLL_OKTA_DOMAIN,
  SONOMA_CLIENT_ID,
  SONOMA_DOMAINS,
  SONOMA_OKTA_DOMAIN,
} from 'constants/api'

export type GetOktaCredentialsResponse = {
  oktaClientId?: string
  oktaDomain?: string
  isOktaDomain: boolean
}

export default function getOktaCredentials(
  domain: string,
): GetOktaCredentialsResponse {
  if (JLL_DOMAINS.includes(domain)) {
    return {
      oktaClientId: JLL_CLIENT_ID,
      oktaDomain: JLL_OKTA_DOMAIN,
      isOktaDomain: true,
    }
  } else if (SONOMA_DOMAINS.includes(domain)) {
    return {
      oktaClientId: SONOMA_CLIENT_ID,
      oktaDomain: SONOMA_OKTA_DOMAIN,
      isOktaDomain: true,
    }
  } else {
    return {
      isOktaDomain: false,
    }
  }
}
