import { DocumentNode } from 'graphql'
import { CacheKeysUnion, CACHE_KEYS } from 'constants/types'
import { doDynamicUpdate } from 'utils/calculations'
import { captureException } from 'utils/sentry'
import { Options as DeepMergeOptions } from 'deepmerge'

// creates the list of updated elements
const mapUpdatedElements = (
  readElements: any[],
  updateElementFinder: (oldElement: any) => boolean,
  newElementData: any,
  mergeOptions?: DeepMergeOptions,
) =>
  readElements.map((el: any) => {
    // check if element is found based on updateElementFinder function
    if (updateElementFinder(el)) {
      const merge = true
      return doDynamicUpdate(el, newElementData, merge, mergeOptions)
    }
    return el
  })

// cache update or add
export const getAddOrUpdateCacheFunction =
  (
    newElementOrData: any,
    cacheKey: CacheKeysUnion,
    queryObject: { query: DocumentNode; variables: AnyObjectType },
    updateElementFinder?: (oldElement: any) => boolean,
    mergeOptions?: DeepMergeOptions,
  ) =>
  (cache: any) => {
    try {
      const readElements = cache.readQuery(queryObject)?.[cacheKey] || []
      // found an element based on updateElementFinder function
      const hasElementToUpdate =
        updateElementFinder && Boolean(readElements.find(updateElementFinder))

      // new elements based on add or update operation
      const newElements = hasElementToUpdate
        ? mapUpdatedElements(
            readElements,
            updateElementFinder,
            newElementOrData,
            mergeOptions,
          )
        : readElements.concat(newElementOrData)

      // write to the cache
      cache.writeQuery({
        ...queryObject,
        data: {
          [cacheKey]: newElements,
        },
      })
    } catch (error) {
      // tslint:disable-next-line no-console
      console.log('error while writing/updating element in cache', error)
    }
  }

export const updateEventCreatedAt =
  (taskCompleteId: string, query: any) =>
  async (cache: any, { data }: any) => {
    const newEvent = { createdAt: data?.createEvent?.createdAt }
    const cacheKey = CACHE_KEYS.TASKS_COMPLETE

    const readElements = cache.readQuery(query)?.[cacheKey] || []
    const updateElementFinder = ({ id }: any) => taskCompleteId == id
    const hasElementToUpdate =
      updateElementFinder && Boolean(readElements.find(updateElementFinder))

    if (!hasElementToUpdate) {
      return
    }

    const newElements = mapUpdatedElements(
      readElements,
      updateElementFinder,
      newEvent,
    )
    cache.writeQuery({
      ...query,
      data: {
        [cacheKey]: newElements,
      },
    })
  }

// cache deletion
export const getDeleteFromCacheFunction =
  (
    keepListComparer: (oldElement: any) => boolean,
    cacheKey: CacheKeysUnion,
    queryObject: { query: DocumentNode; variables: AnyObjectType },
  ) =>
  (cache: any) => {
    try {
      // read elements from cache
      const readElements = cache.readQuery(queryObject)?.[cacheKey] || []

      // write filtered elements based on keepListComparer filter function
      cache.writeQuery({
        ...queryObject,
        data: {
          [cacheKey]: readElements.filter(keepListComparer),
        },
      })
    } catch (error) {
      // tslint:disable-next-line no-console

      captureException(error)
      console.log('error while deleting element from cache', error)
    }
  }
