import { format, toDate } from 'date-fns-tz'

export const ISODayString = (date: number | string | Date) =>
  date && format(toDate(date), 'yyyy-MM-dd')

export const isToday = (someDate: Date) => {
  const today = new Date()
  const sameDate = someDate.getDate() === today.getDate()
  const sameMonth = someDate.getMonth() === today.getMonth()
  const sameYear = someDate.getFullYear() === today.getFullYear()
  return sameDate && sameMonth && sameYear
}
