import { GET_SESSIONS } from 'graphql1/queries'
import get from 'lodash/get'

export const getTaskCompleteVariables = (
  locationHierarchyId: string,
  appContextValue?: AppContextValue,
) => {
  const defaultVariables = getDefaultQueryVariables(appContextValue)
  return {
    date: get(defaultVariables, 'startDate'),
    locationHierarchyId,
  }
}

const getTaskCompleteVariablesAsync = async (
  locationHierarchyId: string,
  appContextValue: AppContextValue,
  taskId: string,
) => {
  const defaultVariables = await getDefaultQueryVariablesAsync(appContextValue)
  return {
    date: get(defaultVariables, 'startDate'),
    locationHierarchyId,
    taskId,
  }
}

export const getUpdateQueryAfterEventSaved = async (
  query: any,
  locationHierarchyId: string,
  appContextValue: AppContextValue,
  taskId: string,
) => {
  const variables = await getTaskCompleteVariablesAsync(
    locationHierarchyId,
    appContextValue,
    taskId,
  )
  return {
    query,
    variables,
  }
}

export const getUpdateQuerySessions = async (
  appContextValue: AppContextValue,
) => ({
  query: GET_SESSIONS,
  variables: await getDefaultQueryVariablesAsync(appContextValue),
})

export const formEventSuccessObject = ({
  id,
  modelType,
  taskId,
  sessionUuid,
  imageUri,
  text,
  cantCompleteText,
}: {
  id: string
  modelType: MODEL_TYPES
  taskId: string
  sessionUuid: string
  imageUri?: string
  text?: string
  cantCompleteText?: string
  createdAt?: string
}): TaskCompleteType => {
  const newTaskComplete: TaskCompleteType = {
    __typename: modelType,
    id,
    taskId,
    sessionUuid,
    imageUrl: imageUri || (null as null),
    text: text || (null as null),
    cantCompleteText:
      cantCompleteText === '' ? '' : cantCompleteText || (null as null),
    createdAt: null,
  }

  return newTaskComplete
}

export const getDefaultQueryVariables = (appContextValue?: AppContextValue) => {
  const selectedDay = appContextValue?.selectedDay
  return {
    startDate: selectedDay,
    endDate: selectedDay,
  }
}

export const getDefaultQueryVariablesAsync = (
  appContextValue?: AppContextValue,
) =>
  new Promise(async (resolve: any) => {
    const selectedDay = appContextValue?.selectedDay
    resolve({
      startDate: selectedDay,
      endDate: selectedDay,
    })
  })
