import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const PriorityIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 16 16" {...props}>
    <Circle cx={8} cy={8} r={8} fill="#F2D2D3" />
    <Path
      d="M11.759 7.864 8.656 4.747a.64.64 0 0 0-.88 0L4.673 7.864a.621.621 0 0 0 .88.876l2.043-2.05v5.26a.62.62 0 1 0 1.242 0V6.69l2.041 2.05a.62.62 0 0 0 .88-.876"
      fill="#E74035"
    />
  </Svg>
)

export default PriorityIcon
