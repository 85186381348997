import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import Background from 'components/common/Background'
import PoweredByTangelo from 'components/common/PoweredBy.web'
import Header from 'components/navigator/Header.web'
import { FETCH_POLICIES } from 'constants/api'
import { GET_CURRENT_USER, GET_ONBOARDING_ANALYTICS_URL } from 'graphql/queries'
import COLORS from 'constants/colors'
import ManagerToggle from 'components/locations/ManagerToggle.web'

function ReportsScreen() {
  const { data: currentUserData } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
  })
  const { data: onboardingAnalyticsData } = useQuery(
    GET_ONBOARDING_ANALYTICS_URL,
    {
      fetchPolicy: FETCH_POLICIES.NETWORK_ONLY,
    },
  )

  const activityUrl =
    onboardingAnalyticsData?.employeeOnboardingAnalytics?.activityUrl

  return (
    <Background>
      <StyledReportsScreen>
        <Header currentUser={currentUserData?.user} />
        <StyledMenu>
          <ManagerToggle isTasksSelected={false} />
        </StyledMenu>
        <StyledContent>
          <iframe
            src={activityUrl}
            frameBorder="0"
            width="100%"
            height="100%"
            allowTransparency
          ></iframe>
        </StyledContent>
        <PoweredByTangelo />
      </StyledReportsScreen>
    </Background>
  )
}

const StyledMenu = styled.div`
  width: 324px;
  margin-top: 6px;
  margin-left: 16px;
`

const StyledContent = styled.div`
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.06));
  flex: 1;
  background: white;
  margin: 16px;
`

const StyledReportsScreen = styled.div`
  @media (min-width: 992px) {
    width: 782px;
  }
  @media (min-width: 1200px) {
    width: 982px;
  }
  @media (min-width: 1400px) {
    width: 1132px;
  }
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;
  font-family: Poppins_400Regular;
  background-color: ${COLORS.LIGHT_POWDER_BLUE};
`

export default ReportsScreen
