import React from 'react'

import { CameraIcon, CameraOutlinedIcon } from 'components/common/custom-icons'
import DefaultButton from './DefaultButton'
import { useTranslation } from 'react-i18next'

const CameraButton = ({
  selected,
  onPress,
}: {
  selected: boolean
  onPress: { (): void }
}) => {
  const { t } = useTranslation()

  return (
    <DefaultButton
      title={t('takePicture')}
      icon={<CameraIcon style={{ marginRight: 6 }} height={24} width={24} />}
      hoverIcon={
        <CameraOutlinedIcon style={{ marginRight: 6 }} height={24} width={24} />
      }
      selected={selected}
      onPress={onPress}
    />
  )
}

export default CameraButton
