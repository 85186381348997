import { ReactNativeFile } from 'apollo-upload-client'
import { CACHE_KEYS, EventTypesUnion, EVENT_TYPES } from 'constants/types'
import { CameraCapturedPicture } from 'expo-camera'
import { FileInfo } from 'expo-file-system'
import { createEventBackend } from 'graphql/graphql-helpers'
import {
  GET_TASKS_COMPLETE,
  LOCATION_PHOTOS_QUERY,
  LOCATION_TEXT_QUERY,
  TASK_PHOTOS_QUERY,
  TASK_TEXT_QUERY,
} from 'graphql/queries'
import { deleteFile } from 'utils/phone'
import {
  addCantCompleteTaskToCache,
  addLocationOrTaskPhotoToCache,
  addLocationOrTaskTextToCache,
  addTaskCompleteToCache,
  addNotApplicableTaskToCache,
  removeTaskCompleteFromCache,
  updateEventImageInCache,
  updateTaskCompleteInCache,
  addMediaCompleteTaskToCache,
} from './cache-update-helpers'
import { createImageFile, resizeAndOverwriteImage } from './image-helpers'

export interface CreateEventData {
  sessionUuid?: string
  taskId?: string
  text?: string
  imageString?: string
  cantCompleteText?: string
  eventType?: string
  image?: CameraCapturedPicture
  file?: FileInfo
  user?: User
  media?: FileList | ReactNativeFile
}

interface CreateEventProps {
  eventType: EventTypesUnion
  createEventData?: CreateEventData
}

interface CreateEventMutationVariables {
  eventType: string
  sessionUuid?: string
  taskId?: string
  imageFile?: ReactNativeFile
  text?: string
  cantCompleteText?: string
  user?: User
}

interface CreateEventMutationResponse {
  data: {
    createEvent: {
      imageUrl: string
      text: string
      optimisticResponse: boolean
    }
  }
}

interface CreateHandleCreateEventProps {
  client: ClientType
  selectedDay: string
  taskId: string
  sessionUuid: string
  locationHierarchyId: string
}

const processImage = async (image: CameraCapturedPicture) => {
  await resizeAndOverwriteImage(image)

  return await createImageFile(image)
}

const processFile = async (file: FileInfo) => {
  return await createImageFile(file)
}

const buildCreateEventMutationVariables = async (
  eventType: EventTypesUnion,
  createEventData: CreateEventData,
): Promise<CreateEventMutationVariables> => {
  const { image, file } = createEventData

  delete createEventData.image
  delete createEventData.file

  const mutationVariables: CreateEventMutationVariables = {
    eventType,
    ...createEventData,
  }

  if (image) {
    mutationVariables.imageFile = await processImage(image)
  }
  if (file) {
    mutationVariables.imageFile = await processFile(file)
  }

  return mutationVariables
}

const getEventTypeQueryCacheKey = (eventType: EventTypesUnion) => {
  if (eventType === EVENT_TYPES.LOCATION_PHOTO) {
    return CACHE_KEYS.LOCATION_PHOTOS
  }

  if (eventType === EVENT_TYPES.LOCATION_TEXT) {
    return CACHE_KEYS.LOCATION_TEXT
  }

  if (eventType === EVENT_TYPES.TASK_PHOTO) {
    return CACHE_KEYS.TASK_PHOTOS
  }

  if (eventType === EVENT_TYPES.TASK_TEXT) {
    return CACHE_KEYS.TASK_TEXT
  }

  return CACHE_KEYS.TASKS_COMPLETE
}

const beforeMutationCacheUpdateFunctions: any = {
  [EVENT_TYPES.UNCOMPLETE_TASK]: removeTaskCompleteFromCache,
  [EVENT_TYPES.ADD_CANT_TEXT]: addCantCompleteTaskToCache,
  [EVENT_TYPES.UPLOAD_MEDIA]: addMediaCompleteTaskToCache,
  [EVENT_TYPES.LOCATION_TEXT]: addLocationOrTaskTextToCache,
  [EVENT_TYPES.TASK_TEXT]: addLocationOrTaskTextToCache,
  [EVENT_TYPES.LOCATION_PHOTO]: addLocationOrTaskPhotoToCache,
  [EVENT_TYPES.TASK_PHOTO]: addLocationOrTaskPhotoToCache,
  [EVENT_TYPES.NOT_APPLICABLE]: addNotApplicableTaskToCache,
}

const afterMutationCacheUpdateFunctions: any = {
  [EVENT_TYPES.UNCOMPLETE_TASK]: (_: any) => {},
  [EVENT_TYPES.LOCATION_TEXT]: (_: any) => {},
  [EVENT_TYPES.TASK_TEXT]: (_: any) => {},
  [EVENT_TYPES.LOCATION_PHOTO]: updateEventImageInCache,
  [EVENT_TYPES.TASK_PHOTO]: updateEventImageInCache,
}

const getQueryToBeUpdated = ({
  eventType,
  taskId,
  selectedDay,
  locationHierarchyId,
}: any) => {
  if (eventType === EVENT_TYPES.LOCATION_PHOTO) {
    return {
      query: LOCATION_PHOTOS_QUERY,
      variables: { locationHierarchyId, date: selectedDay },
    }
  }

  if (eventType === EVENT_TYPES.LOCATION_TEXT) {
    return {
      query: LOCATION_TEXT_QUERY,
      variables: { locationHierarchyId, date: selectedDay },
    }
  }

  if (eventType === EVENT_TYPES.TASK_PHOTO) {
    return {
      query: TASK_PHOTOS_QUERY,
      variables: { taskId, date: selectedDay },
    }
  }

  if (eventType === EVENT_TYPES.TASK_TEXT) {
    return {
      query: TASK_TEXT_QUERY,
      variables: { taskId, date: selectedDay },
    }
  }

  return {
    query: GET_TASKS_COMPLETE,
    variables: {
      date: selectedDay,
      taskId,
      locationHierarchyId,
    },
  }
}

export const createHandleCreateEvent =
  (props: CreateHandleCreateEventProps) => async (data: CreateEventProps) => {
    const { client, selectedDay, taskId, sessionUuid, locationHierarchyId } =
      props
    const { eventType, createEventData } = data
    const mutationVariables = await buildCreateEventMutationVariables(
      eventType,
      { taskId, sessionUuid, ...createEventData },
    )
    const queryToBeUpdated = getQueryToBeUpdated({
      eventType: eventType,
      selectedDay,
      taskId,
      locationHierarchyId,
    })

    const cacheKey = getEventTypeQueryCacheKey(eventType)
    const beforeMutationCacheUpdate =
      beforeMutationCacheUpdateFunctions[eventType] || addTaskCompleteToCache

    const afterMutationCacheUpdate =
      afterMutationCacheUpdateFunctions[eventType] || updateTaskCompleteInCache

    beforeMutationCacheUpdate({
      mutationVariables,
      client,
      query: queryToBeUpdated,
      cacheKey,
      selectedDay,
      locationHierarchyId,
    })

    return createEventBackend({
      client,
      variables: mutationVariables,
      update: (
        cache: any,
        createEventResponse: CreateEventMutationResponse,
      ) => {
        const {
          data: { createEvent },
        } = createEventResponse

        if (createEvent.optimisticResponse) {
          return
        }
        if (mutationVariables.imageFile) {
          deleteFile(mutationVariables.imageFile.uri)
        }

        afterMutationCacheUpdate({
          mutationVariables,
          client,
          query: queryToBeUpdated,
          cacheKey,
          createEventResponse,
        })
      },
    })
  }

export const isCantCompleteEvent = (
  event?: { cantCompleteText?: string | null } | null,
) => {
  if (!event) {
    return false
  }

  return event.cantCompleteText !== null
}

export const isNotApplicable = (completeEvent?: TaskCompleteType) => {
  if (!completeEvent) {
    return false
  }

  return completeEvent?.eventType === EVENT_TYPES.NOT_APPLICABLE
}
