import gql from 'graphql-tag'

import {
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_TASK_VIRTUAL_FIELDS,
  FRAGMENT_LOCATION_HIERARCHY_FIELDS,
  FRAGMENT_SESSION_FIELDS,
  FRAGMENT_LOCATION_TEXT_FIELDS,
  TASK_COMPLETE_FIELDS,
  FRAGMENT_LOCATION_PHOTO_FIELDS,
  FRAGMENT_TASK_TEXT_FIELDS,
  FRAGMENT_TASK_PHOTO_FIELDS,
} from 'graphql1/fragments'
import { CACHE_KEYS, MODEL_TYPES } from 'constants/types'
import { FRAGMENT_USER_OWNER_FIELDS } from './fragments'

const GET_CLIENT_CONFIG_STRING = `
  clientConfig {
    showOrdersTab
    showOnboarding
    showSnippets
    enableSessionTracking
    showResources
    showLocale
  }
`

const GET_SNIPPETS_QUERY_STRING = `
  snippets {
    id
    name
    value
    fallbackValue
    snippetType
  }
`

const LOCATIONS_QUERY_STRING = `
  locationHierarchiesNew(startDate: $startDate, locationTitle: $locationTitle) {
    ...LocationHierarchyFields
  }
`

const SESSIONS_QUERY_STRING = `
  sessions(startDate: $startDate) {
    ...SessionFields
  }
`

const LOCATION_TEXT_QUERY_STRING = `
  locationText(date: $date, locationHierarchyId: $locationHierarchyId) {
    ...LocationTextFields
  }
`
const LOCATION_PHOTOS_QUERY_STRING = `
  locationPhotos(locationHierarchyId: $locationHierarchyId, date: $date) {
    ...LocationPhotoFields
  }
`

const TASK_TEXT_QUERY_STRING = `
  taskText(date: $date, taskId: $taskId) {
    ...TaskTextFields
  }
`
const TASK_PHOTOS_QUERY_STRING = `
  taskPhotos(taskId: $taskId, date: $date) {
    ...TaskPhotoFields
  }
`

const TASK_VIRTUAL_QUERY_STRING = `
  tasksVirtual(startDate: $startDate, endDate: $endDate, locationHierarchyId: $locationHierarchyId) {
    ...TaskVirtualFields
  }
`

const TASK_COMPLETE_QUERY_STRING = `
  tasksComplete(date: $date, locationHierarchyId: $locationHierarchyId) {
    ${TASK_COMPLETE_FIELDS}
  }
`

export const LOCATION_TEXT_QUERY = gql`
  query LocationTextQuery(
    $locationHierarchyId: ID!,
    $date: ISO8601Date!
    )  {
    ${LOCATION_TEXT_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_TEXT_FIELDS}
`

export const LOCATION_PHOTOS_QUERY = gql`
  query LocationPhotosQuery(
    $locationHierarchyId: ID!,
    $date: ISO8601Date!
    ) {
    ${LOCATION_PHOTOS_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_PHOTO_FIELDS}
`

export const TASK_TEXT_QUERY = gql`
  query LocationTextQuery(
    $taskId: ID!,
    $date: ISO8601Date!
    )  {
    ${TASK_TEXT_QUERY_STRING}
  }
  ${FRAGMENT_TASK_TEXT_FIELDS}
`

export const TASK_PHOTOS_QUERY = gql`
  query TaskPhotosQuery(
    $taskId: ID!,
    $date: ISO8601Date!
    ) {
    ${TASK_PHOTOS_QUERY_STRING}
  }
  ${FRAGMENT_TASK_PHOTO_FIELDS}
`

export const TASKS_SCREEN_QUERY = gql`
  query TasksScreenQuery(
    $startDate: ISO8601Date!,
    $endDate: ISO8601Date!,
    $date: ISO8601Date!,
    $locationHierarchyId: ID!
    ) {
    ${SESSIONS_QUERY_STRING}
    ${TASK_VIRTUAL_QUERY_STRING}
    ${TASK_COMPLETE_QUERY_STRING}
    ${LOCATION_TEXT_QUERY_STRING}
    ${GET_SNIPPETS_QUERY_STRING}
    locationHierarchy(locationId: $locationHierarchyId) {
      rootNode {
        owner {
          ...UserOwnerFields
        }
      }
    }
  }
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
  ${FRAGMENT_SESSION_FIELDS}
  ${FRAGMENT_LOCATION_TEXT_FIELDS}
  ${FRAGMENT_USER_OWNER_FIELDS}
`

export const INITIAL_LOAD_ALL_QUERY = gql`
  query LoadInitial($startDate: ISO8601Date!, $endDate: ISO8601Date!, $limitToAssignmentsIfAvail: Boolean!, $locationTitle: String) {
    ${LOCATIONS_QUERY_STRING}
    ${SESSIONS_QUERY_STRING}
    ${TASK_VIRTUAL_QUERY_STRING}
  }
  ${FRAGMENT_LOCATION_HIERARCHY_FIELDS}
  ${FRAGMENT_SESSION_FIELDS}
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
`

export const LOCATION_SCREEN_QUERY = gql`
  query LocationScreenQuery($startDate: ISO8601Date!, $locationTitle: String) {
    ${LOCATIONS_QUERY_STRING}
    ${SESSIONS_QUERY_STRING}
    ${GET_CLIENT_CONFIG_STRING}
  }
  ${FRAGMENT_LOCATION_HIERARCHY_FIELDS}
  ${FRAGMENT_SESSION_FIELDS}
`

export const GET_CLIENT_CONFIG = gql`
  query ClientConfig {
    ${GET_CLIENT_CONFIG_STRING}
  }
`

export const GET_TASKS_VIRTUAL = gql`
  query TasksVirtualQuery($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    ${TASK_VIRTUAL_QUERY_STRING}
  }
  ${FRAGMENT_TASK_VIRTUAL_FIELDS}
`

export const GET_SESSIONS = gql`
  query SessionsQuery {
    ${SESSIONS_QUERY_STRING}
  }
  ${FRAGMENT_SESSION_FIELDS}
`

export const GET_TASKS_COMPLETE = gql`
  query TasksCompleteQuery($date: ISO8601Date!, $locationHierarchyId: ID!) {
    ${TASK_COMPLETE_QUERY_STRING}
  }
`
export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      firstName
      lastName
      isManager
      email
      clientId
      fullName
      workEmail
      personalEmail
      personalEmailSession
      preferredLocale
      isAdmin
      taxonomyTerms {
        title
        id
        taxonomy {
          name
          slug
        }
      }
      employee {
        startDate
        manager {
          firstName
          lastName
          fullName
          email
        }
      }
    }
  }
`

export const GET_CLIENT_PRODUCTS = gql`
  query ClientProductsQuery($locationHierarchyId: ID!) {
    clientProducts(locationHierarchyId: $locationHierarchyId) {
      id
      price
      parLevel
      clientProduct {
        id
        name
        image
      }
    }
  }
`

export const ERROR_DATA = gql`
  query ErrorData {
    errorData @client {
      hasError
      message
      status
    }
  }
`

export const APP_DATA = gql`
  query AppData {
    appData @client {
      taskDescriptionModalId
    }
  }
`

export const CLIENT_CONFIGURATION = gql`
  query Configuration {
    configuration {
      clientLogo
      backgroundUrl
      welcomeIconUrl
      welcomeBackgroundColor
      welcomeFontColor
    }
  }
`

export const GET_PAGES = gql`
  query Pages {
    contentPages {
      id
      title
    }
  }
`

export const GET_PAGE = gql`
  query Pages($pageId: ID!) {
    contentPage(pageId: $pageId) {
      id
      title
      sections {
        id
        sectionType
        hidden
        content
        pictureUrl
        icon1Url
        icon2Url
        icon3Url
        icon4Url
        icon5Url
        icon6Url
        icon7Url
        icon8Url
        icon9Url
        icon10Url
      }
    }
  }
`

export const UP_NEXT_TASKS_QUERY = gql`
  query UpNextTasks {
    upNextTasks {
      id
      title
      dueDate
      taskType
      mediaUrl
      important
      locationHierarchyId
      locationHierarchy {
        parent {
          id
          title
          templateCategoryTitle
          owner {
            id
            fullName
          }
        }
        title
        templateCategoryTitle
      }
    }
  }
`

export const GET_SNIPPETS = gql`
  query Snippets {
    ${GET_SNIPPETS_QUERY_STRING}
  }
`

export const GET_RESOURCES_HIERARCHY = gql`
  query ResourcesHierarchy {
    resourcesHierarchy {
      id
      title
      position
      children {
        id
      }
      resources {
        id
        title
        url
        position
      }
    }
  }
`

export const GET_ONBOARDING_ANALYTICS_URL = gql`
  query employeeOnboardingAnalytics {
    employeeOnboardingAnalytics {
      activityUrl
    }
  }
`
