import React from 'react'
import { ActivityIndicator } from 'react-native'

import COLORS from 'constants/colors'

const GeneralActivityIndicator = ({
  size = 'small',
  ...props
}: {
  size?: number | 'small' | 'large'
}) => <ActivityIndicator size={size} color={COLORS.TURQUOISE} {...props} />

export default GeneralActivityIndicator
