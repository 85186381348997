import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import Modal from 'react-native-modal'
import PDFReader from 'rn-pdf-reader-js'

import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import { stylesGlobal } from 'constants/es-style'

type LocationElementDetailsModalProps = {
  isOpen: boolean
  setModalOpen: DispatchType<boolean>
  invoiceFileUrlJson: []
}

export const LocationElementDetailsModal = ({
  isOpen,
  setModalOpen,
  invoiceFileUrlJson,
}: LocationElementDetailsModalProps) => {
  const closeModal = () => setModalOpen(false)

  return (
    <Modal isVisible={isOpen} style={stylesGlobal.modal}>
      <View style={[stylesGlobal.modalContent, styles.container]}>
        <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
          <Icon
            name={ICON_NAMES.CLOSE}
            size={30}
            style={stylesGlobal.modalCloseIcon}
            onPress={closeModal}
          />
        </TouchableOpacity>
        {invoiceFileUrlJson &&
          invoiceFileUrlJson.map((invoiceFileUrl: string) => (
            <PDFReader
              source={{
                uri: invoiceFileUrl,
              }}
            />
          ))}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginTop: 32,
    backgroundColor: '#f4f4f4',
    maxHeight: '100%',
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 50,
    height: 50,
  },
})
