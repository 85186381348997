import React, {
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  forwardRef,
} from 'react'
import { CACHE_KEYS } from 'constants/types'
import { CROSS_DOMAIN_STORAGE_URL } from 'constants/static'
import AsyncStorage from '@react-native-async-storage/async-storage'

type Props = {
  setCrossDomainIFrameLoaded: Dispatch<SetStateAction<boolean>>
}

const CrossDomainStorage = forwardRef(
  ({ setCrossDomainIFrameLoaded }: Props, forwardRef) => {
    const crossDomainIFrame = useRef({} as HTMLIFrameElement)

    useEffect(() => {
      if (crossDomainIFrame.current!!) {
        crossDomainIFrame.current.addEventListener(
          'load',
          onCrossDomainIframeLoad,
        )
      }

      window.addEventListener('message', handleCrossDomainMessages, false)

      return () => {
        window.removeEventListener('message', handleCrossDomainMessages)
      }
    }, [])

    const onCrossDomainIframeLoad = () => {
      if (crossDomainIFrame.current!!) {
        crossDomainIFrame!.current!.contentWindow!.postMessage(
          {
            action: 'get',
            key: CACHE_KEYS.CROSS_DOMAIN_TOKEN,
          },
          CROSS_DOMAIN_STORAGE_URL || '*',
        )
      }
    }

    const handleCrossDomainMessages = async (event: MessageEvent<any>) => {
      const {
        data: { action, key, value },
        origin,
      } = event

      if (action === 'returnData') {
        switch (key) {
          case CACHE_KEYS.CROSS_DOMAIN_TOKEN: {
            const token = await AsyncStorage.getItem(CACHE_KEYS.TOKEN)

            if (!token && !!value) {
              localStorage.setItem(CACHE_KEYS.TOKEN, value)
            }
          }
        }

        if (origin === CROSS_DOMAIN_STORAGE_URL) {
          setCrossDomainIFrameLoaded(true)
        }
      }
    }

    return (
      <iframe
        ref={(iframe) => {
          if (typeof forwardRef == 'function') {
            forwardRef(iframe)
          } else {
            forwardRef.current = iframe
            crossDomainIFrame.current = iframe
          }
        }}
        style={{ display: 'none' }}
        src={CROSS_DOMAIN_STORAGE_URL}
      />
    )
  },
)

export default CrossDomainStorage
