import * as React from 'react'
import Svg, { SvgProps, Mask, Path } from 'react-native-svg'

const CameraOutlined = (props: SvgProps) => (
  <Svg fill="none" viewBox="0 0 36 30" {...props}>
    <Mask id="a" fill="#fff">
      <Path
        d="M11.126 1.456A3 3 0 0 1 13.698 0h8.603a3 3 0 0 1 2.573 1.456l.252.42a3 3 0 0 0 2.572 1.457H33a3 3 0 0 1 3 3V27a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V6.333a3 3 0 0 1 3-3H8.3a3 3 0 0 0 2.573-1.456l.252-.42z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Mask>
    <Path
      d="M10.874 1.877 9.159.847l1.715 1.03zm14.252 0-1.715 1.029 1.715-1.03zm-.252-.42 1.715-1.03-1.715 1.03zM22.3-2H13.7v4H22.3v-4zm4.54 2.848-.252-.42-3.43 2.057.252.42 3.43-2.057zM33 1.333h-5.301v4h5.3v-4zM38 27V6.333h-4V27h4zm-5 5a5 5 0 0 0 5-5h-4a1 1 0 0 1-1 1v4zM3 32h30v-4H3v4zm-5-5a5 5 0 0 0 5 5v-4a1 1 0 0 1-1-1h-4zm0-20.667V27h4V6.333h-4zm10.3-5H3v4h5.3v-4zM9.41.427l-.252.42 3.43 2.059.253-.42L9.41.426zM8.3 5.333a5 5 0 0 0 4.288-2.427L9.158.848a1 1 0 0 1-.857.485v4zm-6.3 1a1 1 0 0 1 1-1v-4a5 5 0 0 0-5 5h4zm31-1a1 1 0 0 1 1 1h4a5 5 0 0 0-5-5v4zm-9.59-2.427a5 5 0 0 0 4.288 2.427v-4a1 1 0 0 1-.857-.485l-3.43 2.058zM13.699-2A5 5 0 0 0 9.411.427l3.43 2.058A1 1 0 0 1 13.698 2v-4zm8.603 4a1 1 0 0 1 .857.485l3.43-2.058A5 5 0 0 0 22.301-2v4z"
      fill="#50CEA2"
      mask="url(#a)"
    />
    <Path
      d="M11.25 13.8V9.75h4.05M20.7 9.75h4.05v4.05M24.75 19.2v4.05H20.7M15.3 23.25h-4.05V19.2M18 18.525a2.025 2.025 0 1 0 0-4.05 2.025 2.025 0 0 0 0 4.05z"
      stroke="#50CEA2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </Svg>
)

export default CameraOutlined
