import { ReactNativeFile } from 'apollo-upload-client'
import { EventTypesUnion, EVENT_TYPES, MODEL_TYPES } from 'constants/types'
import { CameraCapturedPicture } from 'expo-camera'
import { FileInfo } from 'expo-file-system'
import { isString } from 'lodash'
import { getAddOrUpdateCacheFunction } from 'utils/apollo-cache'

interface CreateEventData {
  sessionUuid?: string
  taskId?: string
  text?: string
  cantCompleteText?: string
  image?: CameraCapturedPicture
  file?: FileInfo
}

interface CreateEventProps {
  eventType: EventTypesUnion
  createEventData?: CreateEventData
}

interface CreateEventMutationVariables {
  eventType: string
  sessionUuid?: string
  taskId?: string
  imageFile?: ReactNativeFile
  text?: string
  cantCompleteText?: string
}

export const removeTaskCompleteFromCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const readElements = client.cache.readQuery(query)?.[cacheKey] || []
  const { taskId } = mutationVariables

  client.cache.writeQuery({
    ...query,
    data: {
      [cacheKey]: readElements.filter(
        (taskComplete: any) => taskComplete.taskId !== taskId,
      ),
    },
  })
}

export const mapLocationText = (
  mutationVariables: CreateEventMutationVariables,
  selectedDay: string,
  locationHierarchyId: string,
) => {
  return {
    __typename: MODEL_TYPES.LOCATION_TEXT,
    id: `${locationHierarchyId}:${selectedDay}`,
    text: mutationVariables.text,
  }
}
export const mapTaskText = (
  mutationVariables: CreateEventMutationVariables,
  selectedDay: string,
) => {
  return {
    __typename: MODEL_TYPES.TASK_TEXT,
    id: `${mutationVariables.taskId}:${selectedDay}`,
    text: mutationVariables.text,
  }
}

export const mapLocationPhoto = (
  mutationVariables: CreateEventMutationVariables,
) => {
  return {
    __typename: MODEL_TYPES.LOCATION_PHOTO,
    imageUrl: mutationVariables.imageFile.uri,
    id: Date.now().toString(),
  }
}
export const mapTaskPhoto = (
  mutationVariables: CreateEventMutationVariables,
) => {
  return {
    __typename: MODEL_TYPES.TASK_PHOTO,
    imageUrl: mutationVariables.imageFile.uri,
    id: Date.now().toString(),
  }
}

export const mapTaskComplete = (
  mutationVariables: CreateEventMutationVariables,
): TaskCompleteType => {
  return {
    __typename: MODEL_TYPES.TASK_COMPLETE,
    createdAt: null,
    id: `${mutationVariables.taskId}:${mutationVariables.sessionUuid}`,
    imageUrl: mutationVariables?.imageFile?.uri || '',
    sessionUuid: mutationVariables.sessionUuid,
    taskId: mutationVariables.taskId,
    eventType: mutationVariables.eventType,
    text: !mutationVariables.cantCompleteText
      ? mutationVariables.text || ''
      : null,
    cantCompleteText: mutationVariables.cantCompleteText || null,
    notApplicableText: null,
    mediaUrls: [],
    completedBy: null,
  }
}

export const mapTaskCantComplete = (
  mutationVariables: CreateEventMutationVariables,
): TaskCompleteType => {
  return {
    __typename: MODEL_TYPES.TASK_COMPLETE,
    createdAt: null,
    id: `${mutationVariables.taskId}:${mutationVariables.sessionUuid}`,
    imageUrl: mutationVariables?.imageFile?.uri || '',
    sessionUuid: mutationVariables.sessionUuid,
    taskId: mutationVariables.taskId,
    text: null,
    mediaUrls: [],
    eventType: mutationVariables.eventType,
    cantCompleteText: isString(mutationVariables.text)
      ? mutationVariables.text
      : null,
    notApplicableText: null,
    completedBy: null,
  }
}

export const mapTaskMediaComplete = (
  mutationVariables: CreateEventMutationVariables,
): TaskCompleteType => {
  return {
    __typename: MODEL_TYPES.TASK_COMPLETE,
    createdAt: null,
    id: `${mutationVariables.taskId}:${mutationVariables.sessionUuid}`,
    imageUrl: '',
    sessionUuid: mutationVariables.sessionUuid,
    taskId: mutationVariables.taskId,
    text: null,
    mediaUrls: ['uploading...'],
    eventType: mutationVariables.eventType,
    cantCompleteText: null,
    notApplicableText: null,
    completedBy: null,
  }
}

export const mapTaskNotApplicable = (
  mutationVariables: CreateEventMutationVariables,
): TaskCompleteType => {
  return {
    __typename: MODEL_TYPES.TASK_COMPLETE,
    createdAt: null,
    id: `${mutationVariables.taskId}:${mutationVariables.sessionUuid}`,
    imageUrl: mutationVariables?.imageFile?.uri || '',
    sessionUuid: mutationVariables.sessionUuid,
    taskId: mutationVariables.taskId,
    text: null,
    eventType: mutationVariables.eventType,
    cantCompleteText: null,
    mediaUrls: [],
    notApplicableText: isString(mutationVariables.text)
      ? mutationVariables.text
      : null,
    completedBy: null,
  }
}

export const addTaskCompleteToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const taskComplete = mapTaskComplete(mutationVariables)
  const { taskId } = mutationVariables
  const elementFinder = (element: TaskCompleteType) => element.taskId === taskId

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}

export const addCantCompleteTaskToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const taskComplete = mapTaskCantComplete(mutationVariables)
  const { taskId } = mutationVariables
  const elementFinder = (element: TaskCompleteType) => element.taskId === taskId

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}

export const addMediaCompleteTaskToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const taskComplete = mapTaskMediaComplete(mutationVariables)
  const { taskId } = mutationVariables
  const elementFinder = (element: TaskCompleteType) => element.taskId === taskId
  const deepMergeOptions = {
    customMerge: (key: string) => {
      // we want to owerwrite mediaUrls array instead of doing a deep merge
      if (key === 'mediaUrls') {
        return (a: Array<string>, b: Array<string>) => b
      }
    },
  }

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
    deepMergeOptions,
  )(client.cache)
}

export const addNotApplicableTaskToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const taskComplete = mapTaskNotApplicable(mutationVariables)
  const { taskId } = mutationVariables
  const elementFinder = (element: TaskCompleteType) => element.taskId === taskId

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}

export const updateTaskCompleteInCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
  createEventResponse,
}: any) => {
  const {
    data: { createEvent },
  } = createEventResponse
  const { taskId } = mutationVariables

  const elementFinder = (element: TaskCompleteType) => element.taskId === taskId
  const deepMergeOptions = {
    customMerge: (key: string) => {
      // we want to owerwrite mediaUrls array instead of doing a deep merge
      if (key === 'mediaUrls') {
        return (a: Array<string>, b: Array<string>) => b
      }
    },
  }

  const updateElement: {
    createdAt: string
    imageUrl?: string
    completedBy: User
    mediaUrls?: Array<string>
  } = {
    createdAt: createEvent.createdAt,
    completedBy: createEvent.user,
  }

  if (!!createEvent?.imageUrl?.length) {
    updateElement.imageUrl = createEvent.imageUrl
  }

  if (!!createEvent?.mediaUrls?.length) {
    updateElement.mediaUrls = createEvent.mediaUrls
  }

  getAddOrUpdateCacheFunction(
    updateElement,
    cacheKey,
    query,
    elementFinder,
    deepMergeOptions,
  )(client.cache)
}

export const addLocationOrTaskTextToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
  selectedDay,
  locationHierarchyId,
}: any) => {
  const { eventType } = mutationVariables
  const taskComplete =
    eventType === EVENT_TYPES.TASK_TEXT
      ? mapTaskText(mutationVariables, selectedDay)
      : mapLocationText(mutationVariables, selectedDay, locationHierarchyId)
  const elementFinder = (element: TaskCompleteType) =>
    element.id === taskComplete.id

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}

export const addLocationOrTaskPhotoToCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
}: any) => {
  const { eventType } = mutationVariables
  const taskComplete =
    eventType === EVENT_TYPES.TASK_PHOTO
      ? mapTaskPhoto(mutationVariables)
      : mapLocationPhoto(mutationVariables)

  // Always add photo
  const elementFinder = (_: TaskCompleteType) => false

  getAddOrUpdateCacheFunction(
    taskComplete,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}

export const updateEventImageInCache = ({
  mutationVariables,
  client,
  query,
  cacheKey,
  createEventResponse,
}: any) => {
  const {
    data: { createEvent },
  } = createEventResponse
  const elementFinder = (element: { imageUrl: string }) =>
    element.imageUrl === mutationVariables.imageFile.uri
  const updateElement: { createdAt: string; imageUrl?: string } = {
    createdAt: createEvent.createdAt,
    imageUrl: createEvent.imageUrl,
  }

  getAddOrUpdateCacheFunction(
    updateElement,
    cacheKey,
    query,
    elementFinder,
  )(client.cache)
}
