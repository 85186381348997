import React from 'react'
import { View, Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'

import SpinningView from './SpinningView'
import Icon from './Icon'

export const Loader = () => {
  return (
    <View style={styles.container}>
      <SpinningView style={styles.spinnerWrapper} reverse>
        <Icon name={ICON_NAMES.SPINNER} style={styles.spinner} />
      </SpinningView>
    </View>
  )
}

// styles
const styles = EStyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  spinnerWrapper: {
    alignSelf: 'center',
  },
  spinner: { color: COLORS.BLACK, fontSize: 80 },
})
