import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ImageSourcePropType, Text, View, Alert } from 'react-native'
import { useQuery } from '@apollo/react-hooks'
import AsyncStorage from '@react-native-async-storage/async-storage'
import RowView from 'components/common/fragments/RowView'
import { FETCH_POLICIES } from 'constants/api'
import COLORS from 'constants/colors'
import { ROOT_FOLDER_NAME, IS_WEB } from 'constants/static'
import { CACHE_KEYS } from 'constants/types'
import { GET_CURRENT_USER, LOCATION_SCREEN_QUERY } from 'graphql/queries'
import groupBy from 'lodash/groupBy'
import { ROUTE_NAMES } from 'navigation/constants'
import { Icon } from 'react-native-elements'
import EStyleSheet from 'react-native-extended-stylesheet'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useApolloOfflineClient, useNetworkStatus } from 'react-offix-hooks'
import { useHistory, useLocation } from 'react-router-native'
import { getDefaultQueryVariables } from 'utils/apollo'
import { useAppContext } from 'utils/app-context'
import { useHeaderContext } from 'utils/header-context'
import { onRefreshCall, useFocusEffect } from 'utils/navigation'
import { clearErrorData } from 'utils/error-handling'
import { LocationNotesModal } from './LocationNotesModal'
import {
  getLevelTree,
  getLocationNotes,
  getLocationsToRender,
} from './locations-helper'
import LocationDatePicker from './LocationsDatePicker'
import LocationsList from './LocationsList'
import Sentry from 'utils/sentry'

const findRootLocationId = (locations: LocationInterface[]) =>
  locations.find((l: LocationInterface) => l.title === ROOT_FOLDER_NAME)?.id

const LogoutButton = () => {
  const history = useHistory()
  const client = useApolloOfflineClient()

  const onLogout = async () => {
    Alert.alert('Logout', 'Do you want to logout?', [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'Confirm',
        onPress: async () => {
          await AsyncStorage.removeItem(CACHE_KEYS.TOKEN)
          history.push(ROUTE_NAMES.LOGIN)
        },
      },
    ])

    clearErrorData(client)
    client.clearStore()
    Sentry.configureScope((scope: any) => scope.setUser(null))
  }

  return (
    <View style={styles.logoutIcon}>
      <Icon
        name="power-settings-new"
        type="material"
        size={30}
        color={COLORS.SECONDARY}
        onPress={onLogout}
      />
    </View>
  )
}

const getRightHeader = (
  hasParentLocation: boolean,
  setModalOpen: any,
  showInfoIcon: boolean,
  showOnboarding: boolean,
) => ({
  headerRight: () =>
    hasParentLocation ? (
      <View style={styles.headerRightContainer}>
        {showInfoIcon && (
          <TouchableOpacity
            style={{ marginRight: 40 }}
            onPress={() => {
              setModalOpen(true)
            }}
          >
            <Icon
              name="info"
              type="material"
              size={35}
              color={COLORS.TURQUOISE}
            />
          </TouchableOpacity>
        )}
      </View>
    ) : (
      <RowView style={styles.datePickerWrapper}>
        {!IS_WEB && !showOnboarding && <LocationDatePicker />}
      </RowView>
    ),
  ...(hasParentLocation
    ? {}
    : {
        headerLeft: () => <LogoutButton />,
      }),
})

// component to render the task list
export default function LocationScreen() {
  const history = useHistory()
  const location = useLocation()
  const { setOptions } = useHeaderContext()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const online = useNetworkStatus()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const getRefreshing = useCallback(() => refreshing, [refreshing])
  const appContextValue = useAppContext()
  const client = useApolloOfflineClient()
  const day = appContextValue.selectedDay
  const variables = getDefaultQueryVariables(appContextValue)
  const { data, loading, error, refetch } = useQuery(LOCATION_SCREEN_QUERY, {
    fetchPolicy: FETCH_POLICIES.CACHE_AND_NETWORK,
    variables,
  })
  // data
  const locations = data?.[CACHE_KEYS.LOCATION_HIERARCHIES] || []
  const sessions = data?.[CACHE_KEYS.SESSIONS] || []
  const showOnboarding = data?.[CACHE_KEYS.CLIENT_CONFIG]?.showOnboarding
  // computed from data
  const rootId = findRootLocationId(locations)
  const groupedSessions = groupBy(sessions, 'locationHierarchyId')
  const { parentId, parentImageSource } = location.state || ({} as any)
  const hasParentLocation = !!parentId

  // locations to render
  const locationsToRender = useMemo(
    getLocationsToRender(locations, parentId, groupedSessions, day),
    [locations, sessions, parentId, groupedSessions, day],
  )

  const refetchLocations = (variables?: {
    startDate: string
    endDate: string
    locationTitle?: string
  }) => {
    refetch(variables)
  }

  useEffect(() => {
    // reset selected task
    client.writeData({
      data: {
        appData: {
          taskDescriptionModalId: null,
          __typename: 'AppData',
        },
      },
    })

    setOptions({
      title: getLevelTree(locations, parentId),
      ...(hasParentLocation ? { showBack: true } : { showBack: false }),
      ...getRightHeader(
        hasParentLocation,
        setModalOpen,
        showInfoIcon,
        showOnboarding,
      ),
    })
    return () => {}
  }, [parentId])

  // refetch on navigation focus
  useFocusEffect(history, refetch)

  const onFolderChange = (
    newParentId: string,
    newParentImageSource: ImageSourcePropType,
    owner?: User,
  ) => {
    history.push({
      pathname: ROUTE_NAMES.LOCATIONS,
      state: {
        parentId: newParentId,
        parentImageSource: newParentImageSource,
        owner: owner,
      },
    })
  }

  const onRefresh = useCallback(() => {
    onRefreshCall(online, getRefreshing, setRefreshing, refetch)
  }, [refreshing, online])

  if (error && !Boolean(locationsToRender?.length)) {
    return (
      <Text>An error occurred while fetching tasks ...{error.message}</Text>
    )
  }

  const locationNotes = getLocationNotes(locations, parentId)
  const showInfoIcon = locationNotes !== null && locationNotes !== ''

  return (
    <View style={{ flex: 1 }}>
      <LocationsList
        {...{
          locationsToRender,
          styles,
          refreshing,
          onRefresh,
          onFolderChange,
          parentImageSource,
          hideSearch: hasParentLocation,
          refetchLocationScreen: refetchLocations,
          loading: loading,
        }}
      ></LocationsList>
      {locationNotes && (
        <LocationNotesModal
          isOpen={modalOpen}
          notes={locationNotes}
          setModalOpen={setModalOpen}
        />
      )}
    </View>
  )
}

const styles = EStyleSheet.create({
  datePickerWrapper: {
    marginRight: 10,
  },
  logoutIcon: {
    marginLeft: 25,
    marginTop: 2,
  },
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
})
