import React, { useEffect, useState, ReactNode } from 'react'
import ReactDOM from 'react-dom'

export default function TaskListModalWeb({
  children,
}: {
  children: ReactNode
}) {
  const [hasMounted, setHasMounted] = useState(false)
  const element: Element = document.querySelector(`#screen-wrapper-content`)

  useEffect(() => {
    element.scrollTop = 0
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return ReactDOM.createPortal(
    children,
    element,
  )
}
