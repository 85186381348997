import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Modal from 'react-native-modal'
import Icon from 'components/common/Icon'
import COLORS from 'constants/colors'
import { stylesGlobal } from 'constants/es-style'
import { ICON_NAMES } from 'constants/icons'
import { EventTypesUnion, EVENT_TYPES } from 'constants/types'
import { IS_WEB } from 'constants/static'
import useCreateEvent from 'components/task-complete/hooks/useCreateEvent'
import DefaultButton from 'components/task-list/buttons/DefaultButton'
import NotApplicableButton from 'components/task-list/buttons/NotApplicableButton'
import CantCompleteButton from 'components/task-list/buttons/CantCompleteButton'
import { useTranslation } from 'react-i18next'

type TextModalProps = {
  isOpen: boolean
  setModalOpen: DispatchType<boolean>
  onSubmit: DispatchType<string>
  taskId: string
  sessionUuid: string
  locationHierarchyId: string
  eventType: EventTypesUnion
  title: string
  completeText?: string
}

export const TextModal = ({
  isOpen,
  setModalOpen,
  onSubmit,
  taskId,
  sessionUuid,
  locationHierarchyId,
  eventType,
  title,
  completeText,
}: TextModalProps) => {
  const [text, setText] = useState('')
  const closeModal = () => setModalOpen(false)
  const textInputRef = useRef()
  const { t } = useTranslation()

  const onChangeText = (newText: string) => setText(newText)

  const onConfirm = () => {
    textComplete(text)
    onSubmit()
  }

  const createEvent = useCreateEvent({
    sessionUuid,
    taskId,
    locationHierarchyId,
  })

  const isCantCompleteModal = useMemo(() => {
    return eventType === EVENT_TYPES.ADD_CANT_TEXT
  }, [eventType])

  const isNotApplicable = useMemo(() => {
    return eventType === EVENT_TYPES.NOT_APPLICABLE
  }, [eventType])

  const textComplete = (text: string) => {
    createEvent({ eventType, createEventData: { text, eventType } })
  }

  useEffect(() => {
    setText(completeText || '')
  }, [completeText])

  useEffect(() => {
    if (isOpen && textInputRef.current) {
      setTimeout(() => {
        textInputRef?.current?.focus()
      }, 200)
    }
  }, [isOpen])

  const renderButton = () => {
    if (isCantCompleteModal) {
      return (
        <CantCompleteButton selected={false} hover={true} onPress={onConfirm} />
      )
    } else if (isNotApplicable) {
      return (
        <NotApplicableButton
          selected={false}
          hover={true}
          onPress={onConfirm}
        />
      )
    }

    return <DefaultButton title={t('accept')} onPress={onConfirm} />
  }

  return (
    <Modal isVisible={isOpen} style={stylesGlobal.modal}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (!IS_WEB) {
            Keyboard.dismiss()
          }
        }}
      >
        <View style={stylesGlobal.modalContent}>
          <View style={stylesGlobal.modalContentHeader}>
            <Text style={stylesGlobal.modalContentHeaderText}>{title}</Text>
            <TouchableOpacity onPress={closeModal}>
              <Icon name={ICON_NAMES.CLOSE} size={30} onPress={closeModal} />
            </TouchableOpacity>
          </View>

          <ScrollView keyboardShouldPersistTaps="handled">
            <TextInput
              style={[
                styles.input,
                IS_WEB ? { outline: 'none' } : {},
                isCantCompleteModal ? { borderColor: '#CCCCD6' } : {},
                isNotApplicable ? { borderColor: COLORS.ORANGE } : {},
              ]}
              multiline
              numberOfLines={5}
              ref={textInputRef}
              placeholder={t('typeHereToAddText')}
              onChangeText={(textChange: string) => onChangeText(textChange)}
              value={text}
            />
            <View style={stylesGlobal.modalButtonsContainer}>
              {renderButton()}
            </View>
          </ScrollView>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

const styles = StyleSheet.create({
  input: {
    minHeight: 180,
    maxHeight: 400,
    borderColor: COLORS.POWDER_BLUE,
    borderWidth: 2,
    borderRadius: 6,
    padding: 16,
  },
})
