import * as React from 'react'
import Svg, { SvgProps, Path, Rect } from 'react-native-svg'

function Calendar(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="#50CEA2" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.468 2.667h13.064c.44 0 .818 0 1.13.025.33.027.658.086.973.247a2.5 2.5 0 011.092 1.093c.161.315.22.643.247.972.026.312.026.691.026 1.13v11.398c0 .44 0 .818-.026 1.13-.026.33-.086.658-.247.973a2.5 2.5 0 01-1.092 1.092c-.315.161-.643.22-.973.247-.312.026-.69.026-1.13.026H5.468c-.44 0-.818 0-1.13-.026-.33-.026-.658-.086-.973-.247a2.5 2.5 0 01-1.093-1.092c-.16-.315-.22-.643-.247-.973C2 18.35 2 17.973 2 17.533V6.135c0-.44 0-.819.025-1.13.027-.33.087-.658.247-.973a2.5 2.5 0 011.093-1.093c.315-.16.643-.22.972-.247.313-.025.692-.025 1.13-.025z"
        fillOpacity={0.77}
      />
      <Path
        d="M5.333 1.833a.833.833 0 011.667 0v3.334a.833.833 0 01-1.667 0V1.833zM17 1.833a.833.833 0 011.667 0v3.334a.833.833 0 01-1.667 0V1.833zM13.667 11a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zM18.667 11a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zM8.667 16a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0zM13.667 16a1.667 1.667 0 11-3.334 0 1.667 1.667 0 013.334 0z"
        fillOpacity={0.77}
      />
      <Rect x={5} y={15} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={5} y={11} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={5} y={7} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={10.5} y={15} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={10.5} y={11} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={10.5} y={7} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={16} y={15} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={16} y={11} width={3} height={2} rx={0.5} fill="#fff" />
      <Rect x={16} y={7} width={3} height={2} rx={0.5} fill="#fff" />
    </Svg>
  )
}

export default Calendar
