import AsyncStorage from '@react-native-async-storage/async-storage'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloOfflineClient } from 'offix-client'
import { setContext } from 'apollo-link-context'
import { GraphQLError } from 'graphql'
import { onError } from 'apollo-link-error'
import { CachePersistor } from 'apollo-cache-persist'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { captureException } from 'utils/sentry'

import { GRAPHQL_END_POINT } from 'constants/api'
import introspectionQueryResultData from 'constants/fragment-types.json'
import { ReactNativeNetworkStatus } from 'utils/helpers/ReactNativeNetworkStatus'
import { CACHE_KEYS } from 'constants/types'
import { writeErrorData } from 'utils/error-handling'

import { addApolloClientListeners } from './helpers/init-helper'
import i18n from 'i18n/setup'

// fragment matcher for cache
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

// define cache storage logic
const cacheStorage = {
  getItem: async (key: string) => {
    const data = await AsyncStorage.getItem(key)
    if (typeof data === 'string') {
      return JSON.parse(data)
    }
    return data
  },
  setItem: (key: string, value: any) => {
    let valueStr = value
    if (typeof valueStr === 'object') {
      valueStr = JSON.stringify(value)
    }
    return AsyncStorage.setItem(key, valueStr)
  },
  removeItem: (key: string) => {
    return AsyncStorage.removeItem(key)
  },
}

const authLinkContextSetter = (_: any, { headers }: any) =>
  new Promise(async (resolve) => {
    // get the authentication token from local storage if it exists
    const token = await AsyncStorage.getItem(CACHE_KEYS.TOKEN)
    // return the headers to the context so httpLink can read them
    resolve({
      headers: {
        ...headers,
        'Accept-Language': i18n.languages.toString(),
        authorization: token || '',
      },
    })
  })

const authLink = setContext(authLinkContextSetter)

const uploadLink: ApolloLink = createUploadLink({
  uri: GRAPHQL_END_POINT,
})

// apollo cache
const apolloCache = new InMemoryCache({ fragmentMatcher })

apolloCache.writeData({
  data: {
    errorData: {
      __typename: 'error',
      hasError: false,
      status: '',
      message: '',
    },
    appData: {
      taskDescriptionModalId: '',
      __typename: 'AppData',
    },
  },
})

// apollo input mapper
export const apolloInputMapper = {
  deserialize: (variables: any) => variables.attributes,
  serialize: (variables: any) => ({ attributes: variables }),
}

const cachePersistor = new CachePersistor<object>({
  cache: apolloCache,
  // @ts-ignore
  storage: AsyncStorage,
  trigger: 'background',
  maxSize: false,
})

// custom network status class
const networkStatus = new ReactNativeNetworkStatus()

type GraphQLErrorCustom = GraphQLError & {
  status?: string
  title?: string
}

const middlewareLink = new ApolloLink((operation, forward) => {
  if (operation.variables?.attributes?.imageFile) {
    operation.variables.attributes.imageFile.__proto__ = File.prototype
  }
  return forward(operation)
})

// init the offline client
const client = new ApolloOfflineClient({
  cache: apolloCache,
  cachePersistor,
  // link: createApolloClientLink(client),
  link: ApolloLink.from([
    authLink,
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, status }: any) => {
          const errorMessage = `[GraphQL error]: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}`
          console.log(errorMessage)

          // Don't log authentication errors
          if (status !== '401') {
            captureException(errorMessage)
          }

          writeErrorData(client, {
            message: 'An error occurred. Admins have been notified',
            status,
          })
        })
      }
    }),
    middlewareLink,
    uploadLink,
  ]),
  offlineStorage: cacheStorage,
  resolvers: {},
  cacheStorage,
  // @ts-ignore
  networkStatus,
  inputMapper: apolloInputMapper,
})

// add listeners
addApolloClientListeners(client)

export default client
