import React from 'react'

import { useTranslation } from 'react-i18next'
import DefaultButton from './DefaultButton'
import Icon from 'components/common/Icon'
import { ICON_NAMES } from 'constants/icons'
import COLORS from 'constants/colors'
import GeneralActivityIndicator from 'components/common/GeneralActivityIndicator'

const UploadMediaButton = ({
  selected,
  loading,
  onPress,
  disabled,
}: {
  selected: boolean
  loading: boolean
  onPress: { (): void }
  disabled: boolean
}) => {
  const { t } = useTranslation()

  const icon = loading ? (
    <GeneralActivityIndicator size="small" style={{ marginRight: 6 }} />
  ) : (
    <Icon
      name={ICON_NAMES.CLOUD_UPLOAD}
      size={24}
      color={COLORS.TURQUOISE}
      style={{ marginRight: 6 }}
    />
  )

  return (
    <DefaultButton
      title={loading ? t('uploading') : t('uploadMedia')}
      icon={icon}
      hoverIcon={icon}
      selected={selected}
      onPress={onPress}
      disabled={disabled}
    />
  )
}

export default UploadMediaButton
