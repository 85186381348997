import React, { FC, Fragment } from 'react'

import { Box } from './Box'

import { SectionViewComponentProps } from '../PageSection'
import styled from 'styled-components'

interface SubsectionValues {
  heading: string
  text: string
}

export const Sections3: FC<SectionViewComponentProps> = ({
  section,
}: SectionViewComponentProps) => {
  const { content, pictureUrl } = section
  if (content) {
    const { heading: mainHeading, text: mainText, subsections } = content
    return (
      <Fragment>
        <HeadingContainer>
          <Heading>{mainHeading}</Heading>
          <Text>{mainText}</Text>
        </HeadingContainer>
        <Container>
          <Picture>
            {pictureUrl ? (
              <img src={pictureUrl} alt="" style={{ maxWidth: '247px' }} />
            ) : null}
          </Picture>
          <Sections>
            {subsections?.map(
              ({ heading, text }: SubsectionValues, index: number) =>
                heading || text ? (
                  <Section key={`${heading}_${text}`}>
                    <Icon>
                      {section[`icon${index + 1}Url`] ? (
                        <img
                          src={section[`icon${index + 1}Url`]}
                          alt=""
                          style={{ maxWidth: '36px' }}
                        />
                      ) : null}
                    </Icon>
                    <SectionContent>
                      <SectionHeading>{heading}</SectionHeading>
                      <SectionText>{text}</SectionText>
                    </SectionContent>
                  </Section>
                ) : null,
            )}
          </Sections>
        </Container>
      </Fragment>
    )
  }
  return <Box>Sections3</Box>
}

const HeadingContainer = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: column;
  padding: 36px 20px 20px;
  border-bottom: 1px solid #333333;
  width: 100%;
  box-sizing: border-box;
`

const Heading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #333333;
  word-wrap: break-word;
`

const Text = styled.div`
  font-style: normal;
  font-family: 'Poppins_300Light';
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #555555;
  word-wrap: break-word;
`
const Container = styled.div`
  font-family: 'Poppins_400Regular';
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
`

const Picture = styled.div``

const Sections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(50% - 40px);
  padding: 20px;
`

const Icon = styled.div`
  width: 20%;
  text-align: center;
`

const SectionContent = styled.div`
  width: 80%;
`

const SectionHeading = styled.div`
  font-style: normal;
  font-family: 'Poppins_600SemiBold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  padding-bottom: 15px;
  word-wrap: break-word;
`

const SectionText = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
  word-wrap: break-word;
`
