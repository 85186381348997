import React from 'react'
import { TouchableOpacity, Text, Image } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import RowView from 'components/common/fragments/RowView'
import COLORS from 'constants/colors'
import { IS_WEB } from 'constants/static'
import { useTranslation } from 'react-i18next'

function SignInWithGoogle({
  onPress,
  loading,
}: {
  loading: boolean
  onPress: () => void
}) {
  const { t } = useTranslation()

  return (
    <TouchableOpacity
      style={[styles.button, loading ? styles.disabledGoogleLoginButton : {}]}
      onPress={onPress}
      activeOpacity={0.8}
      disabled={loading}
    >
      <RowView style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Image
          style={{ width: 40, height: 40 }}
          source={require('assets/images/png/btn-google-icon.png')}
        />
        <Text style={styles.text}>{t('signInWithGoogle')}</Text>
      </RowView>
    </TouchableOpacity>
  )
}

const styles = EStyleSheet.create({
  button: {
    marginTop: 30,
    width: IS_WEB ? 424 : 300,
    backgroundColor: 'white',
    padding: 0,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#DBDBDB',
  },
  text: {
    textAlign: 'center',
    lineHeight: 42,
    color: COLORS.GRAYDARK1,
    fontFamily: 'Poppins_400Regular',
  },
})

export default SignInWithGoogle
