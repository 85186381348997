import React from 'react'
import styled from 'styled-components'
import COLORS from 'constants/colors'
import { sortBy } from 'lodash'
import Level2ResourcesSection from './Level2ResourcesSection.web'

const Level1ResourcesSection = ({
  children,
  title,
}: {
  children: [ResourceSectionInterface]
  title: string
}) => {
  return (
    <Level1ResourcesSectionStyled>
      <h1 className="resources-level-1-heading">{title}</h1>
      <div className="children">
        {sortBy(children, ['position']).map((child) => (
          <Level2ResourcesSection
            key={child.id}
            resources={child.resources}
            title={child.title}
          />
        ))}
      </div>
    </Level1ResourcesSectionStyled>
  )
}

const Level1ResourcesSectionStyled = styled.div`
  background: white;
  padding: 16px 36px 16px;

  .resources-level-1-heading {
    font-size: 40px;
    margin: 16px 0px 48px 0px;
  }

  &:nth-child(2n) {
    background: ${COLORS.LIGHTER_GREY};
  }

  .children {
    display: flex;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 20px;
  }
`

export default Level1ResourcesSection
