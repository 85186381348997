import React, { useState } from 'react'
import {
  View,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { TextInput } from 'react-native-paper'
import { IS_WEB } from 'constants/static'

const LoginTextInput = ({
  value,
  setValue,
  placeholder,
  handleOnSubmit,
  secureTextEntry = false,
  editable = true,
}: {
  value: string
  setValue: DispatchType<string>
  placeholder: string
  handleOnSubmit: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void
  secureTextEntry?: boolean
  editable?: boolean
}) => {
  const [secureEntry, setSecureEntry] = useState(secureTextEntry)

  return (
    <View style={styles.formElement}>
      <TextInput
        {...{ value, placeholder, editable }}
        mode="outlined"
        onChangeText={setValue}
        secureTextEntry={secureEntry}
        autoCapitalize="none"
        onKeyPress={handleOnSubmit}
        right={
          secureTextEntry && (
            <TextInput.Icon
              name={secureEntry ? 'eye' : 'eye-off'}
              color="#CCCCD6"
              onPress={() => setSecureEntry(!secureEntry)}
            />
          )
        }
        autoFocus
        theme={
          !editable
            ? {
                colors: {
                  background: '#EEF4FF',
                },
              }
            : {}
        }
      />
    </View>
  )
}

const styles = EStyleSheet.create({
  formElement: {
    width: IS_WEB ? 424 : 300,
    height: 56,
  },
})

export default LoginTextInput
