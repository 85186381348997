import * as React from 'react'
import Svg, { Path, Circle, SvgProps } from 'react-native-svg'

function Checkmark(props: SvgProps) {
  return (
    <Svg viewBox="0 0 84 84" {...props}>
      <Circle cx={42} cy={42} r={42} fill="#50CEA2" />
      <Path
        d="m68.868 29.131-6.125-6.125a.875.875 0 0 0-1.237 0L35 49.512 24.244 38.756a.875.875 0 0 0-1.237 0l-6.125 6.125a.875.875 0 0 0 0 1.237l17.5 17.5a.875.875 0 0 0 1.237 0l33.25-33.25a.875.875 0 0 0 0-1.237z"
        fill="#fff"
      />
    </Svg>
  )
}

export default Checkmark
