import React from 'react'

import styled from 'styled-components'
import COLORS from 'constants/colors'
import { MedalIcon } from 'components/common/custom-icons'
import { useTranslation } from 'react-i18next'

const UpNextDone = ({}: {}) => {
  const { t } = useTranslation()

  return (
    <StyledUpNextDone>
      <MedalIcon height={180} width={123} />
      <div className="upnext-done-title">{t('upNextDoneTitle')}</div>
      <div className="upnext-done-subtitle">{t('upNextDoneSubtitle')}</div>
    </StyledUpNextDone>
  )
}
const StyledUpNextDone = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;

  .upnext-done-title {
    font-size: 28px;
    color: ${COLORS.NAVY_BLUE};
  }

  .upnext-done-subtitle {
    font-size: 12px;
    color: ${COLORS.SIXES_GREY};
  }
`

export default UpNextDone
